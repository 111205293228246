@mixin md() {
    @media only screen and (min-width: #{$md}) {
        @content;
    }
}

@mixin lg() {
    @media only screen and (min-width: #{$lg}) {
        @content;
    }
}

@mixin xl() {
    @media only screen and (min-width: #{$xl}) {
        @content;
    }
}

@mixin xxl() {
    @media only screen and (min-width: #{$xxl}) {
        @content;
    }
}

@mixin ancho_xs($medida) {
    width: calc( 100vw / calc( 375 / #{$medida}));
}

@mixin alto_xs($medida) {
    height: calc( 100vw / calc( 375 / #{$medida}));
}

@mixin ancho_md($medida) {
    width: calc( 100vw / calc( 1336 / #{$medida}));
}

@mixin alto_md($medida) {
    height: calc( 100vw / calc( 1336 / #{$medida}));
}

@mixin background_image($url) {
    background-image: url($url);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}