.superadmin {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 82px);
    overflow: auto;
    padding: 27px;
    width: 100%;
    .superadmin-wrapper {
        width: 912px;
        .header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            h1 {
                font-family: $archivo_bold;
                font-size: 28px;
                color: var(--colorPrincipal);
            }
            .botonera {
                display: flex;
                & > div {
                    align-items: center;
                    background-color: $azul2;
                    display: flex;
                    height: 35px;
                    justify-content: center;
                    margin-left: 8px;
                    width: 35px;
                    &.btn-refresh {
                        width: 35px;
                        height: 36px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: $archivo_bold;
                        font-size: 21px;
                        cursor: pointer;
                        svg {
                            g {
                                stroke: var(--colorPrincipal);
                                fill: transparent;
                            }
                            line {
                                stroke: var(--colorPrincipal);
                                stroke-width: 2px;
                            }
                        }
                    }
                    &.btn-add {
                        width: 35px;
                        height: 36px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: $archivo_bold;
                        font-size: 21px;
                        position: relative;
                        cursor: pointer;
                        svg {
                            position: relative;
                            top: -2px;
                            text {
                                fill: var(--colorPrincipal);
                            }
                        }
                    }
                }
            }
        }
    }
}