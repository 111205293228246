.sucursales {
    flex-shrink: 0;
    height: calc(100vh - 85px);
    width: calc(100% - 66px);
    padding-left: 33px;
    padding-right: 33px;
    .preheader {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 28px;
        padding-bottom: 42px;
        .title {
            display: flex;
            align-items: center;
            span {
                display: inline-block;
                width: 25px;
                height: 25px;
                background-image: url('../../assets/img/sprite.png');
                background-position: -147px -380px;
                margin-right: 10px;
            }
            h1 {
                font-family: $archivo_bold;
                font-size: 28px;
                color: var(--colorPrincipal);
            }
        }
        .botonera {
            display: flex;
            justify-content: flex-end;
            flex: 1;
            transition: transform 0.3s;
            & > div {
                margin-left: 8px;
            }
            .btn-add {
                width: 35px;
                height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $azul2;
                font-family: $archivo_bold;
                font-size: 21px;
                cursor: pointer;
            }
        }
        &.compress {
            .botonera {
                transform: translateX(-135px);                
            }
        }
    }
    .table-container {
        height: calc( 100vh - 196px);
        overflow: auto;
        position: relative;
        width: 100%;
        .table-row {
            border-bottom: 8px solid $white;
            cursor: pointer;
            display: flex;
            padding: 15px 15px 15px 24px;
            .table-item {
                display: flex;
                flex-direction: column;
                padding-right: 15px;
                /* &:nth-child(1) { flex: 2; }
                &:nth-child(2) { flex: 28; } */
                &:nth-child(1){
                    width: 8%;
                }
                &:nth-child(2){
                    flex: 1;
                }
                &:nth-child(3){
                    width: calc( 41% - 40px );
                }
                &:nth-child(4){
                    width: 24%;
                }
                &:nth-child(5){
                    width: 22px;
                }
                .item-title {
                    font-size: 10px;
                    min-height: 30px;
                }
                .item-content {
                    font-family: $archivo_bold;
                    .horarios {
                        margin-left: 10px;
                        color: var(--colorPrincipal);
                    }
                    .profesionals {
                        display: flex;
                        flex-wrap: wrap;
                        .profesional {
                            background-size: cover;
                            background-position: center;
                            border-radius: 50%;
                            margin-right: 8px;
                            margin-bottom: 8px;
                            height: 27px;
                            width: 27px;
                            &:last-of-type {
                                margin-right: 0px;
                            }
                        }
                    }
                    .btn-menu {
                        flex-shrink: 0;
                        cursor: pointer;
                        position: relative;
                        & > span {
                            align-items: center;
                            display: inline-flex;
                            justify-content: center;
                            width: 35px;
                            height: 36px;
                        }
                        .submenu {
                            position: absolute;
                            display: none;
                            opacity: 0;
                            background-color: $white;
                            border: 1px solid $calendar-border-gray;
                            box-shadow: 0px 2px 10px #cccccc;
                            top: 10px;
                            right: 20px;
                            list-style: none;
                            width: 140px;
                            padding: 0px 20px;
                            transition: opacity 0.3s;
                            &.active {
                                display: block;
                                opacity: 1;
                            }
                            li {
                                display: flex;
                                align-items: center;
                                height: 40px;
                                border-bottom: 1px solid $calendar-border-gray;
                                cursor: pointer;
                                &:last-of-type {
                                    border: none;
                                }
                                &:hover {
                                    color: var(--colorPrincipal);
                                }
                            }
                        }
                        &.active {
                            .submenu {
                                display: block;
                                opacity: 1;
                            }
                        }
                    }
                }
                &.table-btn {
                    padding-right: 0px;
                    .item-content {
                        position: relative;
                        top: -41px;
                        font-family: $archivo_medium;
                    }
                }
            }
        }
    }
}

.sucursal {
    width: 100%;
    input {
        &:disabled {
            background-color: transparent;
        }
    }
    .sucursal-wrapper {
        width: 963px;
        .single-sucursal {
            padding: 28px 30px;
            height: calc(100vh - 80px);
            .header {
                display: flex;
                justify-content: space-between;
                width: 100%;
                .nav-back {
                    align-items: center;
                    color: $azul3;
                    cursor: pointer;
                    display: flex;
                    font-family: $archivo_bold;
                    font-size: 20px;
                    p {
                        margin-left: 9px;
                    }
                }
                .botonera {
                    align-items: center;
                    display: flex;
                    transition: transform 0.3s;
                    .btn-mini {
                        background: none;
                        border: none;
                        height: 36px;
                        margin-left: 10px;
                        width: 36px;
                        &.btn-editar {
                            svg {
                                height: 16px;
                                width: 17px;
                            }
                        }
                        &.btn-eliminar {
                            svg {
                                height: 17px;
                                width: 17px;
                            }
                        }
                    }
                    .btn {
                        background: none;
                        background-color: $gestion_bg_menu;
                        border: none;
                        color: var(--colorPrincipal);
                        font-family: $archivo_bold;
                        font-size: 16px;
                        height: 36px;
                        margin-left: 10px;
                        width: 120px;
                        &.btn-crear {
                            background-color: var(--colorPrincipal);
                            color: $gestion_bg_menu;
                        }
                        &:hover {
                            opacity: 0.3;
                        }
                    }
                    .btn:active {
                        background-color: #EDD8BB; 
                    }
                }
                &.compress {
                    .botonera {
                        transform: translateX(-135px);                
                    }
                }
            }
            .sucursal-body {
                height: calc( 100vh - 163px);
                margin-top: 19px;
                overflow: auto;
                h1 {
                    input {
                        background-color: transparent;
                        border: none;
                        font-family: $archivo_bold;
                        font-size: 26px;
                        &::placeholder {
                            color: #524F4F;
                        }
                    }
                }
                .sucursal-main {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 26px;
                    .map {
                        height: 264px;
                        position: relative;
                        width: 230px;
                        .bg-map {
                            background-color: $white;
                            bottom: 0px;
                            height: 34px;
                            left: 0px;
                            position: absolute;
                            width: 100%;
                            z-index: 10;
                        }
                        iframe {
                            height: 100%;
                            width: 100%;
                        }
                    }
                    .data {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        width: calc( 100% - 230px - 35px );
                        h3 {
                            color: $azul_flojo;
                            font-size: 16px;
                            font-weight: 300;
                            margin-bottom: 13px;
                        }
                        & > div {
                            margin-bottom: 8px;
                        }
                        input {
                            border: none;
                            font-family: $archivo_regular;
                            font-size: 14px;
                            margin-left: 10px;
                            &::placeholder {
                                color: var(--colorPrincipal);
                            }
                            &:disabled {
                                color: $black;
                            }
                        }
                        .address,
                        .city {
                            input {
                                margin-left: 0px;
                            }
                        }
                        .profesionales-sucursal {
                            margin-bottom: 0px;
                            .profesionals-row {
                                display: flex;
                                .prof-item {
                                    background-size: cover;
                                    background-position: center;
                                    border-radius: 50%;
                                    border: 2px solid;
                                    border-radius: 50%;
                                    height: 45px;
                                    margin-right: 8px;
                                    width: 45px;
                                }
                            }
                        }
                    }
                }

                .rotulo {
                    font-family: $archivo_regular;
                    color: $azul_flojo;
                    font-size: 16px;
                    font-weight: 300;
                    margin-top: 35px;
                    margin-bottom: 13px;
                }

                .sucursal-tabs {
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                    height: 40px;
                    margin-top: 22px;
                    .sucursal-tab {
                        align-items: center;
                        background-color: $azul2;
                        color: var(--colorPrincipal);
                        cursor: pointer;
                        display: flex;
                        font-family: $archivo_bold;
                        font-size: 16px;
                        justify-content: center;
                        height: 100%;
                        width: calc( (100% - 10px) / 3 );
                        .tab-icon {
                            display: inline-flex;
                            font-family: $archivo_medium;
                        }
                        p {
                            margin-left: 0px;
                        }
                        &.active {
                            background-color: $azul3;
                            color: var(--colorPrincipal);
                            svg {
                                path {
                                    fill: $white;
                                }
                            }
                        }
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
            .semanal {
                .horarios-container {
                    background-color: $azul2;
                    margin-top: 5px;
                    .horario-row {
                        display: flex;
                        align-items: center;
                        input {
                            background-color: transparent;
                            border: none;
                            color: var(--colorPrincipal);
                            font-family: $archivo_bold;
                            font-size: 16px;
                            padding: 8px 10px;
                            width: 80%;
                            &:focus {
                                background-color: $azul3;
                            }
                            &::placeholder {
                                font-family: $archivo_regular;
                                font-size: 14px;
                                color: $black;
                            }
                        }
                        button {
                            background: none;
                            border: none;
                            svg {
                                width: 18px;
                            }
                            &:hover {
                                opacity: .8;
                            }
                        }
                    }
                    .add-line {
                        background-color: $azul3;
                        color: var(--colorPrincipal);
                        cursor: pointer;
                        font-family: $archivo_bold;
                        font-size: 16px;
                        margin-top: 15px;
                        padding: 8px;
                        width: 200px;
                        &:hover {
                            opacity: 0.8;
                        }
                        p {
                            text-align: center;
                        }
                    }
                }
            }

            .vacacional {
                .vacacional-item {
                    align-items: center;
                    background-color: $azul2;
                    color: var(--colorPrincipal);
                    display: flex;
                    margin-top: 3px;
                    min-height: 70px;
                    padding: 0px 21px 0px 33px;
                    .new-item-txt {
                        cursor: pointer;
                        font-family: $archivo_semibold;
                        font-size: 16px;
                    }
                    .new-item-section {
                        display: flex;
                        flex-direction: column;
                    }
                    .new-item-row {
                        align-items: center;
                        color: $gris_oscuro;
                        display: flex;
                        justify-content: space-between;
                        font-family: $archivo_medium;
                        width: 100%;
                        .calendar-row {
                            align-items: center;
                            display: flex;
                            width: 491px;
                            span {
                                color: $azul6;
                                margin: 0px 19px;
                            }
                            .date-row {
                                align-items: center;
                                display: flex;
                                &.del {
                                    span {
                                        margin: 0px 12px 0px 0px;
                                    }
                                }
                                .date-input {
                                    align-items: center;
                                    background-color: $white;
                                    cursor: pointer;
                                    display: flex;
                                    justify-content: center;
                                    height: 30px;
                                    position: relative;
                                    width: 131px;
                                    .vacacional-calendar {
                                        font-family: $archivo_regular;
                                        font-size: 10px;
                                        left: 0px;
                                        position: absolute;
                                        top: 30px;
                                        width: 287px;
                                        .react-calendar {
                                            position: relative;
                                            background-color: $white;
                                            border: 1px solid $gris4;
                                            z-index: 5;
                                            .react-calendar__navigation {
                                                padding: 17px 0px 8px;
                                                button {
                                                    font-size: 15px;
                                                }
                                                .react-calendar__navigation__label span {
                                                    font-family: $archivo_bold;
                                                    font-size: 12px;
                                                    color: $black;
                                                }
                                            }
                                            .react-calendar__viewContainer {
                                                .react-calendar__month-view__weekdays__weekday {
                                                    font-size: 7px;
                                                }
                                                .react-calendar__tile {
                                                    border: none !important;
                                                    background-color: transparent;
                                                    padding: 8px 12px !important;
                                                    margin: 0px;
                                                    abbr {
                                                        font-size: 10px;
                                                    }
                                                }
                                                .react-calendar__tile--active,
                                                .react-calendar__tile--hasActive {
                                                    background: var(--colorPrincipal);
                                                    color: var(--white);
                                                    font-family: $archivo_bold;
                                                }
                                                .react-calendar__tile--rangeStart {
                                                    border-radius: 15px 0px 0px 15px;
                                                }
                                                .react-calendar__tile--rangeEnd {
                                                    border-radius: 0px 15px 15px 0px;
                                                }
                                                .react-calendar__tile--rangeBothEnds,
                                                .react-calendar__tile--hasActive {
                                                    border-radius: 100px;
                                                }
                                            }
                                        }
                                    }
                                }
                                .time-input {
                                    align-items: center;
                                    background-color: $white;
                                    display: flex;
                                    height: 30px;
                                    justify-content: center;
                                    margin-left: 3px;
                                    width: 70px;
                                    .hora-input {
                                        width: 100%;
                                        .h-input {
                                            font-family: $archivo_medium;
                                            justify-content: center;
                                        }
                                    }
                                }
                            }
                            &.hide-hours {
                                .time-input {
                                    display: none !important;
                                }
                            }
                        }
                        .ylm-checkbox {
                            margin-left: 12px;
                            flex: 1 0 auto;
                            p {
                                font-family: $archivo_regular;
                                font-size: 14px;
                            }
                        }
                    }
                }
                .botonera {
                    align-items: center;
                    display: flex;
                    .btn {
                        align-items: center;
                        background-color: $white;
                        cursor: pointer;
                        display: flex;
                        height: 50px;
                        justify-content: center;
                        margin-right: 14px;
                        width: 50px;
                        &:last-of-type {
                            margin-right: 0px;
                        }
                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }
            }


            .festivos {
                margin-top: 3px;
                .festivos-item {
                    align-items: center;
                    background-color: $azul2;
                    color: var(--colorPrincipal);
                    display: flex;
                    min-height: 70px;
                    padding: 0px 21px 0px 33px;
                    .new-item-txt {
                        cursor: pointer;
                        font-family: $archivo_semibold;
                        font-size: 16px;
                    }
                    .item-row {
                        align-items: center;
                        color: $gris_oscuro;
                        display: flex;
                        justify-content: space-between;
                        font-family: $archivo_medium;
                        width: 100%;
                        .calendar-row {
                            align-items: center;
                            display: flex;
                            width: 210px;
                            span {
                                color: $azul6;
                                margin: 0px 19px;
                            }
                            .date-row {
                                align-items: center;
                                display: flex;
                                &.del {
                                    span {
                                        margin: 0px 12px 0px 0px;
                                    }
                                }
                                .date-input {
                                    align-items: center;
                                    background-color: $white;
                                    cursor: pointer;
                                    display: flex;
                                    justify-content: center;
                                    height: 30px;
                                    position: relative;
                                    width: 131px;
                                    .vacacional-calendar {
                                        font-family: $archivo_regular;
                                        font-size: 10px;
                                        left: 0px;
                                        position: absolute;
                                        top: 30px;
                                        width: 287px;
                                        .react-calendar {
                                            position: relative;
                                            background-color: $white;
                                            border: 1px solid $gris4;
                                            z-index: 5;
                                            .react-calendar__navigation {
                                                padding: 17px 0px 8px;
                                                button {
                                                    font-size: 15px;
                                                }
                                                .react-calendar__navigation__label span {
                                                    font-family: $archivo_bold;
                                                    font-size: 12px;
                                                    color: $black;
                                                }
                                            }
                                            .react-calendar__viewContainer {
                                                .react-calendar__month-view__weekdays__weekday {
                                                    font-size: 7px;
                                                }
                                                .react-calendar__tile {
                                                    border: none !important;
                                                    background-color: transparent;
                                                    padding: 8px 12px !important;
                                                    margin: 0px;
                                                    abbr {
                                                        font-size: 10px;
                                                    }
                                                }
                                                .react-calendar__tile--active,
                                                .react-calendar__tile--hasActive {
                                                    background: var(--colorPrincipal);
                                                    color: $white;
                                                    font-family: $archivo_bold;
                                                }
                                                .react-calendar__tile--rangeStart {
                                                    border-radius: 15px 0px 0px 15px;
                                                }
                                                .react-calendar__tile--rangeEnd {
                                                    border-radius: 0px 15px 15px 0px;
                                                }
                                                .react-calendar__tile--rangeBothEnds,
                                                .react-calendar__tile--hasActive {
                                                    border-radius: 100px;
                                                }
                                            }
                                        }
                                    }
                                }
                                .time-input {
                                    align-items: center;
                                    background-color: $white;
                                    display: flex;
                                    height: 30px;
                                    justify-content: center;
                                    margin-left: 3px;
                                    width: 70px;
                                    .hora-input {
                                        width: 100%;
                                        .h-input {
                                            font-family: $archivo_medium;
                                            justify-content: center;
                                        }
                                    }
                                }
                            }
                            &.hide-hours {
                                .time-input {
                                    display: none !important;
                                }
                            }
                        }

                    }
                    .new-item-row {
                        align-items: center;
                        color: $gris_oscuro;
                        display: flex;
                        justify-content: space-between;
                        font-family: $archivo_medium;
                        width: 100%;
                        .calendar-row {
                            align-items: center;
                            display: flex;
                            width: 210px;
                            span {
                                color: $azul6;
                                margin: 0px 19px;
                            }
                            .date-row {
                                align-items: center;
                                display: flex;
                                &.del {
                                    span {
                                        margin: 0px 12px 0px 0px;
                                    }
                                }
                                .date-input {
                                    align-items: center;
                                    background-color: $white;
                                    cursor: pointer;
                                    display: flex;
                                    justify-content: center;
                                    height: 30px;
                                    position: relative;
                                    width: 131px;
                                    .vacacional-calendar {
                                        font-family: $archivo_regular;
                                        font-size: 10px;
                                        left: 0px;
                                        position: absolute;
                                        top: 30px;
                                        width: 287px;
                                        .react-calendar {
                                            position: relative;
                                            background-color: $white;
                                            border: 1px solid $gris4;
                                            z-index: 5;
                                            .react-calendar__navigation {
                                                padding: 17px 0px 8px;
                                                button {
                                                    font-size: 15px;
                                                }
                                                .react-calendar__navigation__label span {
                                                    font-family: $archivo_bold;
                                                    font-size: 12px;
                                                    color: $black;
                                                }
                                            }
                                            .react-calendar__viewContainer {
                                                .react-calendar__month-view__weekdays__weekday {
                                                    font-size: 7px;
                                                }
                                                .react-calendar__tile {
                                                    border: none !important;
                                                    background-color: transparent;
                                                    padding: 8px 12px !important;
                                                    margin: 0px;
                                                    abbr {
                                                        font-size: 10px;
                                                    }
                                                }
                                                .react-calendar__tile--active,
                                                .react-calendar__tile--hasActive {
                                                    background: var(--colorPrincipal);
                                                    color: $white;
                                                    font-family: $archivo_bold;
                                                }
                                                .react-calendar__tile--rangeStart {
                                                    border-radius: 15px 0px 0px 15px;
                                                }
                                                .react-calendar__tile--rangeEnd {
                                                    border-radius: 0px 15px 15px 0px;
                                                }
                                                .react-calendar__tile--rangeBothEnds,
                                                .react-calendar__tile--hasActive {
                                                    border-radius: 100px;
                                                }
                                            }
                                        }
                                    }
                                }
                                .time-input {
                                    align-items: center;
                                    background-color: $white;
                                    display: flex;
                                    height: 30px;
                                    justify-content: center;
                                    margin-left: 3px;
                                    width: 70px;
                                    .hora-input {
                                        width: 100%;
                                        .h-input {
                                            font-family: $archivo_medium;
                                            justify-content: center;
                                        }
                                    }
                                }
                            }
                            &.hide-hours {
                                .time-input {
                                    display: none !important;
                                }
                            }
                        }
                        .ylm-checkbox {
                            margin-left: 12px;
                            flex: 0 0 auto;
                            p {
                                font-family: $archivo_regular;
                                font-size: 14px;
                            }
                        }
                        .select-dia {
                            align-items: center;
                            display: flex;
                            position: relative;
                            p {
                                margin-right: 10px;
                            }
                            .dia-semana-select {
                                cursor: pointer;
                                position: relative;
                                p {
                                    font-family: $archivo_bold;
                                }
                                .dia-semana-options {
                                    background-color: $white;
                                    left: -8px;
                                    list-style: none;
                                    margin-top: 8px;
                                    position: absolute;
                                    z-index: 2;
                                    li {
                                        border-bottom: 1px solid $azul3;
                                        padding: 8px;
                                        &:last-of-type {
                                            border: none;
                                        }
                                    }
                                }
                            }
                        }
                        .select-fin {
                            align-items: center;
                            display: flex;
                            position: relative;
                            > p {
                                margin-right: 10px;
                            }
                            .finaliza-select {
                                .date-input {
                                    font-family: $archivo_bold;
                                }
                            }
                        }
                    }
                }
                .botonera {
                    align-items: center;
                    display: flex;
                    .btn {
                        align-items: center;
                        background-color: $white;
                        cursor: pointer;
                        display: flex;
                        height: 50px;
                        justify-content: center;
                        margin-right: 14px;
                        width: 50px;
                        &:last-of-type {
                            margin-right: 0px;
                        }
                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }
            }
        }
    }
}