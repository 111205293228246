.daily-view {
    position: relative;
    width: 100%;
    height: calc( 100vh - 300px );
    overflow: auto;
    .horario-diario {
        display: flex;
        justify-content: flex-start;
        border-top: 2px solid $text-grey;
        /* margin-right: 30px; */
        width: 100%;
        &.ultimo {
            border-bottom: 2px solid $text-grey;
        }
        .hora {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            flex-shrink: 0;
        }
        .franjas {
            .franja {
                font-size: 10px;
                height: $franja-height;
            }
        }
    }
    .citas-view {
        position: absolute;
        top: 0px;
        left: 85px;
        width: calc( 100% - 60px );
        .daily-row {
            position: absolute;
            width: 100%;
        }
        /* .franja-item {
            height: 25.35px;
        } */
        .daily-cita {
            position: relative;
            box-sizing: border-box;
            float: left;
            cursor: pointer;
            width: 100%;
            margin: 1px;
            border-radius: 5px;
            padding: 5px 10px;
            overflow: hidden;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            color: $white;
            font-weight: bold;
           
            .datos-cita {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                color: $white;
                font-weight: bold;
                width: calc(100% - 17px);
                .linea {
                    margin-bottom: 5px;
                    p {
                        width: 100%;
                        overflow: hidden;
                    }
                    &.limitada {
                        max-width: 100%;
                        height: 14px;
                        flex-shrink: 0;
                        overflow: hidden;
                        p {
                            height: 100%;
                        }
                    }
                    &.bold {
                        font-family: $archivo_bold;
                    }
                }
            }
            .iconos-cita {
                flex-basis: 15px;
                padding-left: 5px;
                .icono {
                    position: relative;
                    svg {
                        stroke: transparent;
                    }
                    &.comments-mini {
                        span {
                            position: absolute;
                            display: inline-block;
                            top: 2px;
                            left: 0px;
                            width: 11px;
                            text-align: center;
                            font-family: $archivo_bold;
                            font-size: 8px;
                        }
                    }
                }
            }

            &.size-1 { width: calc(100% - 2px); }
            &.size-2{ width: calc(50% - 2px); }
            &.size-3{ width: calc(33.3333% - 2px); }
            &.size-4{ width: calc(25% - 2px); }
            &.size-5{ width: calc(20% - 2px); }
            &.size-6{ width: calc(16.6666% - 2px); }
            &.size-7{ width: calc(14.2857% - 2px); }
            &.size-8{ width: calc(12.5% - 2px); }
            &.size-9{ width: calc(11.1111% - 2px); }
            &.size-10{ width: calc(10% - 2px); }
			&.size-11{ width: calc(9.090% - 2px); }

            &.date-10 { height: 25px; }
            &.date-15 { height: 37.5px }
            &.date-20 { height: 50px }
            &.date-25 { height: 62.5px }
            &.date-30 { height: 75px }
            &.date-35 { height: 87.5px }
            &.date-40 { height: 100px }
            &.date-45 { height: 112.5px }
            &.date-50 { height: 125px }
            &.date-55 { height: 137.5px }
            &.date-60 { height: 150px }
            &.date-65 { height: 162.5px }
            &.date-70 { height: 175px }
            &.date-75 { height: 187.5px }
            &.date-80 { height: 200px }
            &.date-85 { height: 212.5px }
            &.date-90 { height: 225px }
            &.date-95 { height: 237.5px }
            &.date-100 { height: 250px }
            &.date-105 { height: 262.5px }
            &.date-110 { height: 275px }
            &.date-115 { height: 287.5px }
            &.date-120 { height: 300px }
            &.date-125 { height: 312.5px }
        }
        &.columns {
            display: flex;
            .daily-column {
                position: relative;
                /* min-height: 20px;
                background-color: red; */
    
                &.size-1 { width: calc(100% - 2px); }
                &.size-2{ width: calc(50% - 2px); }
                &.size-3{ width: calc(33.3333% - 2px); }
                &.size-4{ width: calc(25% - 2px); }
                &.size-5{ width: calc(20% - 2px); }
                &.size-6{ width: calc(16.6666% - 2px); }
                &.size-7{ width: calc(14.2857% - 2px); }
                &.size-8{ width: calc(12.5% - 2px); }
                &.size-9{ width: calc(11.1111% - 2px); }
                &.size-10{ width: calc(10% - 2px); }
				&.size-11{ width: calc(9.090% - 2px); }
            }
            .daily-cita {
                position: absolute;
            }
        }
        .daily-cita-no-pagada {
            position: relative;
            box-sizing: border-box;
            float: left;
            cursor: pointer;
            width: 100%;
            margin: 1px;
            border-radius: 5px;
            padding: 5px 10px;
            overflow: hidden;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            color: $white;
            font-weight: bold;
            background: repeating-linear-gradient(
                135deg, /* Ángulo de las rayas */
                var(--colorPrincipal), /* Color de la primera raya */
                /*#f7a5a5 2px, Final de la primera raya */
                #fff 2px, /* Color del espacio entre las rayas */
                #fff 6px /* Final del espacio entre las rayas */
            );
           
            .datos-cita-no-pagadas {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                //border-style: solid;
                border-color: $black;//#f7a5a5;
                color: $black;
                font-weight: bold;
                width: calc(100% - 17px);
                .linea {
                    margin-bottom: 5px;
                    p {
                        width: 100%;
                        overflow: hidden;
                    }
                    &.limitada {
                        max-width: 100%;
                        height: 14px;
                        flex-shrink: 0;
                        overflow: hidden;
                        p {
                            height: 100%;
                        }
                    }
                    &.bold {
                        font-family: $archivo_bold;
                    }
                }
            }
        }
    }
}