.reset-password {
    padding-top: 246px;
    @include lg(){
        padding-bottom: 30px;
    }
    .form-container {
        width: calcula(375, 325);
        margin: 0 auto;
        @include lg(){
            width: 600px;
        }
        .form-header {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            h2 {
                font-family: $archivo_bold;
                font-size: 16px;
                text-transform: uppercase;
                color: $gold;
                letter-spacing: 5px;
            }
        }
        form {
            .auth__input {
                background: none;
                border: none;
                border-bottom: 2px solid $form-border-color;
                font-family: $archivo_medium;
                font-size: 20px;
                padding: 7px 0px;
                margin-top: 38px;
                width: 100%;
                &:focus {
                    outline: unset;
                }
                &::placeholder {
                    color: $font;
                }
                &[disabled]{
                    color: $disabled;
                    border-color: $disabled;
                }
            }
            p {
                &.error-message {
                    height: 0px;
                    overflow: hidden;
                    font-family: $archivo_medium;
                    font-size: 16px;
                    color: $gold;
                    line-height: 2.3rem;
                    transition: height .3s;
                    &.active {
                        height: 32px;
                    }
                }
                &.remember-text {
                    font-family: $archivo_medium;
                    font-size: 16px;
                    line-height: 2.3rem;
                }
            }

            button {
                margin: 0;
                background: none;
                font-family: $archivo_bold;
                border: 4px solid $font;
                font-size: 22px;
                height: 54px;
                margin-top: 75px;
                width: 170px;
                overflow: hidden;
                @include lg(){
                    font-size: 20px;
                    margin-top: 60px;
                    padding: 0px;
                }
                &:hover {
                    background-color: $gold;
                    color: $white;
                    border-color: $gold;
                }
            }
        }
    }
}