.idiomas-superadmin {
    flex-shrink: 0;
    height: calc(100vh - 85px);
    width: calc(100% - 66px);
    padding-left: 33px;
    padding-right: 33px;
    position: relative;
    .preheader {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 28px;
        .title {
            align-items: center;
            color: var(--colorPrincipal);
            display: flex;
            span {
                display: inline-block;
                width: 25px;
                height: 25px;
                background-image: url('../../assets/img/sprite.png');
                background-position: -147px -380px;
                margin-right: 10px;
            }
            h1 {
                font-family: $archivo_bold;
                font-size: 28px;
                color: var(--colorPrincipal);
            }
        }
        .botonera {
            display: flex;
            justify-content: flex-end;
            flex: 1;
            & > div {
                margin-left: 8px;
            }
            .btn-add {
                width: 35px;
                height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $azul2;
                font-family: $archivo_bold;
                font-size: 21px;
                cursor: pointer;
            }

            .btn-search {
                align-items: center;
                background-color: var(--color6);
                color: var(--colorPrincipal);
                cursor: pointer;
                display: flex;
                font-family: $archivo_bold;
                font-size: 21px;
                height: 36px;
                justify-content: center;
                position: relative;
                width: 35px;
                svg {
                    g {
                        fill: transparent;
                    }
                    line {
                        stroke-width: 2px;
                    }
                }
                .search-input {
                    display: none;
                    position: relative;
                    input {
                        background: none;
                        border: none;
                        height: 100%;
                        width: 250px;
                        padding-left: 8px;
                        &::placeholder {
                            color: $black;
                        }
                    }
                    span {
                        font-family: $archivo_semibold;
                        font-size: 12px;
                        position: absolute;
                        right: 15px;
                        top: 0px;
                    }
                }
                &.active {
                    display: flex;
                    justify-content: flex-end;
                    width: auto;
                    padding-right: 8px;
                    .search-input {
                        display: flex;
                    }
                }
            }
        }
    }
    .idiomas-list {
        margin-top: 47px;
        .idioma-row {
            align-items: center;
            background-color: var(--color6);
            display: flex;
            margin-bottom: 13px;
            padding: 13px 19px 13px 24px;
            .idioma-circle {
                align-items: center;
                background-color: var(--colorPrincipal);
                border-radius: 50%;
                color: var(--white);
                display: flex;
                flex: 0 0 55px;
                font-family: $archivo_bold;
                font-size: 14px;
                height: 55px;
                justify-content: center;
                margin-right: 27px;
                text-transform: uppercase;
            }
            .idioma-name {
                color: var(--colorPrincipal);
                flex: 1;
                font-family: $archivo_bold;
                font-size: 18px;
            }
            .idioma-file {
                align-items: center;
                background-color: var(--white);
                display: flex;
                flex: 0 0 241px;
                padding: 9px 9px 9px 16px;
                svg {
                    height: 23px;
                    width: 20px;
                    fill: var(--colorPrincipal);
                    margin-right: 18px;
                }
                p {
                    color: var(--gris1);
                }
            }
        }
    }
}