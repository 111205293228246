.reservas-productos {
    margin-top: 40px;
    .productos-table {
        width: 100%;
        .col {
            margin-right: 10px;
            overflow: hidden;
            &.col-pedido { 
                align-items: center;
                display: flex;
                flex: 0 0 65px;
                position: relative;
                span {
                    &:nth-child(1){
                        width: 30px;
                    }
                    &:nth-child(2){
                        svg {
                            position: relative;
                            top: -1px;
                            height: 5px;
                            transition: transform 0.3s;
                            width: 6px;
                        }
                    }
                }
            }
            &.col-cliente { flex: 4; }
            &.col-producto { flex: 3; }
            &.col-referencia { flex: 0 0 95px; }
            &.col-unidades { 
                flex: 0 0 70px;
                margin-right: 75px;
            }
            &.col-total { 
                flex: 0 0 60px;
                margin-right: 49px;
            }
            &.col-pago { 
                flex: 0 0 70px;
                margin-right: 33px;
                svg {
                    rect {
                        fill: var(--colorPrincipal);
                    }
                }
            }
            &.col-entregado { 
                flex: 0 0 75px;
                svg {
                    &#deliv_false {
                        rect {
                            fill: var(--colorSecundario);
                        }
                    }
                    &#deliv_partial {
                        rect {
                            fill: var(--colorPrincipal); 
                        }
                    }
                    &#deliv {
                        rect {
                            fill: var(--colorPrincipal); 
                        }
                    }
                }
            }
            &:last-of-type { margin-right: 0px; }
            &.center {
                text-align: center;
            }
            &.bold {
                font-family: $archivo_semibold;
            }
        }
        .headers-row {
            border-bottom: 1px solid var(--gris2);
            display: flex;
            font-family: $archivo_bold;
            font-size: 14px;
            padding-bottom: 8px;
        }
        .table-row {
            border-bottom: 1px solid var(--gris2);
            cursor: pointer;
            padding: 12px 0px;
            position: relative;
            .pedido-row {
                align-items: center;
                display: flex;
                padding: 3px 0px;
                &.first-row {
                    padding: 0px;
                }
                &:last-child {
                    padding-bottom: 0px;
                }
            }
            .pedido-detail {
                max-height: 0px;
                overflow: hidden;
                transition: max-height 0.3s;
                .pedido-row {
                    &:first-child {
                        margin-top: 15px;
                    }
                }
            }
            &.active {
                .col-pedido {
                    span:nth-child(2){
                        svg {
                            transform: rotate(180deg);
                        }
                    }
                }
                .pedido-detail {
                    max-height: 1000px;
                }
            }
        }
    }
}