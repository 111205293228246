.clientes {
    flex-shrink: 0;
    height: calc(100vh - 85px);
    width: calc(100% - 66px);
    padding-left: 33px;
    padding-right: 33px;
    position: relative;
    .preheader {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 28px;
        .title {
            display: flex;
            align-items: center;
            span {
                display: inline-block;
                width: 25px;
                height: 25px;
                background-image: url('../../assets/img/sprite.png');
                background-position: -147px -380px;
                margin-right: 10px;
            }
            h1 {
                font-family: $archivo_bold;
                font-size: 28px;
                color: var(--colorPrincipal);
            }
        }
        .botonera {
            display: flex;
            justify-content: flex-end;
            flex: 1;
            transition: transform 0.3s;
            & > div {
                margin-left: 8px;
            }
            .btn-add {
                width: 35px;
                height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $azul2;
                font-family: $archivo_bold;
                font-size: 21px;
                cursor: pointer;
            }

            .btn-search {
                align-items: center;
                background-color: $azul2;
                cursor: pointer;
                display: flex;
                font-family: $archivo_bold;
                font-size: 21px;
                height: 36px;
                justify-content: center;
                position: relative;
                width: 35px;
                svg {
                    g {
                        fill: transparent;
                    }
                    line {
                        stroke-width: 2px;
                    }
                }
                .search-input {
                    display: none;
                    position: relative;
                    input {
                        background: none;
                        border: none;
                        height: 100%;
                        width: 250px;
                        padding-left: 8px;
                        &::placeholder {
                            color: $black;
                        }
                    }
                    span {
                        font-family: $archivo_semibold;
                        font-size: 12px;
                        position: absolute;
                        right: 15px;
                        top: 0px;
                    }
                }
                &.active {
                    display: flex;
                    justify-content: flex-end;
                    width: auto;
                    padding-right: 8px;
                    .search-input {
                        display: flex;
                    }
                }
            }
        }
        &.compress {
            .botonera {
                transform: translateX(-135px);                
            }
        }
    }
    .table-clientes {
        display: flex;
        flex-direction: column;
        margin-top: 47px;
        .table-scrollable {
            height: calc(100vh - 293px);
            overflow: auto;
        }
        .table-row {
            border-bottom: 1px solid $gris4;
            cursor: pointer;
            display: flex;  
            padding: 12px 2px 12px 10px;
        }
        .column {
            display: flex;
            margin-right: 5px;
            overflow: hidden;
            span {
                margin-left: 22px;
            }
            &.name {
                flex-basis: 140px;
                min-width: 140px;
                @include xl() {
                    flex-basis: 260px;
                    min-width: 260px;
                }
                @include xxl() {
                    flex-basis: 29%;
                    min-width: 29%;
                }
            }
            &.phone {
                flex-basis: 109px;
                min-width: 109px;
                @include xxl() {
                    flex-basis: 10%;
                    min-width: 10%;
                }
            }
            &.email {
                flex-basis: 174px;
                min-width: 174px;
                @include xl() {
                    flex-basis: 219px;
                    min-width: 219px;
                }
                @include xxl() {
                    flex-basis: 20%;
                    min-width: 20%;
                }
            }
            &.birthdate {
                flex-basis: 124px;
                min-width: 124px;
                @include xxl() {
                    flex-basis: 10%;
                    min-width: 10%;
                }
            }
            &.created {
                flex-basis: 100px;
                min-width: 100px;
                @include xxl() {
                    flex-basis: 10%;
                    min-width: 10%;
                }
            }
            &.last {
                flex-basis: 179px;
                min-width: 179px;
                @include xxl() {
                    flex-basis: 208px;
                    min-width: 208px;
                }
                @include xxl() {
                    flex-basis: 15%;
                    min-width: 15%;
                }
                p {
                    margin: 0px 5px;
                    &:first-of-type {
                        margin-left: 0px;
                    }
                }
            }
            &.btns {
                position: relative;
                flex-basis: 20px;
                min-width: 20px;
                display: flex;
                justify-content: flex-end;
                overflow:initial;
                .btn-menu {
                    flex-shrink: 0;
                    cursor: pointer;
                    position: relative;
                    & > span {
                        align-items: center;
                        display: inline-flex;
                        justify-content: center;
                        width: 35px;
                    }
                    .submenu {
                        position: absolute;
                        display: none;
                        opacity: 0;
                        background-color: $white;
                        border: 1px solid $calendar-border-gray;
                        box-shadow: 0px 2px 10px #cccccc;
                        top: 10px;
                        right: 30px;
                        list-style: none;
                        width: 140px;
                        padding: 0px 20px;
                        transition: opacity 0.3s;
                        &.active {
                            display: block;
                            opacity: 1;
                        }
                        li {
                            display: flex;
                            align-items: center;
                            height: 40px;
                            border-bottom: 1px solid $calendar-border-gray;
                            cursor: pointer;
                            &:last-of-type {
                                border: none;
                            }
                            &:hover {
                                color: var(--colorPrincipal);
                            }
                        }
                    }
                    &.active {
                        .submenu {
                            display: block;
                            opacity: 1;
                        }
                    }
                }
            }
        }
        .headers-row {          
            .column {
                font-family: $archivo_bold;
                span {
                    position: relative;
                    top: -1px;
                    &.desc {
                        top: 2px;
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
    .paginacion {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 15px;
        .pag-btn {
            align-items: center;
            background-color: $azul2;
            color: var(--colorPrincipal);
            cursor: pointer;
            display: flex;
            font-family: $archivo_bold;
            font-size: 16px;
            justify-content: center;
            height: 35px;
            margin: 0px 5px;
            width: 35px;
        }
        .pag-number {
            margin: 3px 20px 0px;
        }
    }
    .loading-clientes {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0px;
        opacity: 1;
        position: absolute;
        top: 0px;
        width: 100%;
        z-index: 5;
        .bg-loading {
            align-items: center;
            background-color: $gris_flojo;
            display: flex;
            height: 100%;
            justify-content: center;
            left: 0px;
            opacity: 0.5;
            position: absolute;
            top: 0px;
            width: 100%;
        }
        p {
            font-family: $archivo_bold;
            font-size: 35px;
            z-index: 6;
        }
    }
}

.cliente {
    width: 100%;
    .cliente-wrapper {
        width: 963px;
        .header {
            display: flex;
            justify-content: space-between;
            min-height: 36px;
            width: 100%;
            .nav-back {
                align-items: center;
                color: $azul3;
                cursor: pointer;
                display: flex;
                font-family: $archivo_bold;
                font-size: 20px;
                p {
                    margin-left: 9px;
                }
            }

            .botonera {
                align-items: center;
                display: flex;
                transition: transform 0.3s;
                .btn-mini {
                    background: none;
                    border: none;
                    height: 36px;
                    margin-left: 10px;
                    width: 36px;
                    &.btn-editar {
                        svg {
                            height: 16px;
                            width: 17px;
                        }
                    }
                    &.btn-eliminar {
                        svg {
                            height: 17px;
                            width: 17px;
                        }
                    }
                }
                .btn {
                    background: none;
                    background-color: $gestion_bg_menu;
                    border: none;
                    color: var(--colorPrincipal);
                    font-family: $archivo_bold;
                    font-size: 16px;
                    height: 36px;
                    margin-left: 10px;
                    width: 120px;
                    &.btn-crear {
                        background-color: var(--colorPrincipal);
                        color: $gestion_bg_menu;
                    }
                    &:hover {
                        opacity: 0.3;
                    }
                }
                .btn:active {
                    background-color: #EDD8BB; 
                }
            }
            &.compress {
                .botonera {
                    transform: translateX(-135px);                
                }
            }
        }
        .cliente-body {
            margin-top: 18px;
            h1 {
                font-family: $archivo_bold;
                font-size: 26px;
            }
            .user-main {
                align-items: center;
                color: $gris_oscuro;
                display: flex;
                justify-content: space-between;
                margin-top: 12px;
                position: relative;
                width: 100%;
                .col-1 {
                    align-items: center;
                    background-color: $azul2;
                    display: flex;
                    height: 128px;
                    width: calc(100% - 263px);
                    .avatar {
                        background-size: cover;
                        background-position: center;
                        border-radius: 50%;
                        cursor: pointer;
                        height: 61px;
                        margin-left: 30px;
                        position: relative;
                        width: 68px;

                        svg {
                            left: 1px;
                            position: relative;
                            top: 2px;
                        }
                        .avatar-bg {
                            align-items: center;
                            background-color: rgba(82,79,79,0.7);
                            border-radius: 50%;
                            color: $white;
                            display: flex;
                            font-family: $archivo_bold;
                            font-size: 21px;
                            height: 100%;
                            justify-content: center;
                            left: 0px;
                            position: absolute;
                            top: 0px;
                            width: 100%;
                        }
                    }
                    .data-group {
                        align-items: center;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        width: 100%;
                        .data {
                            display: flex;
                            justify-content: space-between;
                            margin-left: 22px;
                            min-width: 300px;
                            padding-right: 20px;
                            position: relative;
                            width: 100%;
                            gap: 5px;
                            .name {
                                input {
                                    background-color: transparent;
                                    border: none;
                                    font-family: $archivo_bold;
                                    font-size: 16px;
                                    padding: 5px 8px;
                                    width: 100%;
                                    &::placeholder {
                                        color: $gris_oscuro;
                                    }
                                }
                            }
                        }
                        .contact-data {
                            align-items: center;
                            display: flex;
                            margin-left: 22px;
                            margin-top: 19px;
                            width: 100%;
                            .contact-input {
                                margin-right: 8px;
                                input {
                                    border: none;
                                    height: 30px;
                                    text-align: center;
                                    font-family: $archivo_regular;
                                    font-size: 16px;
                                    width: 100%;
                                    &::placeholder {
                                        color: $black;
                                    }
                                }
                                &.phone {
                                    width: 111px;
                                }
                                &.email {
                                    flex: 1 1 auto;
                                }
                                &.fecha {
                                    background-color: $white;
                                    font-size: 16px;
                                    margin-right: 20px;
                                    position: relative;
                                    width: 111px;
                                    .date-input {
                                        align-items: center;
                                        display: flex;
                                        height: 30px;   
                                        justify-content: center;                                 
                                    }
                                    .fechanac-calendar {
                                        font-size: 10px;
                                        left: 0px;
                                        position: absolute;
                                        top: 30px;
                                        width: 287px;
                                        .react-calendar {
                                            position: relative;
                                            background-color: $white;
                                            border: 1px solid $gris4;
                                            z-index: 5;
                                            .react-calendar__navigation {
                                                padding: 17px 0px 8px;
                                                button {
                                                    font-size: 15px;
                                                }
                                                .react-calendar__navigation__label span {
                                                    font-family: $archivo_bold;
                                                    font-size: 12px;
                                                    color: $black;
                                                }
                                            }
                                            .react-calendar__viewContainer {
                                                .react-calendar__month-view__weekdays__weekday {
                                                    font-size: 7px;
                                                }
                                                .react-calendar__tile {
                                                    border: none !important;
                                                    background-color: transparent;
                                                    padding: 8px 12px !important;
                                                    margin: 0px;
                                                    abbr {
                                                        font-size: 10px;
                                                    }
                                                }
                                                .react-calendar__tile--active,
                                                .react-calendar__tile--hasActive {
                                                    background: var(--colorPrincipal);
                                                    color: $white;
                                                    font-family: $archivo_bold;
                                                }
                                                .react-calendar__tile--rangeStart {
                                                    border-radius: 15px 0px 0px 15px;
                                                }
                                                .react-calendar__tile--rangeEnd {
                                                    border-radius: 0px 15px 15px 0px;
                                                }
                                                .react-calendar__tile--rangeBothEnds,
                                                .react-calendar__tile--hasActive {
                                                    border-radius: 100px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
                .col-2 {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 128px;
                    width: 258px;
                    .col-item {
                        align-items: center;
                        background-color: $azul2;
                        display: flex;
                        font-family: $archivo_bold;
                        font-size: 16px;
                        height: 62px;
                        justify-content: space-between;
                        padding: 0px 14px 0px 26px;
                        width: 100%;
                        .ylm-toggle {
                            p {
                                display: none;
                            }
                        }
                    }
                }
                .picker {
                    cursor: pointer;
                    display: flex;
                    margin-left: 10px;
                    padding-bottom: 27px;
                    position: relative;
                    width: 180px;
                    .swatch {
                        border-radius: 50%;
                        height: 10px;
                        position: relative;
                        width: 10px;
                    }
                    .popover {
                        position: absolute;
                        right: -40px;
                        top: 0px;
                        z-index: 3;
                    }
                }
                .delays {
                    align-items: center;
                    display: flex;
                    min-width: 200px;
                    .delay {
                        align-items: center;
                        cursor: pointer;
                        display: flex;
                        position: relative;
                        .delay-select {
                            align-items: center;
                            display: flex;
                            margin-left: 8px;
                            p {
                                font-family: $archivo_bold;
                                font-size: 14px;
                                &.underline {
                                    text-decoration: underline;
                                }
                            }
                            & > span {
                                margin-left: 9px;
                            }
                            .delay-options {
                                background-color: $white;
                                border: 1px solid $calendar-pasado;
                                top: 25px;
                                box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.15);
                                color: $black;
                                display: none;
                                list-style: none;
                                padding: 0px 16px;
                                position: absolute;
                                z-index: 3;
                                li {
                                    border-bottom: 1px solid $calendar-pasado;
                                    cursor: pointer;
                                    padding: 8px 0px;
                                    &:last-of-type {
                                        border: none;
                                    }
                                    &:hover {
                                        opacity: 0.8;
                                    }
                                }
                                &.active {
                                    display: block;
                                }
                            }
                        }
                    }
                    .delay-time {
                        align-items: center;
                        cursor: pointer;
                        display: flex;
                        margin-left: 10px;
                        position: relative;
                        .delay-time-select {
                            align-items: center;
                            display: flex;
                            margin-left: 8px;
                            p {
                                font-family: $archivo_bold;
                                font-size: 14px;
                                &.underline {
                                    text-decoration: underline;
                                }
                            }
                            & > span {
                                margin-left: 9px;
                            }
                            .delay-time-options {
                                background-color: $white;
                                border: 1px solid $calendar-pasado;
                                top: 25px;
                                box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.15);
                                color: $black;
                                display: none;
                                list-style: none;
                                padding: 0px 16px;
                                position: absolute;
                                width: 100px;
                                z-index: 3;
                                li {
                                    border-bottom: 1px solid $calendar-pasado;
                                    cursor: pointer;
                                    padding: 8px 0px;
                                    &:last-of-type {
                                        border: none;
                                    }
                                    &:hover {
                                        opacity: 0.8;
                                    }
                                }
                                &.active {
                                    display: block;
                                }
                            }
                        }
                    }  
                }
            }
            .user-tabs {
                align-items: center;
                display: flex;
                justify-content: space-between;
                height: 40px;
                margin-top: 22px;
                .user-tab {
                    align-items: center;
                    background-color: $azul2;
                    color: var(--colorPrincipal);
                    cursor: pointer;
                    display: flex;
                    font-size: 16px;
                    justify-content: center;
                    height: 100%;
                    width: calc( (100% - 15px) / 4 );
                    .tab-icon {
                        display: inline-flex;
                        font-family: $archivo_medium;
                    }
                    p {
                        margin-left: 13px;
                    }
                    &.active {
                        background-color: var(--colorPrincipal);
                        color: $white;
                        font-family: $archivo_bold;
                        svg {
                            path {
                                fill: $white;
                            }
                        }
                    }
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            .user-secondary {
                height: calc( 100vh - 452px);
                overflow: auto;
                .horario {
                    .add-sucursal {
                        align-items: center;
                        background-color: $azul2;
                        color: var(--colorPrincipal);
                        cursor: pointer;
                        display: flex;
                        font-family: $archivo_semibold;
                        font-size: 16px;
                        height: 70px;
                        margin-bottom: 3px;
                        margin-top: 3px;
                        padding: 0px 33px;
                        position: relative;
                        .add-sucursal-options {
                            color: $gris_oscuro;
                            display: none;
                            font-family: $archivo_regular;
                            font-size: 14px;
                            list-style: none;
                            padding: 5px 8px 5px 19px;
                            position: absolute;
                            top: 25px;
                            width: 150px;
                            &.active {
                                display: block;
                            }
                        }
                    }
                    .sucursal-item {
                        background-color: $azul2;
                        cursor: pointer;
                        display: flex;
                        margin-bottom: 3px;
                        padding: 26px 21px 26px 40px;
                        &.selected {
                            background-color: $azul3;
                        }
                        & > div {
                            padding-right: 10px;
                        }
                        .name {
                            color: var(--colorPrincipal);
                            font-family: $archivo_bold;
                            font-size: 16px;
                            width: 185px;
                        }
                        .horarios {
                            display: flex;
                            flex-wrap: wrap;
                            width: 538px;
                            .horario {
                                display: flex;
                                flex-direction: column;
                                width: 175px;
                                .dias {
                                    display: flex;
                                    font-family: $archivo_bold;
                                    font-size: 16px;
                                    /* height: 34px; */
                                    overflow: hidden;
                                    width: calc(100% - 10px);
                                    .text {
                                        display: flex;
                                        flex-wrap: wrap;
                                        .separador {
                                            margin: 0px 3px;
                                            &.coma {
                                                margin: 0px 3px 0px 0px;
                                            }
                                        }
                                    }
                                    .chevron {
                                        position: relative;
                                        margin-left: 5px;
                                        top: -2px;
                                    }
                                }
                                .horas {
                                    margin-top: 8px;
                                    margin-bottom: 18px;
                                    .hora {
                                        align-items: center;
                                        display: flex;
                                        font-family: $archivo_medium;
                                        font-size: 14px;
                                        margin-bottom: 2px;
                                        .hora-input {
                                            position: relative;
                                            .h-input {
                                                align-items: center;
                                                display: flex;
                                                font-family: $archivo_bold;
                                                font-size: 14px;
                                                height: 30px;
                                                justify-content: flex-start;
                                                width: 70px;
                                                &.active {
                                                    border: 2px solid var(--colorPrincipal);
                                                }
                                            }
                                            .selector-wrapper {
                                                background-color: $white;
                                                box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.15);
                                                display: none;
                                                padding: 10px;
                                                position: absolute;
                                                top: 32px;
                                                .selectors {
                                                    align-items: center;
                                                    display: flex;
                                                    .selector {
                                                        width: 50px;
                                                        .selector-value {
                                                            font-family: $archivo_bold;
                                                            font-size: 20px;
                                                            padding: 10px;
                                                            text-align: center;
                                                            width: 100%;
                                                        }
                                                        .btn {
                                                            display: flex;
                                                            justify-content: center;
                                                            padding: 10px 0px;
                                                            &.btn-add {
                                                                transform: rotate(180deg);
                                                            }
                                                        }
                                                    }
                                                    .separator {
                                                        font-family: $archivo_bold;
                                                        font-size: 20px;
                                                        position: relative;
                                                        top: -1px;
                                                    }
                                                }
                                                & > button {
                                                    background-color: var(--colorPrincipal);
                                                    border: none;
                                                    color: $white;
                                                    font-family: $archivo_medium;
                                                    font-size: 14px;
                                                    margin-top: 10px;
                                                    padding: 5px 0px;
                                                    width: 100%;
                                                }
                                                &.active {
                                                    display: block;
                                                    z-index: 5;
                                                }
                                            }
                                        }
                                        .sep-a {
                                            margin: 0px 9px;
                                        }
                                    }
                                }
                            }
                        }
                        &.active {
                            background-color: $azul3;
                            .horarios {
                                .horario {
                                    .horas {
                                        .hora {
                                            .hora-input {
                                                .h-input {
                                                    background-color: $white;
                                                    font-family: $archivo_medium;
                                                    padding-left: 11px;
                                                }
                                            }
                                        }
                                    }

                                }
                            }
                        }
                    }
                }

                .descansos {
                    padding-top: 24px;
                    .descansos-tabs {
                        display: flex;
                        justify-content: space-between;
                        .descansos-tab {
                            align-items: center;
                            background-color: #FAFAFA;
                            color: var(--colorPrincipal);
                            cursor: pointer;
                            display: flex;
                            font-family: $archivo_bold;
                            height: 35px;
                            justify-content: center;
                            width: calc(50% - 2px);
                            &.active {
                                background-color: $azul3;
                            }
                        }
                    }
                    .vacacional {
                        margin-top: 3px;
                        .vacacional-item {
                            align-items: center;
                            background-color: $azul3;
                            color: var(--colorPrincipal);
                            display: flex;
                            min-height: 70px;
                            padding: 0px 21px 0px 33px;
                            .new-item-txt {
                                cursor: pointer;
                                font-family: $archivo_semibold;
                                font-size: 16px;
                            }
                            .new-item-row {
                                align-items: center;
                                color: $gris_oscuro;
                                display: flex;
                                justify-content: space-between;
                                font-family: $archivo_medium;
                                width: 100%;
                                .calendar-row {
                                    align-items: center;
                                    display: flex;
                                    width: 491px;
                                    span {
                                        color: $azul6;
                                        margin: 0px 19px;
                                    }
                                    .date-row {
                                        align-items: center;
                                        display: flex;
                                        &.del {
                                            span {
                                                margin: 0px 12px 0px 0px;
                                            }
                                        }
                                        .date-input {
                                            align-items: center;
                                            background-color: $white;
                                            cursor: pointer;
                                            display: flex;
                                            justify-content: center;
                                            height: 30px;
                                            position: relative;
                                            width: 131px;
                                            .vacacional-calendar {
                                                font-family: $archivo_regular;
                                                font-size: 10px;
                                                left: 0px;
                                                position: absolute;
                                                top: 30px;
                                                width: 287px;
                                                .react-calendar {
                                                    position: relative;
                                                    background-color: $white;
                                                    border: 1px solid $gris4;
                                                    z-index: 5;
                                                    .react-calendar__navigation {
                                                        padding: 17px 0px 8px;
                                                        button {
                                                            font-size: 15px;
                                                        }
                                                        .react-calendar__navigation__label span {
                                                            font-family: $archivo_bold;
                                                            font-size: 12px;
                                                            color: $black;
                                                        }
                                                    }
                                                    .react-calendar__viewContainer {
                                                        .react-calendar__month-view__weekdays__weekday {
                                                            font-size: 7px;
                                                        }
                                                        .react-calendar__tile {
                                                            border: none !important;
                                                            background-color: transparent;
                                                            padding: 8px 12px !important;
                                                            margin: 0px;
                                                            abbr {
                                                                font-size: 10px;
                                                            }
                                                        }
                                                        .react-calendar__tile--active,
                                                        .react-calendar__tile--hasActive {
                                                            background: var(--colorPrincipal);
                                                            color: $white;
                                                            font-family: $archivo_bold;
                                                        }
                                                        .react-calendar__tile--rangeStart {
                                                            border-radius: 15px 0px 0px 15px;
                                                        }
                                                        .react-calendar__tile--rangeEnd {
                                                            border-radius: 0px 15px 15px 0px;
                                                        }
                                                        .react-calendar__tile--rangeBothEnds,
                                                        .react-calendar__tile--hasActive {
                                                            border-radius: 100px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .time-input {
                                            align-items: center;
                                            background-color: $white;
                                            display: flex;
                                            height: 30px;
                                            justify-content: center;
                                            margin-left: 3px;
                                            width: 70px;
                                            .hora-input {
                                                width: 100%;
                                                .h-input {
                                                    font-family: $archivo_medium;
                                                    justify-content: center;
                                                }
                                            }
                                        }
                                    }
                                    &.hide-hours {
                                        .time-input {
                                            display: none !important;
                                        }
                                    }
                                }
                                .ylm-checkbox {
                                    margin-left: 12px;
                                    flex: 1 0 auto;
                                    p {
                                        font-family: $archivo_regular;
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                        .botonera {
                            align-items: center;
                            display: flex;
                            .btn {
                                align-items: center;
                                background-color: $white;
                                cursor: pointer;
                                display: flex;
                                height: 50px;
                                justify-content: center;
                                margin-right: 14px;
                                width: 50px;
                                &:last-of-type {
                                    margin-right: 0px;
                                }
                                svg {
                                    path {
                                        fill: var(--colorPrincipal);
                                    }
                                }
                                &:hover {
                                    opacity: 0.8;
                                }
                            }
                        }
                    }
                }
                .servicios {
                    .ylm-select {
                        align-items: center;
                        cursor: pointer;
                        flex-direction: row;
                        span {
                            margin-left: 12px;
                        }
                        .ylm-select-options {
                            border: none;
                            box-shadow: 0px 2px 10px #cccccc;
                            top: 35px;
                            width: 470px;
                            .select-option {
                                color: $black;
                                font-family: $archivo_regular;
                                font-size: 14px;
                            }
                            &.active {
                                max-height: 221px;
                            }
                        }
                    }
                    .add-servicio {
                        align-items: center;
                        background-color: $azul3;
                        color: var(--colorPrincipal);
                        display: flex;
                        font-family: $archivo_semibold;
                        font-size: 16px;
                        height: 70px;
                        margin-top: 3px;
                        padding: 0px 21px 0px 33px;
                        span {
                            path {
                                stroke: var(--colorPrincipal);
                            }
                        }
                    }
                }
                .permisos {
                    .ylm-select {
                        align-items: center;
                        cursor: pointer;
                        flex-direction: row;
                        span {
                            margin-left: 12px;
                        }
                        .ylm-select-options {
                            border: none;
                            box-shadow: 0px 2px 10px #cccccc;
                            top: 35px;
                            min-width: 200px;
                            .select-option {
                                color: $black;
                                font-family: $archivo_regular;
                                font-size: 14px;
                            }
                            &.active {
                                max-height: 221px;
                            }
                        }
                    }
                    .add-permiso {
                        align-items: center;
                        background-color: $azul3;
                        color: var(--colorPrincipal);
                        display: flex;
                        font-family: $archivo_semibold;
                        font-size: 16px;
                        height: 70px;
                        margin-top: 3px;
                        padding: 0px 21px 0px 33px;
                        span {
                            path {
                                stroke: var(--colorPrincipal);
                            }
                        }
                    }
                }
            }
        }
        .single-cliente {
            padding: 44px 30px;
            .ultima-reserva {
                .cita-row {
                    align-items: center;
                    background-color: $azul2;
                    font-family: $archivo_bold;
                    height: 70px;
                    display: flex;
                    margin-top: 5px;
                    position: relative;
                    & > div {
                        margin-left: 15px;
                    }
                    .cita-date {
                        font-family: $archivo_regular;
                        margin-left: 33px;
                        width: calc( 66px + 102px );
                    }
                    .cita-servicio {
                        font-family: $archivo_regular;
                        width: calc( 92px + 73px );
                    }
                    .cita-profesional {
                        width: calc( 35px + 82px );
                    }
                    .cita-sucursal {
                        width: calc( 90px + 66px );
                    }
                    .cita-activa {
                        flex: 1 1 auto;
                    }
                    .cita-indicador {
                        background-color: $rojo1;
                        height: 100%;
                        width: 6px;
                        &.active {
                            background-color: $verde1;
                        }
                    }
                }
                .new-cita {
                    align-items: center;
                    background-color: $azul2;
                    color: var(--colorPrincipal);
                    cursor: pointer;
                    display: flex;
                    font-family: $archivo_bold;
                    font-size: 16px;
                    height: 70px;
                    justify-content: center;
                    margin-top: 5px;
                }
            }
            .comentarios {

                .comentario-row {
                    align-items: center;
                    background-color: $azul2;
                    font-family: $archivo_bold;
                    height: 70px;
                    display: flex;
                    margin-top: 5px;
                    position: relative;
                    & > div {
                        margin-left: 15px;
                    }
                    .comentario-date {
                        font-family: $archivo_regular;
                        margin-left: 33px;
                        width: calc( 66px + 102px );
                    }
                    .comentario-text {
                        font-family: $archivo_regular;
                        flex: 1 1 auto;
                    }
                    button {
                        align-items: center;
                        background-color: $white;
                        border: none;
                        display: flex;
                        height: 50px;
                        justify-content: center;
                        margin-left: 10px;
                        margin-right: 15px;
                        padding: 0px;
                        width: 50px;
                        svg {
                            margin-left: 2px;
                        }
                    }
                }
                .new-comentario {
                    align-items: center;
                    background-color: $azul2;
                    color: var(--colorPrincipal);
                    cursor: pointer;
                    display: flex;
                    font-family: $archivo_bold;
                    font-size: 16px;
                    height: 70px;
                    justify-content: center;
                    margin-top: 5px;
                    padding: 0px 15px;
                    .comentario-text {
                        align-items: center;
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        input {
                            font-size: 14px;
                            height: 35px;
                            padding: 0px 10px;
                            width: 100%;
                            &::placeholder {
                                color: $gris_medio;
                            }
                        }
                        button {
                            align-items: center;
                            background-color: $white;
                            border: none;
                            display: flex;
                            height: 50px;
                            justify-content: center;
                            margin-left: 10px;
                            padding: 0px;
                            width: 50px;
                            svg {
                                margin-left: 2px;
                            }
                        }
                    }
                }
            }
            .bono-servicio {
                .bono-item {
                    background-color: $azul2;
                    margin-top: 6px;
                    padding: 21px;
                    .first-row {
                        table {
                            table-layout: fixed;
                            width: 100%;
                            thead {
                                th {
                                    color: $azul_flojo;
                                    font-family: $archivo_bold;
                                    font-size: 10px;
                                    text-align: left;
                                    text-transform: uppercase;
                                    &:nth-child(1) {
                                        width: 16%;
                                    }
                                    &:nth-child(3) {
                                        width: 10%;
                                    }
                                    &:nth-child(4) {
                                        width: 10%;
                                    }
                                    &:nth-child(5) {
                                        width: 10%;
                                    }
                                    &:nth-child(6) {
                                        width: 18%;
                                    }
                                }
                            }
                            td {
                                padding: 14px 15px 14px 0px;
                                &.precio,
                                &.duracion,
                                &.renovacion {
                                    font-family: $archivo_bold;
                                }
                            }
                        }
                    }
                    .second-row {
                        margin-top: 20px;
                        table {
                            table-layout: fixed;
                            width: 100%;
                            thead {
                                th {
                                    color: $azul_flojo;
                                    font-family: $archivo_bold;
                                    font-size: 10px;
                                    text-align: left;
                                    text-transform: uppercase;
                                    &:nth-child(2) {
                                        width: 18%;
                                    }
                                }
                            }
                            tbody {
                                .sellos {
                                    align-items: center;
                                    display: flex;
                                    justify-content: flex-start;
                                    flex-wrap: wrap;
                                    .sello {
                                        align-items: center;
                                        background-color: $azul_flojo;
                                        border-radius: 50%;
                                        color: $white;
                                        display: flex;
                                        font-family: $archivo_bold;
                                        height: 50px;
                                        justify-content: center;
                                        margin-right: 12px;
                                        width: 50px;
                                        &.active {
                                            background-color: var(--colorPrincipal);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .bono-importe {
                table {
                    border-collapse: collapse;
                    margin-top: 30px;
                    table-layout: fixed;
                    width: 100%;
                    thead {
                        th {
                            font-family: $archivo_bold;
                            &:nth-child(1) {
                                text-align: left;
                                width: 18%;
                            }
                            &:nth-child(2) {
                                text-align: left;
                                width: 15%;
                            }
                            &:nth-child(3) {
                                width: 9%;
                            }
                            &:nth-child(4) {
                                width: 11%;
                            }
                            &:nth-child(5) {
                                width: 13%;
                            }
                            &:nth-child(7) {
                                width: calc( 12% - 6px );
                            }
                            &:nth-child(8) {
                                width: 6px;
                            }
                        }
                    }
                    tbody {
                        tr {
                            background-color: $azul2;
                            border-top: 8px solid $white;
                            &:nth-child(1) {
                                border-top: 11px solid $white;
                            }
                            td {
                                padding: 24px 15px 24px 0px;
                                text-align: center;
                                &:nth-child(1) {
                                    padding-left: 25px;
                                    text-align: left;
                                }
                                &:nth-child(2) {
                                    text-align: left;
                                }
                                &.renovacion,
                                &.duracion,
                                &.estado {
                                    font-family: $archivo_bold;
                                }
                                &.green {
                                    background-color: $verde1;
                                }
                            }
                        }
                    }
                }
            }
            .cliente-saldos {
                .cliente-saldos-row {
                    align-items: center;
                    background-color: $azul2;
                    font-family: $archivo_bold;
                    height: 70px;
                    display: flex;
                    margin-top: 5px;
                    position: relative;
                    & > div {
                        margin-left: 15px;
                    }
                    .cliente-saldos-date {
                        font-family: $archivo_regular;
                        margin-left: 33px;
                        width: calc( 66px + 102px );
                    }
                    .cliente-saldos-text {
                        font-family: $archivo_regular;
                        flex: 1 1 auto;
                    }
                }
            }
        }
    }
}