.horarios-container {
    padding: 22px 0px 15px;
    @include lg(){
        padding: 22px 30px 15px;
    }
    .horarios-header {
        display: flex;
        align-items: center;
        padding-bottom: 35px;
        padding-left: 15px;
        @include lg(){
            padding-left: 0px;
        }
        .left, .right {
            font-size: 20px;
            cursor: pointer;
            @include lg(){
                font-size: 10px;
            }
        }
        .header-fecha {
            font-family: 'Archivo-Bold';
            font-size: 20px;
            margin: 0px 15px;
            .fecha {
                margin-left: 8px;
            }
        }
    }
    .no-data {
        padding: 30px 0px 30px;
        text-align: center;
        h4 {
            font-family: 'Archivo-Bold';
            font-size: 20px;
            margin: 0 auto;
            text-align: center;
            width: calc( 100% - 40px);
        }
    }
    .single-container {
        h4 {
            font-family: $archivo_regular;
            font-size: 16px;
            padding-left: 15px;
            padding-bottom: 7px;
        }
        .horas {
            display: flex;
            flex-wrap: wrap;
            .horario-item {
                display: flex;
                align-items: center;
                border-top: 1px solid $calendar-border-gray;
                width: 33%;
                padding: 34px 0px;
                cursor: pointer;
                @include lg(){
                    width: 25%;
                    padding: 20px 0px;
                }
                
                .check {
                    position: relative;
                    display: block;
                    width: 16px;
                    height: 16px;
                    border: 1px solid $font;
                    border-radius: 50%;
                    margin-left: 13px;
                }
                p {
                    font-family: $archivo_regular;
                    font-size: 19px;
                    margin-left: 15px;
                    @include lg(){
                        font-size: 20px;
                    }
                }
                &.blocked {
                    position: relative;
                    color: $text-placeholder-grey;
                    cursor:default;
                    p {
                        position: relative;                    
                        &::after {
                            content: '';
                            position: absolute;
                            left: -3px;
                            bottom: 10px;
                            width: calc( 100% + 6px );
                            height: 2px;
                            background-color: $text-placeholder-grey;
                        }
                    }
                    .check {
                        border-color: $text-placeholder-grey;
                    }
                }
                &.active {
                    .check {
                        &::after {
                            content: '';
                            position: absolute;
                            display: block;
                            top: 2px;
                            left: 2px;
                            width: 10px;
                            height: 10px;
                            background-color: $font;
                            border-radius: 50%;
                        }
                    }
                    &.blocked {
                        .check {
                            background-color: $text-placeholder-grey;
                        }
                    }
                }
            }
        }
        
    }
}