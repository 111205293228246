.menu-lateral {
    flex-shrink: 0;
    height: calc(100vh - 79px);
    position: relative;
    transition: width 0.3s;
    width: 196px;
    .btn-minimize {
        position: absolute;
        right: -11px;
        bottom: 33px;
        z-index: 3;
        cursor: pointer;
        width: 23px;
        height: 23px;
        border-radius: 50%;
        background-color: $azul_flojo;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 1px;
        svg {
            fill: transparent;
            stroke: $white;
            stroke-width: 2px;
            transform:rotate(270deg);
            -ms-transform:rotate(270deg);
            -webkit-transform:rotate(270deg);
            transition: transform 0.3s;
            -webkit-transition: transform 0.3s;
        }
    }
    .menu-item {
        width: 100%;
        height: 62px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: var(--colorPrincipal);
        font-family: $archivo_medium;
        font-size: 14px;
        padding-left: 18px;
        cursor: pointer;
        &:last-of-type {
        }
        .menu-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 26px;
            margin-right: 33px;
            svg {
                path {
                    fill: var(--colorPrincipal);
                }
            }
        }
        &.active,
        &:hover {
            font-family: $archivo_bold;
            background-color: $menu_amarillo;
            color: var(--colorPrincipal);
        }
        &.menu-reservas {
            span { background-position: -4px -449px; }
            &.active, &:hover {
                span { background-position: -4px -482px; }
            }
        }
        &.menu-profesionales {
            span { width: 26px; height: 11px; background-position: -39px -449px; }
            &.active, &:hover {
                span { background-position: -39px -482px; }
            }
        }
        &.menu-clientes {
            span { width: 20px; height: 30px; background-position: -78px -449px; }
            &.active, &:hover {
                span { background-position: -78px -482px; }
            }
        }
        &.menu-servicios {
            span { width: 23px; height: 18px; background-position: -111px -449px; }
            &.active, &:hover {
                span { background-position: -111px -482px; }
            }
        }
        &.menu-notificaciones {
            span { width: 17px; height: 17px; background-position: -146px -449px; }
            &.active, &:hover {
                span { background-position: -146px -482px; }
            }
        }
        &.menu-locales {
            span { width: 12px; height: 27px; background-position: -176px -449px; }
            &.active, &:hover {
                span { background-position: -176px -482px; }
            }
        }
        &.menu-configuracion {
            span { width: 20px; height: 19px; background-position: -201px -449px; }
            &.active, &:hover {
                span { background-position: -201px -482px; }
            }
        }
        &.menu-super-admin {
            span { width: 19px; height: 19px; background-position: -234px -449px; }
            &.active, &:hover {
                span { background-position: -234px -482px; }
            }
        }
    }
    &.mini {
        width: 61px;
        .btn-minimize {
            padding-left: 1px;
            svg {
                transform:rotate(90deg);
                -ms-transform:rotate(90deg);
                -webkit-transform:rotate(90deg);
            }
            span {
                background-position: -34px -420px;
            }
        }
        .menu-item {
            padding: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            .menu-icon {
                margin: 0px;
            }
            p {
                display: none;
            }
        }
    }
}