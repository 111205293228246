.configuracion {
    display: flex;
    height: calc(100vh - 73px);
    width: 100%;
    .lateral {
        width: 196px;
        box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.15);
        .list {
            display: flex;
            flex-direction: column;
            .list-item {
                cursor: pointer;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                height: 62px;
                font-family: $archivo_bold;
                font-size: 14px;
                color: var(--colorPrincipal);
                text-transform: uppercase;
                padding-left: 25px;
                &.list-header {
                    text-transform: none;
                    background-color: $gestion_bg_filters;
                    cursor: default;
                }
                &.active {
                    background-color: var(--colorPrincipal);
                    color: $white;
                }
            }
        }
    }
    .principal {
        flex-grow: 1;
        overflow: auto;
    }
    .configuracion-wrapper {
        padding: 27px 41px 0px;
        .header {
            align-items: center;
            display: flex;
            justify-content: space-between;
            min-height: 56px;
            padding-bottom: 20px;
            width: 100%;
            h1 {
                font-family: $archivo_bold;
                font-size: 28px;
                color: var(--colorPrincipal);
            }
        }
        .sections {
            height: calc( 100vh - 156px );
            overflow: auto;
        }
        .section {
            margin-top: 30px;
            h3 {
                margin-top: 27px;
                font-size: 16px;
                color: $azul_flojo;
            }
            h5 {
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                color: $gris_oscuro;
                font-size: 14px;
                margin-top: 12px;
            }
            .selectors {
                display: flex;
                flex-direction: column;
                margin-top: 10px;
                .selector {
                    margin: 9px 0px;
                    .ylm-toggle {
                        p {
                            font-size: 16px;
                        }
                    }
                }
            }
            &.recomendacion {
                .recomendacion-items {
                    display: flex;
                    margin-top: 20px;
                    & > div {
                        margin-left: 80px;
                        &:first-of-type {
                            margin-left: 0px;
                        }
                    }
                }
            }
            &.distribucion {
                margin-bottom: 30px;
                max-width: calc(100vw - 339px);
                overflow: hidden;
                .dist-container {
                    margin-top: 13px;
                    .dist-item {
                        margin-bottom: 3px;
                        .dist-main {
                            display: flex;
                            .dist-number {
                                align-items: center;
                                border-radius: 8px;
                                color: $white;
                                display: flex;
                                font-family: $archivo_semibold;
                                font-size: 16px;
                                line-height: 25px;
                                justify-content: center;
                                height: 40px;
                                width: 40px;
                            }
                            .dist-name {
                                align-items: center;
                                background-color: $gris_boton_bg;
                                border-radius: 8px;
                                display: flex;
                                font-family: $archivo_medium;
                                font-size: 16px;
                                line-height: 25px;
                                height: 40px;
                                margin-left: 5px;
                                padding: 10px 15px 10px 22px;
                                width: 400px;
                            }
                        }
                        .dd-sucursales {
                            display: flex;
                            gap: 2px;
                            margin-left: 45px;
                            margin-top: 3px;
                            .dd-sucursal {
                                width: 155px;
                                .suc-title {
                                    align-items: center;
                                    background-color: $grey3;
                                    border-radius: 8px;
                                    display: flex;
                                    font-family: $archivo_medium;
                                    font-size: 16px;
                                    height: 40px;
                                    justify-content: center;
                                    padding: 0px 10px;
                                    text-align: center;
                                    width: 100%;
                                }
                                .suc-prof {
                                    align-items: center;
                                    background-color: $grey3;
                                    border-radius: 8px;
                                    display: flex;
                                    font-family: $archivo_medium;
                                    font-size: 14px;
                                    margin-top: 3px;
                                    min-height: 50px;
                                    justify-content: center;
                                    padding: 0px 10px;
                                    position: relative;
                                    text-align: center;
                                    width: 100%;
                                    .prof-arrows {
                                        background-color: rgba(255,255,255,0.5);
                                        display: flex;
                                        flex-direction: column;
                                        height: 100%;
                                        left: 0px;
                                        position: absolute;
                                        top: 0px;
                                        width: 20px;
                                        .btn-arrow {
                                            align-items: center;
                                            display: flex;
                                            height: 25px;
                                            justify-content: center;
                                            text-align: center;
                                            width: 100%;
                                            &.arrow-down {
                                                svg {
                                                    transform: rotate(180deg);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.franja {
                .franja-items {
                    display: flex;
                    margin-top: 20px;
                    & > div {
                        margin-left: 40px;
                        &:first-of-type {
                            margin-left: 0px;
                        }
                    }
                }
            }
            &.horario {
                .horario-items {
                    display: flex;
                    margin-top: 20px;
                    & > div {
                        margin-left: 40px;
                        &:first-of-type {
                            margin-left: 0px;
                        }
                    }
                }
            }
            &.cancelacion {
                h5 {
                    input {
                        border: none;
                        background-color: $azul2;
                        width: 32px;
                        height: 30px;
                        text-align: center;
                        color: $gris_oscuro;
                        font-family: $archivo_bold;
                        font-size: 14px;
                    }
                }
                .cancelacion-items {
                    display: flex;
                    margin-top: 20px;
                    & > div {
                        margin-left: 40px;
                        &:first-of-type {
                            margin-left: 0px;
                        }
                    }
                }
            }
            &.productos {
                h5 {
                    input {
                        border: none;
                        background-color: $azul2;
                        width: 32px;
                        height: 30px;
                        text-align: center;
                        color: $gris_oscuro;
                        font-family: $archivo_bold;
                        font-size: 14px;
                    }
                }
            }
            &.prepago {
                h5 {
                    input {
                        border: none;
                        background-color: $azul2;
                        width: 32px;
                        height: 30px;
                        text-align: center;
                        color: $gris_oscuro;
                        font-family: $archivo_bold;
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .customize-body {
        display: flex;

        .tabs {
            display: flex;
            margin-top: 33px;
            .tab {
                width: 123px;
                height: 35px;
                background-color: transparent;
                color: $azul_flojo;
                font-family: $archivo_medium;
                font-size: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                
                cursor: pointer;
                &.active {
                    background-color: $azul_extraflojo;
                    color: var(--colorPrincipal);
                    font-family: $archivo_bold;
                }
                &:hover {
                    opacity: 0.8;
                }
            }
        }
        .left {
            display: flex;
            flex-direction: column;
            width: 431px;
        }
        .right {
            margin-left: 16px;
            .tabs {
                margin-left: 7px;
                .tab {
                    cursor: default;
                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
        .panel-body {
            margin-top: 25px;
            .panel-container {
                display: flex;
                .identidad-left {
                    .galeria {
                        width: 223px;
                        .main-img {
                            position: relative;
                            background-size: cover;
                            background-position: center;
                            width: 223px;
                            height: 270px;
                            border-radius: 10px;
                            p {
                                bottom: 15px;
                                font-size: 9px;
                                left: 0px;
                                position: absolute;
                                text-align: center;
                                width: 100%;
                            }
                        }
                        .img-grid {
                            display: grid;
                            grid-template-columns: repeat(4, 1fr);
                            justify-items: center;
                            justify-content: space-between;
                            gap: 4px;
                            margin-top: 11px;
                            .img-item {
                                background-color: $gestion_bg_menu;
                                width: 52px;
                                height: 52px;
                                border-radius: 8px;
                                cursor: pointer;
                                &:hover {
                                    opacity: 0.8;
                                }
                                &.add-button {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    span {
                                        display: inline-block;
                                        color: $white;
                                        font-family: $archivo_bold;
                                        font-size: 30px;
                                        position: relative;
                                        top: -2px;
                                    }
                                }
                            }
                        }
                    }
                }
                .identidad-right {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin-left: 9px;
                    width: 199px;
                    height: 270px;
                    .identidad-input {
                        position: relative;
                        background-color: $gestion_bg_menu;
                        border-radius: 8px;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 40px;
                        padding: 10px 10px 10px 13px;
                        input, textarea {
                            font-family: $archivo_regular;
                            font-size: 9px;
                            width: 100%;
                            background: none;
                            border: none;
                            &::placeholder {
                                color: $modal_texto;
                            }
                        }
                        &.icon {
                            display: flex;
                            flex-direction: row;

                            .btn-upload-icon {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                background: none;
                                background-color: var(--colorPrincipal);
                                border: none;
                                cursor: pointer;
                                border-radius: 8px;
                                color: $white;
                                position: absolute;
                                top: 0px;
                                right: 0px;
                                font-family: $archivo_bold;
                                font-size: 11px;
                                width: 60px;
                                height: 100%;
                                &:hover {
                                    opacity: 0.8;
                                }
                            }
                        }
                        & > .icon {
                            width: 26px;
                        }
                        &.title {
                            height: 35px;
                        }
                        &.description {
                            height: 71px;
                            align-items: flex-start;
                            textarea {
                                height: 100%;
                            }
                        }
                    }
                    .identidad-btn {
                        display: block;
                        background: none;
                        border: 2px solid var(--colorPrincipal);
                        border-radius: 8px;
                        color: var(--colorPrincipal);
                        font-family: $archivo_bold;
                        font-size: 11px;
                        height: 48px;
                        &:hover {
                            opacity: .8;
                        }
                    }
                }
            }
            &.right-body {
                .prev-canvas {
                    align-items: center;
                    background-color: $gestion_bg_menu;
                    border-radius: 20px;
                    display: flex;
                    height: 403px;
                    justify-content: center;
                    width: 245px;
                    .screen {
                        background-color: $white;
                        border-radius: 18px;
                        height: 314px;
                        position: relative;
                        width: 177px;
                        .slick-slider {
                            width: 100%;
                            height: 100%;
                            .slick-list {
                                width: 100%;
                                height: 100%;
                                .slick-track {
                                    width: 100%;
                                    height: 100%;
                                    .slick-slide {  
                                        width: 100%;
                                        height: 100%;      
                                        & > div { 
                                            width: 100%;
                                            height: 100%;  
                                        }         
                                        .slider-item {
                                            position: relative;
                                            height: 100%;  
                                            width: 100%;
                                            .first-header {
                                                align-items: center;
                                                display: flex;
                                                justify-content: space-between;
                                                padding: 12px 6px 11px 5px;
                                                .bg-btn {
                                                    align-items: center;
                                                    border-radius: 50%;
                                                    display: flex;
                                                    height: 36px;
                                                    justify-content: center;
                                                    position: relative;
                                                    width: 36px;
                                                    .header-btn {
                                                        align-items: center;
                                                        background-color: $white;
                                                        border-radius: 50%;
                                                        display: flex;
                                                        justify-content: center;
                                                        height: 27px;
                                                        width: 27px;
                                                        .badge {
                                                            align-items: center;
                                                            border-radius: 50%;
                                                            color: $white;
                                                            display: flex;
                                                            font-family: $archivo_bold;
                                                            font-size: 6px;
                                                            justify-content: center;
                                                            height: 10px;
                                                            position: absolute;
                                                            right: 3px;
                                                            top: 3px;
                                                            width: 10px;
                                                        }
                                                    }
                                                }
                                            }
                                            .second-header {
                                                align-items: center;
                                                display: flex;
                                                justify-content: center;
                                                padding: 15px 0px;
                                                width: 100%;
                                                .header-item {
                                                    align-items: center;
                                                    display: flex;
                                                    border-radius: 100px;
                                                    font-family: $archivo_semibold;
                                                    font-size: 6px;
                                                    justify-content: center;
                                                    letter-spacing: 1px;
                                                    min-width: 47px;
                                                    padding: 5px;
                                                    text-transform: uppercase;
                                                }
                                                .header-user {
                                                    position: absolute;
                                                    right: 13px;
                                                    top: 11px;
                                                }
                                            }
                                            .next-reservation {
                                                padding: 0px 5px;
                                                h3 {
                                                    align-items: center;
                                                    display: flex;
                                                    margin-left: 13px;
                                                    span {
                                                        font-family: $archivo_bold;
                                                        font-size: 9px;
                                                        margin-left: 4px;
                                                        opacity: 0.5;
                                                        text-transform: uppercase;
                                                    }
                                                }
                                                .next-row {
                                                    align-items: center;
                                                    border-radius: 10px;
                                                    display: flex;
                                                    font-family: $archivo_bold;
                                                    font-size: 10px;
                                                    justify-content: space-between;
                                                    margin-top: 7px;
                                                    padding: 11px 0px;
                                                    .row-date {
                                                        margin-left: 15px;
                                                    }
                                                    .row-arrow {
                                                        margin-left: 15px;
                                                        margin-right: 16px;
                                                    }
                                                }
                                            }
                                            .hero {
                                                align-items: center;
                                                border-radius: 10px;
                                                display: flex;
                                                height: 154px;
                                                justify-content: center;
                                                margin: 7px auto 0px;
                                                width: 167px;
                                                .hero-content {
                                                    align-items: center;
                                                    color: $white;
                                                    display: flex;
                                                    flex-direction: column;
                                                    h3 {
                                                        font-size: 10px;
                                                        font-weight: 700px;
                                                        line-height: 14px;
                                                        margin-top: 9px;
                                                        text-align: center;
                                                        text-transform: uppercase;
                                                        width: 105px;
                                                    }
                                                    p {
                                                        font-size: 8px;
                                                        line-height: 12px;
                                                        font-weight: 600px;
                                                        margin-top: 9px;
                                                        text-align: center;
                                                        width: 129px;
                                                    }
                                                }
                                            }
                                            .shop-items {
                                                display: flex;
                                                flex-direction: column;
                                                padding: 0px 4px;
                                                .shop-item {
                                                    border-radius: 20px;
                                                    display: flex;
                                                    flex-direction: column;
                                                    margin-bottom: 5px;
                                                    padding: 6px 13px 6px 12px;
                                                    .row-1 {
                                                        h4 {
                                                            font-family: $archivo_bold;
                                                            font-size: 6px;
                                                            line-height: 15px;
                                                            margin-left: 17px;
                                                            opacity: 0.5;
                                                            text-transform: uppercase;
                                                        }
                                                    }
                                                    .row-2 {
                                                        display: flex;
                                                        position: relative;
                                                        .row-title {
                                                            margin-left: 6px;
                                                            flex: 1;
                                                            h2 {
                                                                font-size: 10px;
                                                                line-height: 12px;
                                                                min-height: 27px;
                                                            }
                                                        }
                                                        .row-btn {
                                                            align-items: center;
                                                            border: 1px solid transparent;
                                                            border-radius: 50%;
                                                            cursor: pointer;
                                                            display: flex;
                                                            height: 25px;
                                                            justify-content: center;
                                                            position: relative;
                                                            top: -3px;
                                                            width: 26px;
                                                        }
                                                    }
                                                    .row-3 {
                                                        display: flex;
                                                        padding-left: 17px;
                                                        .column {
                                                            font-family: $archivo_bold;
                                                            font-size: 6px;
                                                            line-height: 7px;
                                                            flex: 1;
                                                            h5 {
                                                                opacity: 0.5;
                                                                text-transform: uppercase;
                                                            }
                                                            &.promo-column {
                                                                flex: none;
                                                                width: 35px;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .common-footer {
                                                align-items: center;
                                                border-radius: 0px 0px 20px 20px;
                                                display: flex;
                                                height: 30px;
                                                justify-content: space-around;
                                                position: absolute;
                                                bottom: 0;
                                                width: 100%;
                                                z-index: 10;
                                                .footer-btn {
                                                    position: relative;
                                                    &.central {
                                                        .central-wrapper {
                                                            align-items: center;
                                                            bottom: 12px;
                                                            display: flex;
                                                            flex-direction: column;
                                                            font-family: $archivo_regular;
                                                            font-size: 6px;
                                                            letter-spacing: 1px;
                                                            position: relative;
                                                            text-transform: uppercase;
                                                            width: 28px;   
                                                            .central-btn {
                                                                align-items: center;
                                                                border-radius: 50%;
                                                                display: flex;
                                                                height: 35px;
                                                                justify-content: center;
                                                                margin-bottom: 3px;
                                                                width: 35px;
                                                            } 
                                                        }                          
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            /* Slick slider custom */
                            .slick-dots {
                                align-items: center;
                                bottom: -16px;
                                display: flex !important;
                                justify-content: center;
                                list-style: none;
                                position: absolute;
                                width: 100%;
                                .custom-dot {
                                    background-color: $white;
                                    border-radius: 50%;
                                    cursor: pointer;
                                    display: block;
                                    margin: 0 3px;
                                    height: 6px;
                                    width: 6px;
                                }
                                .slick-active {
                                    .custom-dot {
                                        background-color: #7B7B7B;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .custom-row {
        width: 691px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 26px;
        .input-container {
            width: 327px;
            h5 {
                color: $azul_flojo;
                font-family: $archivo_medium;
                font-size: 16px;
                margin-bottom: 15px;
            }
            .customize-input {
                position: relative;
                background-color: $gestion_bg_menu;
                border-radius: 8px;
                display: flex;
                align-items: center;
                width: 100%;
                height: 40px;
                padding: 10px 10px 10px 13px;
                input, textarea {
                    font-family: $archivo_regular;
                    font-size: 9px;
                    width: 100%;
                    background: none;
                    border: none;
                    &::placeholder {
                        color: $modal_texto;
                    }
                }
                &.icon {
                    display: flex;
                    flex-direction: row;

                    .btn-upload-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        background: none;
                        background-color: var(--colorPrincipal);
                        border: none;
                        cursor: pointer;
                        border-radius: 8px;
                        color: $white;
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        font-family: $archivo_bold;
                        font-size: 11px;
                        width: 60px;
                        height: 100%;
                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }
                & > .icon {
                    width: 26px;
                }
                &.title {
                    height: 35px;
                }
                &.description {
                    height: 71px;
                    align-items: flex-start;
                    textarea {
                        height: 100%;
                    }
                }
            }
        }
    }

    // Apartado colores

    .color-container {
        height: 403px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        overflow: auto;
        .color-selector {
            display: flex;
            margin: 14px 0px;
            cursor: pointer;
            .color-theme {
                display: flex;
                margin-left: 10px;
                .color {
                    width: 34px;
                    height: 34px;
                    border-radius: 50%;
                }
                .main-color {

                }
                .secondary-colors {
                    display: flex;
                    margin-left: 45px;
                    .color {
                        margin-right: 12px;
                        &:last-of-type {
                            margin-right: 0px;
                        }
                    }
                }
            }
        }
    }

    // Fin apartado colores

    // Apartado tipografía

    .typo-container {
        height: 403px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        overflow: auto;
        .typo-selector {
            display: flex;
            align-items: center;
            margin: 14px 0px;
            cursor: pointer;
            .typo-theme {
                display: flex;
                align-items: flex-end;
                margin-left: 10px;
                .font-big {
                    font-size: 40px;
                }
                .font-name {
                    margin-left: 15px;
                    font-size: 15px;
                    padding-bottom: 8px;
                }
            }
        }
    }

    // Fin apartado tipografía

    // Apartado idiomas
    .preferencias {
        .preferencias-items{
            margin-top: 22px;
            .pref-row {
                align-items: center;
                display: flex;
                margin-bottom: 4px;
                .fav {
                    align-items: center;
                    background-color: var(--colorPrincipal);
                    border-radius: 8px;
                    cursor: pointer;
                    display: flex;
                    height: 40px;
                    justify-content: center;
                    width: 40px;
                    svg {
                        stroke: $gris_oscuro;
                        stroke-width: 2px;
                        fill: transparent;
                        width: 15px;
                        height: 14px;
                    }
                    &.active {
                        svg {
                            fill: rgb(255,216,0);
                            stroke: rgb(255,216,0);
                            stroke-width: 1px;
                        }
                    }
                }
                .name {
                    align-items: center;
                    background-color: $gestion_bg_menu;
                    border-radius: 8px;
                    display: flex;
                    font-family: $archivo_medium;
                    font-size: 16px;
                    height: 40px;
                    margin-left: 3px;
                    padding: 0px 10px 0px 22px;
                    width: 184px;
                }
                .delete {
                    align-items: center;
                    background-color: $gestion_bg_menu;
                    border-radius: 8px;
                    cursor: pointer;
                    display: flex;
                    height: 40px;
                    justify-content: center;
                    margin-left: 8px;
                    width: 40px;
                    &.no-delete {
                        cursor: default;
                        opacity: 0.5;
                    }
                }
                .ylm-select {
                    margin-left: 6px;
                    .ylm-select-header {
                        border-radius: 8px;
                        height: 40px;
                        padding: 0px 16px 0px 22px;
                        width: 184px;
                        background-color: $select_borrador;
                        &.no-click {
                            cursor: default;
                            opacity: 0.5;
                        }
                    }
                    .ylm-select-options {
                        background-color: $gestion_bg_menu;
                        border-radius: 8px;
                        top: 44px;
                        .select-option {
                            align-items: center;
                            display: flex;
                            height: 40px;
                            padding-left: 22px;
                            &:last-of-type {
                                border-bottom: none;
                            }
                        }
                        &.active {
                            height: auto;
                        }
                    }
                    &.published {
                        .ylm-select-header {
                            background-color: $select_publicado;
                        }
                    }
                }
                .btn-add-lang {
                    align-items: center;
                    background-color: $gestion_bg_menu;
                    border-radius: 8px;
                    color: var(--colorPrincipal);
                    cursor: pointer;
                    display: flex;
                    font-family: $archivo_medium;
                    font-size: 16px;
                    height: 40px;
                    justify-content: center;
                    margin-left: 43px;
                    margin-top: 8px;
                    text-decoration: underline;
                    width: 184px;
                }
            }
        }
    }
    // Fin apartado idiomas

    // Apartado idiomas - visualización
    .visualizacion {
        .visualizacion-items {
            margin-top: 21px;
            & > div {
                margin-bottom: 14px;
                &:last-of-type {
                    margin-bottom: 0px;
                }
            }
            .ylm-radio {
                .select-nav-default {
                    margin-left: 15px;
                    visibility: hidden;
                    p {
                        margin: 0px;
                        font-family: $archivo_regular;
                    }
                    .ylm-select-header {
                        background-color: $gestion_bg_menu;
                        border-radius: 8px;
                        min-width: 184px;
                        height: 40px;
                        padding: 0px 16px 0px 22px;
                    }
                    .ylm-select-options {
                        background-color: $gestion_bg_menu;
                        border-radius: 8px;
                        top: 44px;
                        .select-option {
                            align-items: center;
                            display: flex;
                            height: 40px;
                            padding-left: 22px;
                            &:last-of-type {
                                border-bottom: none;
                            }
                        }
                        &.active {
                            height: auto;
                        }
                    }
                }
                &.active {
                    .select-nav-default {
                        visibility: visible;
                    }
                }
            }
        }
    }
    // Fin apartado idiomas - visualización

    // Apartado Ayuda - contacto
    .contacto {
        .contacto-items {
            margin-top: 20px;
            .contacto-item {
                display: flex;
                align-items: center;
                height: 40px;
                .icon {
                    align-items: center;
                    display: flex;
                    height: 100%;
                    width: 25px;
                }
                input {
                    border: none;
                    margin-left: 15px;
                    height: 100%;
                    &::placeholder {
                        color: $black;
                        font-family: $archivo_medium;
                        font-size: 16px;
                    }
                }
            }
        }
    }
    // Fin apartado Ayuda - contacto

    // Apartado Ayuda - cancelaciones
    
    .cancelaciones {
        .cancelaciones-items {
            margin-top: 15px;
            width: 366px;
            .cancelacion-input {
                position: relative;
                background-color: $gestion_bg_menu;
                border-radius: 8px;
                display: flex;
                align-items: center;
                width: 100%;
                height: 40px;
                padding: 10px 10px 10px 13px;
                input, textarea {
                    font-family: $archivo_regular;
                    font-size: 9px;
                    width: 100%;
                    background: none;
                    border: none;
                    &::placeholder {
                        color: $modal_texto;
                    }
                }
                &.icon {
                    input {
                        width: calc( 100% - 80px);
                    }
                    .btn-upload-icon {
                        background: none;
                        background-color: var(--colorPrincipal);
                        border: none;
                        border-radius: 8px;
                        color: $white;
                        font-family: $archivo_bold;
                        font-size: 11px;
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        display: block;
                        width: 60px;
                        height: 100%;
                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }
                & > .icon {
                    width: 26px;
                }
                &.title {
                    height: 35px;
                }
                &.description {
                    height: 71px;
                    align-items: flex-start;
                    textarea {
                        height: 100%;
                    }
                }
            }
        }
    }

    // Fin apartado Ayuda - cancelaciones

    // Apartado Ayuda - FAQ'S
    .faqs {
        .faqs-items {
            margin-top: 29px;
            width: 100%;
            .faq-item {
                display: flex;
                flex-direction: column;
                width: 100%;
                & > * {
                    border: none;
                    border-radius: 8px;
                    font-family: $archivo_medium;
                    font-size: 16px;
                    line-height: 25px;
                    margin-bottom: 12px;
                    padding: 13px;
                }
                input {
                    background-color: $gestion_bg_menu;
                    font-family: $archivo_bold;
                    height: 40px;
                }
                textarea {
                    background-color: $azul2;
                    min-height: 144px;
                }
                .btn-add {
                    align-items: center;
                    display: flex;
                    height: 40px;
                    justify-content: center;
                    margin-left: 0px;
                    text-decoration: underline;
                    width: 262px;
                }
            }
        }
    }
    // Fin apartado Ayuda - FAQ'S

    // Apartado Legal - Aviso legal
    .legal-text-column {
        display: flex; 
        flex-direction: row; /* Alinea los elementos en columna */
    }
    .sections.legal {
        width: 295px;
        .section {
            margin-top: 30px;
            .section-items {
                margin-top: 16px;
            }
        }
        .input-container {
            .legal-input {
                position: relative;
                background-color: $gestion_bg_menu;
                border-radius: 8px;
                display: flex;
                align-items: center;
                width: 100%;
                height: 40px;
                padding: 10px 10px 10px 13px;
                input, textarea {
                    font-family: $archivo_regular;
                    font-size: 9px;
                    width: 100%;
                    background: none;
                    border: none;
                    &::placeholder {
                        color: $modal_texto;
                    }
                }
                &.icon {
                    input {
                        width: calc( 100% - 80px);
                    }
                    .btn-upload-icon {
                        background: none;
                        background-color: var(--colorPrincipal);
                        border: none;
                        border-radius: 8px;
                        color: $white;
                        font-family: $archivo_bold;
                        font-size: 11px;
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        display: block;
                        width: 60px;
                        height: 100%;
                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }
                & > .icon {
                    width: 26px;
                }
                &.title {
                    height: 35px;
                }
                &.description {
                    height: 71px;
                    align-items: flex-start;
                    textarea {
                        height: 100%;
                    }
                }
            }
        }
    }
    .sections.text {
        border-color: black;
        margin-left: 20px;
        width: 500px;
        .textarea {
            margin-top: 15px;
        }
    }
    // Fin apartado Legal - Aviso legal

    .btn {
        width: 120px;
        height: 36px;
        border: none;
        background-color: $gris_boton_bg;
        color: var(--colorPrincipal);
        font-family: $archivo_bold;
        font-size: 16px;
        margin-left: 5px;
        &.btn-primary {
            margin-left: 0px;
            background-color: var(--colorPrincipal);
            color: $gris_boton_bg;
        }
        &:hover {
            opacity: 0.8;
        }
    }
}

// Estilos específicos para Legal
.configuracion-wrapper {
  .customize-body {
    .left {
      &.legal-tabs {
        width: 100%;
        max-width: 1200px;
        
        .tabs {
          display: flex;
          gap: 32px;
          padding: 0;
          margin-bottom: 24px;
          border-bottom: 1px solid #E5E7EB;
          width: 100%;

          .tab {
            padding: 8px 16px;
            cursor: pointer;
            font-size: 14px;
            border-bottom: 2px solid transparent;
            white-space: nowrap;
            width: 100%;
            &.active {
              border-bottom: 2px solid currentColor;
            }
          }
        }

        .panel-body {
          width: 100%;
        }
      }
    }
  }
}