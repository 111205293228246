.loading-ylm {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 100;
    display: none;
    justify-content: center;
    align-items: center;
    .loading-bg {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: $azul_fuerte_trans;
    }
    .loading-wrapper {
        position: relative;
        min-height: 100px;
        margin: 0px 15px;
        padding: 47px 30px;
        @include lg(){
            margin: 0px;
            padding: 47px 81px;
        }
        h3 {
            color: $white;
            font-size: 35px;
        }
    }
    &.active {
        display: flex;
    }
}