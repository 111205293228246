.admin-tab {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 82px);
    overflow: auto;
    padding: 27px;
    width: 100%;
    .admin-wrapper {
        width: 912px;
        .header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            h1 {
                font-family: $archivo_bold;
                font-size: 28px;
                color: var(--colorPrincipal);
            }
            .botonera {
                display: flex;
                transition: transform 0.3s;
                .slide-button {
                    overflow: hidden;
                    .slide-item {
                        position: relative;
                        .slide-data {
                            align-items: center;
                            background-color: $gris_boton_bg;
                            border-radius: 8px 0px 0px 8px;
                            cursor: pointer;
                            display: flex;
                            height: 36px;
                            padding: 0px 39px 0px 16px;
                            transform: translateX(100%);
                            transition: all 0.3s;
                            svg {
                                margin-left: 5px;
                                transform: scale(2);
                            }
                            p {
                                color: var(--colorPrincipal);
                                font-size: 16px;
                                margin-left: 23px;
                            }
                            &:hover {
                                opacity: 0.8;
                            }
                        }
                    }
                    &.show {
                        .slide-data {
                            transform: translateX(0%);
                        }
                    }
                }
                .btn {
                    margin-left: 0px;
                    &.active {
                        background-color: var(--colorPrincipal);
                        .reverse-icon svg path {
                            fill: $white;
                        }
                    }
                }
            }
            &.compress {
                .botonera {
                    transform: translateX(-135px);                
                }
            }
        }
        .section {
            margin-top: 35px;
            h3 {
                color: $azul_flojo;
                font-size: 16px;
                margin-bottom: 20px;
                margin-top: 27px;
            }
            h5 {
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                color: $gris_oscuro;
                font-size: 14px;
                margin-top: 12px;
            }
            &.datos {
                .datos-row {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 7px;
                    &.row-1 {
                        .datos-col {
                            background-color: $azul2;
                            padding: 26px 27px;
                        }
                    }
                    &.row-2 {
                        .datos-col {
                            background-color: $gris_boton_bg;
                            padding: 31px 27px;
                        }
                    }
                    .datos-col {
                        display: flex;
                        &.col-1 {
                            width: 541px;
                        }
                        &.col-2 {
                            width: calc( 100% - (541px + 6px))
                        }
                        &.col-3 {
                            .notificaciones {
                                flex-grow: 1;
                                width: 294px;
                                label {
                                    color: var(--colorPrincipal);
                                    font-family: $archivo_bold;
                                    font-size: 14px;
                                    margin-bottom: 8px;
                                }
                            }
                        }
                        &.col-4 {
                            width: 257px;
                            .permisos {
                                flex-grow: 1;
                                label {
                                    color: var(--colorPrincipal);
                                    font-family: $archivo_bold;
                                    font-size: 14px;
                                    margin-bottom: 8px;
                                }
                            }
                        }
                        &.col-5 {
                            width: 179px;
                            .sector {
                                flex-grow: 1;
                                label {
                                    color: var(--colorPrincipal);
                                    font-family: $archivo_bold;
                                    font-size: 14px;
                                    margin-bottom: 8px;
                                }
                            }
                        }
                        .data-item {
                            display: flex;
                            flex: 0;
                            flex-direction: column;
                            label {
                                color: $gris_oscuro;
                                display: inline-block;
                                font-size: 12px;
                                margin-bottom: 18px;
                                min-height: 15px;
                            }
                            input {
                                background-color: transparent;
                                border: none;
                                color: $gris_oscuro;
                                font-family: $archivo_bold;
                                font-size: 14px;
                                padding: 0px 10px 0px 0px;
                            }
                            &.icono {
                                justify-content: center;
                                margin-right: 23px;
                            }
                            &.nombre {
                                flex: 1 1 auto;
                            }
                            &.telefono {
                                width: 140px;
                            }
                            &.email {
                                width: 145px;
                            }
                            &.password {
                                width: 103px;
                            }
                        }
                        .datos-btn {
                            border: none;
                            background-color: transparent;
                            color: var(--colorPrincipal);
                            font-family: $archivo_bold;
                            font-size: 12px;
                            text-transform: uppercase;
                        }
                    }
                }
            }
            &.plan {
                .planes-row {
                    display: flex;
                    justify-content: space-between;
                    .plan-item {
                        background-color: var(--color6);
                        border-radius: 20px;
                        display: flex;
                        flex-direction: column;
                        height: 131px;
                        padding: 28px 30px 0px 30px;
                        position: relative;
                        width: 290px;
                        .title-row {
                            display: flex;
                            justify-content: space-between;
                            .title {
                                color: var(--colorPrincipal);
                                font-family: $archivo_bold;
                                font-size: 18px;
                            }
                            .more-btn {
                                background: none;
                                border: none;
                                color: var(--colorPrincipal);
                                font-size: 14px;
                                padding: 0px;
                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }
                        .price {
                            color: var(--colorPrincipal);
                            font-family: $archivo_bold;
                            font-size: 45px;
                            margin-top: 5px;
                            span {
                                font-size: 20px;
                            }
                        }
                        p {
                            font-family: $archivo_semibold;
                            margin-top: 10px;
                        }
                        .update-btn {
                            background: none;
                            border: 1px solid var(--colorPrincipal);
                            border-radius: 100px;
                            color: var(--colorPrincipal);
                            font-family: $archivo_semibold;
                            height: 23px;
                            margin-top: 10px;
                            width: 133px;
                        }
                        .recomendado {
                            background-color: var(--color5);
                            color: var(--white);
                            border-radius: 100px;
                            bottom: -13px;
                            font-family: $archivo_bold;
                            font-size: 16px;
                            padding: 6px 20px;
                            position: absolute;
                        }
                        &.active {
                            background-color: var(--colorPrincipal);
                            .title {
                                color: $white;
                            }
                            .price {
                                color: $white;
                            }
                            p {
                                font-family: $archivo_bold;
                                font-size: 15px;
                                color: var(--color3);
                                margin-top: 3px;
                            }
                        }
                    }
                }
            }
            &.pagos {
                .pagos-table {
                    .pagos-row {
                        align-items: center;
                        color: $gris_oscuro;
                        display: flex;
                        justify-content: flex-start;
                        border-bottom: 1px solid $calendar-pasado;
                        padding-top: 12px;
                        padding-bottom: 12px;
                        & > div {
                            padding-left: 15px;
                            flex: 0 0 auto;
                        }
                        &.headers {
                            font-family: $archivo_bold;
                            padding-bottom: 5px;
                            .factura {
                                padding-left: 0px;
                                cursor: default;
                            }
                        }
                        .plan { flex-basis: 218px; }
                        .facturado { flex-basis: 210px; }
                        .cuota { flex-basis: 167px; }
                        .pago { 
                            flex-basis: 200px;
                            font-family: $archivo_bold;
                            &.pendiente {
                                color: $table_red_text;
                            }
                        }
                        .factura { 
                            flex: 1 1 auto; 
                            padding-left: 20px;
                            cursor: pointer;
                            svg {
                                path {
                                    fill: $gris_oscuro;
                                }
                            }
                        }
                    }
                }
            }
        }

    }
    .btn {
        width: 120px;
        height: 36px;
        border: none;
        background-color: $gris_boton_bg;
        color: var(--colorPrincipal);
        font-family: $archivo_bold;
        font-size: 16px;
        margin-left: 5px;
        &.btn-primary {
            margin-left: 0px;
            background-color: var(--colorPrincipal);
            color: $gris_boton_bg;
        }
        &.btn-download {
            background-color: $azul2;
            height: 36px;
            width: 36px;
        }
        &:hover {
            opacity: 0.8;
        }
    }
}

.modal-planes {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 1100;
    display: none;
    justify-content: center;
    align-items: center;
    &.active {
        display: flex;
    }
    .modal-wrapper {
        border-radius: 30px;
        padding: 47px 20px 10px;
        width: 950px;
        .modal-col {
            flex: 0 0 24%;
            &:first-of-type {
                background-color: transparent;
                flex: 1;
            }
        }
        .modal-top {
            display: flex;
            gap: 8px;
            .plan-header {
                background-color: var(--color6);
                border-radius: 15px;
                color: var(--colorPrincipal);
                padding: 37px 15px 10px;
                position: relative;
                .recomendado {
                    border-radius: 100px;
                    background-color: var(--color5);
                    color: var(--white);
                    font-family: $archivo_semibold;
                    font-size: 16px;
                    text-align: center;
                    padding: 8px 20px;
                    position: absolute;
                    top: -17px;
                }
                h2 {
                    font-family: $archivo_semibold;
                    font-size: 18px;
                    font-weight: normal;
                }
                .plan-price {
                    font-family: $archivo_bold;
                    font-size: 40px;
                    margin-top: 8px;
                    span {
                        font-size: 15px;
                        margin-left: 3px;
                    }
                }
                .plan-excerpt {
                    font-size: 12px;
                    line-height: 18px;
                    margin-top: 10px;
                }
                .plan-active {
                    font-family: $archivo_semibold;
                    font-size: 18px;
                    font-weight: normal;
                    .active-plan {
                        margin-top: 20px;
                        padding: 12px 0px;
                    }
                    .active-btn {
                        border: 2px solid var(--colorPrincipal);
                        border-radius: 100px;
                        cursor: pointer;
                        margin-top: 20px;
                        padding: 8px 0px;
                        text-align: center;
                        width: 100%;
                    }
                }
            }
        }
        .modal-table {
            margin-top: -43px;
            .table-header {
                .header-text {
                    align-items: center;
                    background-color: var(--colorPrincipal);
                    border-radius: 25px 25px 0px 0px;
                    color: var(--white);
                    display: flex;
                    font-family: $archivo_bold;
                    font-size: 18px;
                    height: 54px;
                    padding-left: 35px;
                    width: 230px;
                    p {
                        margin-top: 5px;
                    }
                }
                .table-line {
                    background-color: var(--colorPrincipal);
                    height: 8px;
                    transform: translateY(-1px);
                    width: 100%;
                }
            }
            .table-body {
                margin-top: 5px;
                max-height: calc( 100vh - 440px );
                overflow: auto;
                .table-row {
                    align-items: center;
                    background-color: var(--color6);
                    border-radius: 100px;
                    color: var(--colorPrincipal);
                    display: flex;
                    font-family: $archivo_bold;
                    font-size: 18px;
                    margin-bottom: 5px;
                    padding: 10px 0px;
                    .modal-col {
                        text-align: center;
                        &:first-of-type {
                            padding-left: 30px;
                            text-align: left;
                        }
                        svg:not(.no-color-change) {
                            path {
                                fill: var(--colorPrincipal);
                            }
                        }
                    }
                }
            }
        }
        .modal-close {
            border: none;
            border-radius: 50%;
            background-color: var(--colorPrincipal);
            right: -17px;
            top: -17px;
        }
    }
}