:root {
    --admin-placeholder-color: #535AF1;
    --time-slider-color: red;
    --colorPrincipal: #535AF1;
    --colorSecundario: #D6E8FF;
    --color3: #FDFFAE;
    --color4: #D2FAFF;
    --color5: #F67F8C;
    --color6: #F8FBFF;
    --color7: #F4EFEF;

    --white: #ffffff;
    --black: #030303;
    --gris1: #707070;
    --gris2: #E3D0D0;
    --gris3: #F7F7F7;
    --gris4: #C9C5C5;
    --gris5: #7B7B7B;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

:focus {
    outline: unset !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 50px white inset;
}


html, body {
    height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
    color: $black;
    font-size: 14px;
}

body {
    overscroll-behavior-y: contain;
    user-select: none;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

::placeholder {
    color: white;
}

h1,h2,h3,h4,h5,h6,p,ul,li,label {
    margin: 0;
    padding: 0;
}

button {
    cursor: pointer;
    &:focus {
        outline: none;
    }
}

/* Customize the label (the container) */
.container {
    display: flex;
    align-items: center;
    height: 18px;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #eee;
    border: 1px solid $dark-grey;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
    
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid $light-grey;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.ylm-select {
    position: relative;
    .ylm-select-header {
        cursor: pointer;
        span {
            margin-left: 10px;
        }
    }
    .ylm-select-options {
        background-color: $white;
        border: 1px solid $gris4;
        display: flex;
        flex-direction: column;
        height: 0px;
        overflow: hidden;
        padding: 0px 8px 0px 19px;
        position: absolute;
        top: 20px;
        transition: height 0.3s;
        width: 100%;
        z-index: 3;
        &.active {
            overflow: auto;
            max-height: 115px;
            height: auto;
        }
        .select-option {
            padding: 10px 0px;
            border-bottom: 1px solid $text-grey;
            cursor: pointer;
            &.check-option {
                align-items: center;
                color: var(--colorPrincipal);
                display: flex;
                span {
                    border: 1px solid var(--colorPrincipal);
                    display: inline-block;
                    height: 16px;
                    margin-right: 10px;
                    position: relative;
                    width: 16px;
                }
                &.active {
                    span {
                        &::after {
                            border-bottom: 2px solid var(--colorPrincipal);
                            border-left: 2px solid var(--colorPrincipal);
                            content: '';
                            height: 3px;
                            left: 3px;
                            position: absolute;
                            top: 4px;
                            width: 6px;
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
            &:last-of-type {
                border-bottom: none;
            }
        }
    }
}

.ylm-toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    .toggle-bg {
        background-color: $gris3;
        border-radius: 100px;
        height: 11px;
        position: relative;
        width: 34px;
        .toggle-item {
            background-color: $gris_medio;
            border-radius: 50%;
            content: '';
            height: 17px;
            left: 0px;
            position: absolute;
            top: -3px;
            transition: all 0.2s;
            width: 17px;
        }         
    }
    p {
        color: $black;
        font-size: 16px;
        margin-left: 16px;
    }
    &.active {
        .toggle-bg {
            background-color: $azul_flojo;
            .toggle-item {
                background-color: var(--colorPrincipal);
                left: calc(100% - 17px);
            }
        }
    }
}

.ylm-checkbox {
    align-items: center;
    display: flex;
    cursor: pointer;
    .checkbox-item {
        border: 1px solid $gris_oscuro;
        height: 17px;
        position: relative;
        width: 17px;
    }
    p {
        font-size: 16px;
        margin-left: 13px;
    }
    &.active {
        .checkbox-item {
            &::after {
                border-left: 2px solid $gris_oscuro;
                border-bottom: 2px solid $gris_oscuro;
                content: '';
                height: 4px;
                left: 2px;
                position: absolute;
                top: 3px;
                transform: rotate(315deg);
                width: 9px;
            }
        }
        p {
            font-family: $archivo_semibold;
        }
    }
}

.ylm-radio {
    align-items: center;
    display: flex;
    cursor: pointer;
    .radio-bg {
        background-color: $gris_boton_bg;
        border-radius: 50%;
        height: 17px;
        position: relative;
        width: 17px;
    }
    p {
        font-size: 16px;
        margin-left: 13px;
    }
    &.active {
        .radio-bg {
            .radio-item {
                background-color: var(--colorPrincipal);
                border-radius: 50%;
                content: '';
                height: 9px;
                left: 4px;
                position: absolute;
                top: 4px;
                width: 9px;
            }
        }
        p {
            font-family: $archivo_semibold;
        }
    }
}

.reverse-icon {
    display: inline-block;
    transform: rotate(180deg);
}


.hora-input {
    position: relative;
    .h-input {
        align-items: center;
        display: flex;
        font-family: $archivo_bold;
        font-size: 14px;
        height: 30px;
        justify-content: flex-start;
        width: 70px;
        &.active {
            border: 2px solid var(--colorPrincipal);
        }
    }
    .selector-wrapper {
        background-color: $white;
        border: 2px solid var(--colorPrincipal);
        box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.15);
        display: none;
        position: absolute;
        top: 32px;
        .selectors {
            align-items: center;
            color: var(--colorPrincipal);
            display: flex;
            .selector {
                padding: 10px 0px;
                width: 50px;
                .selector-value {
                    font-family: $archivo_bold;
                    font-size: 20px;
                    padding: 10px;
                    text-align: center;
                    width: 100%;
                }
                .btn {
                    display: flex;
                    font-size: 18px;
                    justify-content: center;
                    &.btn-add {
                        transform: rotate(180deg);
                    }
                }
            }
            .separator {
                font-family: $archivo_bold;
                font-size: 20px;
                position: relative;
                top: -1px;
            }
        }
        & > button {
            background-color: var(--colorPrincipal);
            border: none;
            color: $white;
            font-family: $archivo_medium;
            font-size: 14px;
            margin-top: 10px;
            padding: 5px 0px;
            width: 100%;
        }
        &.active {
            display: block;
            z-index: 5;
        }
    }
}

.doble-hora-input {
    position: relative;
    .h-input {
        align-items: center;
        display: flex;
        font-family: $archivo_bold;
        font-size: 14px;
        height: 30px;
        justify-content: flex-start;
        width: 70px;
        &.active {
            border: 2px solid var(--colorPrincipal);
        }
    }
    .selector-wrapper {
        background-color: $white;
        box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.15);
        display: none;
        position: absolute;
        top: 32px;
        z-index: 3;
        .selectors {
            align-items: center;
            border-top: 2px solid var(--colorPrincipal);
            border-bottom: 2px solid var(--colorPrincipal);
            border-left: 2px solid var(--colorPrincipal);
            color: var(--colorPrincipal);
            display: flex;
            &:last-of-type {
                border-right: 2px solid var(--colorPrincipal);
            }
            .selector {
                padding: 10px 0px;
                width: 50px;
                .selector-value {
                    font-family: $archivo_bold;
                    font-size: 20px;
                    padding: 10px;
                    text-align: center;
                    width: 100%;
                }
                .btn {
                    display: flex;
                    font-size: 18px;
                    justify-content: center;
                    &.btn-add {
                        transform: rotate(180deg);
                    }
                }
            }
            .separator {
                font-family: $archivo_bold;
                font-size: 20px;
                position: relative;
                top: -1px;
            }
        }
        & > button {
            background-color: var(--colorPrincipal);
            border: none;
            color: $white;
            font-family: $archivo_medium;
            font-size: 14px;
            margin-top: 10px;
            padding: 5px 0px;
            width: 100%;
        }
        &.active {
            display: flex;
            z-index: 5;
        }
    }
}

.std-ul {
    background-color: var(--color6);
    border: 1px solid var(--colorPrincipal);
    border-radius: 10px;
    list-style: none;
    max-height: 190px;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    top: calc( 100% + 5px);
    right: 1px;
    width: 166px;
    z-index: 100;
    &.big {
        width: 318px;
    }
    &.top {
        bottom: calc( 100% + 5px);
        top: auto;
    }
    &.left {
        left: 0px;
        right: auto;
    }
}

.std-li {
    align-items: center;
    border-bottom: 1px solid var(--colorPrincipal);
    color: var(--colorPrincipal);
    cursor: pointer;
    display: flex;
    height: 47px;
    padding: 10px 10px 10px 29px;
    width: 100%;
    &:last-of-type {
        border-bottom: none;
    }
    .checkbox-bg {
        border: 1px solid var(--colorPrincipal);
        height: 17px;
        margin-right: 10px;
        position: relative;
        width: 17px;
    }
    &.active {
        .checkbox-item {
            border-bottom: 2px solid var(--colorPrincipal);
            border-left: 2px solid var(--colorPrincipal);
            height: 5px;
            left: 4px;
            position: absolute;
            top: 4px;
            transform: rotate(-45deg);
            width: 8px;
        }
    }
}