.sectores-list {
    display: flex;
    gap: 6px;
    margin-top: 31px;
    .sector-item {
        align-items: center;
        background-color: var(--colorSecundario);
        color: var(--colorPrincipal);
        cursor: pointer;
        display: flex;
        font-family: $archivo_bold;
        font-size: 20px;
        height: 120px;
        justify-content: center;
        width: 175px;
    }
}