.mi-cuenta {
    padding-top: 113px;
    background-color: $bg-beige;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include lg(){
        padding-top: 147px;
        flex-direction: row;
    }
    @include xxl(){
        padding-top: 217px;
    }
    .left {
        padding-left: 0px;
        width: calcula(375, 330);
        margin: 39px auto 0;
        overflow: hidden;
        @include lg(){
            flex-shrink: 0;
            padding-left: 30px;
            width: 230px;
            margin: 0px;
        }
        @include xl(){
            width: 346px;
        }
        @include xxl(){
            padding-left: 120px;
            width: 466px;
        }
        h4 {
            color: $gold;
            font-family: $archivo_semibold;
            font-size: 17px;
            text-transform: uppercase;
            letter-spacing: 5px;
            padding-top: 33px;
            margin-left: 5px;
            @include lg(){
                font-size: 19px;
            }
        }
        .datos-container {
            padding-top: 43px;
            @include lg(){
                padding-top: 56px;
                padding-right: 20px;
            }
            h5 {
                font-family: $archivo_bold;
                font-size: 21px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-left: 5px;
                @include lg(){
                    justify-content: flex-start;
                    text-decoration: underline;
                }
                span {
                    cursor: pointer;
                    display: inline-block;
                    width: 35px;
                    height: 34px;
                    background-image: url('../../assets/img/sprite.png');                    
                    background-position: -202px -256px;
                    margin-left: 10px;
                    @include lg(){
                        width: 25px;
                        height: 26px;
                        background-position: -9px -262px;
                    }
                    &:hover {
                        background-position: -240px -256px;
                        @include lg(){
                            background-position: -38px -262px;
                        }
                    }
                }
            }
            .form-container {
                padding-top: 16px;
                @include lg(){
                    padding-top: 22px;
                }
                .form-item {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    padding: 16px 0px;
                    @include lg(){
                        padding: 9px 0px;
                    }
                    label {
                        font-family: $archivo_regular;
                        font-size: 16px;
                        margin-left: 5px;
                        padding-bottom: 2px;
                        @include lg(){
                            font-size: 12px;
                        }
                    }
                    input {
                        font-family: $archivo_semibold;
                        font-size: 20px;
                        background-color: #f2f2f2;
                        border-color: transparent;
                        padding: 5px;
                        border-radius: 7px;
                        @include lg(){
                            font-size: 16px;
                        }
                        @include xl(){
                            width: 250px;
                        }
                        &::placeholder {
                            color: $font;
                        }
                        &[disabled] {
                            background: none;
                        }
                    }
                    .errores {
                        display: none;
                        font-family: $archivo_bold;
                        position: absolute;
                        bottom: -5px;
                        left: 5px;
                        color: red;
                        p {
                            font-size: 11px;
                        }
                        &.error {
                            display: block;
                        }
                    }
                    .btn-accept {
                        display: none;
                        margin-top: 9px;
                        cursor: pointer;
                        justify-content: center;
                        align-items: center;
                        font-family: $archivo_bold;
                        font-size: 21px;
                        width: 100%;
                        height: 51px;
                        border: none;
                        background-color: $gold;
                        color: $white;
                        @include lg(){
                            font-size: 18px;
                            width: 180px;
                        }
                        @include xl() {
                            width: 202px;
                        }
                        &.active {
                            display: flex;
                        }
                    }
                }
            }
        }
        
    }
    .right {
        padding-top: 16px;
        width: calcula(375, 337);
        margin: 39px auto 0;
        @include lg(){
            padding-top: 73px;
            width: 100%;
            padding-right: 30px;
        }
        @include xl(){
            padding-right: 108px;
        }
        h5 {
            font-family: $archivo_bold;
            font-size: 21px;
            display: flex;
            align-items: center;
            padding-left: 10px;
            @include lg(){
                padding-left: 0px;
                text-decoration: underline;
            }
        }
        .column {
            overflow: hidden;
            span { 
                display: inline-block;
                font-family: $archivo_bold !important;
                padding-bottom: 9px;
                @include lg(){
                    display: none;
                }
            }
        }
        .diahora {   
            position: relative;
            top: -6px;
            font-family: $archivo_bold !important;
            font-size: 23px !important;
            width: calcula(375, 213);
            max-width: calcula(375, 213);
            order: 1;
            @include lg(){
                font-size: 16px;
                padding-left: 10px;
                width: 152px;
                max-width: 152px;
                font-size: 16px !important;
                top: 0px;
            }
            @include xl(){
                padding-left: 28px;
            }
            span {
                font-size: 16px !important;
            }
        }
        .servicio {
            padding-top: 21px;
            order: 3;
            width: calcula(375, 213);
            max-width: calcula(375, 213);
            @include lg(){
                padding-top: 0px;
                width: 206px;
                max-width: 206px;
                order: 2;
            }
        }
        .precio {
            width: 85px;
            max-width: 85px;
            order: 2;
            @include lg(){
                order: 3;
            }
        }
        .ubicacion {
            flex-shrink: 0;
            width: calcula(375, 181);
            order: 5;
            padding-top: 36px;
            @include lg(){
                padding-top: 0px;
                width: 130px;
                order: 4;
            }
        }
        .profesional {
            width: calcula(375, 90);
            max-width: calcula(375, 90);
            order: 4;
            padding-top: 21px;
            @include lg(){
                padding-top: 0px;
                width: 106px;
                max-width: 106px;
                order: 5;
            }
        }
        .botones {
            padding-top: 36px;
            order: 6;
            @include lg(){
                padding-top: 0px;
                padding-right: 20px;
            }
        }
        .reservas-container {
            max-width: 762px;
            padding-top: 25px;
            padding-bottom: 60px;
            @include lg(){
                padding-top: 0px;
            }
            .reservas-header {
                display: none;
                font-family: $archivo_bold;
                font-size: 17px;
                padding-top: 38px;
                @include lg(){
                    display: flex;
                }
            }
            .reservas-rows {
                padding-top: 5px;
                @include lg(){
                    height: 322px;
                }
                .reservas-row {
                    display: flex;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    background-color: $white;
                    border: 2px solid $font;
                    padding: 17px 15px 21px;
                    margin-bottom: 21px;
                    cursor: default;
                    @include lg(){
                        padding: 26px 0px 28px;
                        flex-wrap: nowrap;
                        margin-bottom: 10px;
                    }
                    .column {
                        font-family: $archivo_regular;
                        font-size: 16px;
                        .editar {
                            display: inline-block;
                            width: 31px;
                            height: 31px;
                            background-image: url('../../assets/img/sprite.png');
                            background-position: -203px -256px;
                            @include lg(){
                                width: 24px;
                                height: 23px;
                                background-position: -9px -263px;
                            }
                            cursor: pointer;
                            &:hover {
                                background-position: -241px -256px;
                                @include lg(){
                                    background-position: -38px -263px;
                                } 
                            }
                        }
                        .borrar {
                            display: inline-block;
                            width: 30px;
                            height: 31px;
                            background-image: url('../../assets/img/sprite.png');
                            background-position: -288px -256px;
                            margin-left: 36px;
                            @include lg(){
                                width: 23px;
                                height: 22px;
                                background-position: -71px -264px;
                                margin-left: 10px;
                            }
                            cursor: pointer;
                            &:hover {
                                background-position: -329px -256px;
                                @include lg(){
                                    background-position: -99px -264px;
                                } 
                            }
                        }
                    }
                    &.old {
                        color: $gold;
                        border-color: transparent;
                        background-color: rgba(169,142,101,0.2);
                        .editar {
                            background-position: -241px -256px;
                            @include lg(){
                                background-position: -38px -263px;
                            }
                            &:hover {
                                background-position: -203px -256px;
                                @include lg(){
                                    background-position: -9px -263px;
                                } 
                            }
                        }
                        .borrar {
                            background-position: -329px -256px;
                            @include lg(){
                                background-position: -99px -264px;
                            }
                            &:hover {
                                background-position: -329px -256px;
                                @include lg(){
                                    background-position: -71px -264px;
                                }
                            }
                        }
                    }
                }
            }
            .paginador {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-family: $archivo_regular;
                font-size: 20px;
                padding-top: 20px;
                @include lg(){
                    padding-top: 10px;
                    font-size: 16px;
                    justify-content: flex-end;
                }
                .paginas {
                    display: flex;
                    width: 100%;
                    justify-content: space-around;
                    margin: 0px 6px;
                    @include lg(){
                        display: block;
                        width: auto;
                    }
                    .pagina {
                        cursor: pointer;
                        margin: 0px 8px;
                        &:hover {
                            color: $gold;
                        }
                        &.active {
                            font-family: $archivo_bold;
                        }
                    }
                }
                .arrow {
                    display: inline-block;
                    width: 15px;
                    height: 20px;
                    background-image: url('../../assets/img/sprite.png');
                    background-position: -376px -266px;
                    cursor: pointer;
                    @include lg(){
                        width: 9px;
                        height: 14px;
                        background-position: -136px -269px;
                    }
                    &.arrow-left {
                        &:hover {
                            background-position: -394px -266px;
                            @include lg(){
                                background-position: -148px -269px;
                            }
                        }
                    }
                    &.arrow-right {
                        background-position: -416px -267px;
                            @include lg(){
                                background-position: -163px -269px;
                            }
                        &:hover {
                            background-position: -434px -267px;
                            @include lg(){
                                background-position: -175px -269px;
                            }
                        }
                    }
                }
            }
        }
    }
    .modal-gestion {
        top: 0px;
        height: 100vh;
    }
    .fondo-no-click {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100vh;
        background-color: rgba(52, 52, 52, 0.8);
        z-index: 999;
        transform: translate(-100%, 0px);
        transition: all 0.1s;
        opacity: 0;
        &.active {
            opacity: 1;
            transform: translate( 0px, 0px);
        }
    }
}