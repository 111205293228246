.punto-venta {
    flex-shrink: 0;
    height: calc(100vh - 85px);
    width: calc(100% - 66px);
    padding-left: 33px;
    padding-right: 33px;
    .preheader {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 28px 0px 20px;
        .title {
            display: flex;
            align-items: center;
            span {
                display: inline-block;
                width: 25px;
                height: 25px;
                background-image: url('../../assets/img/sprite.png');
                background-position: -147px -380px;
                margin-right: 10px;
            }
            h1 {
                font-family: $archivo_bold;
                font-size: 28px;
                color: var(--colorPrincipal);
            }
        }
        .tabs {
            align-items: center;
            display: flex;
            margin-left: 25px;
            margin-top: 6px;
            .tab {
                align-items: center;
                color: $azul3;
                cursor: pointer;
                display: flex;
                font-family: $archivo_medium;
                justify-content: center;
                height: 35px;
                transition: width 0.3s;
                width: 133px;
                &.active {
                    background-color: $azul3;
                    color: var(--colorPrincipal);
                    font-family: $archivo_bold;
                }
            }
        }
        .botonera {
            display: flex;
            justify-content: flex-end;
            flex: 1;
            position: relative;
            transition: transform 0.3s;
            & > div {
                margin-left: 8px;
            }
            .btn-add {
                width: 35px;
                height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $azul2;
                font-family: $archivo_bold;
                font-size: 21px;
                cursor: pointer;
            }
            
            .btn-upload {
                width: 35px;
                height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $azul2;
                font-family: $archivo_bold;
                font-size: 21px;
                cursor: pointer;
            }

            .btn-search {
                align-items: center;
                background-color: $azul2;
                cursor: pointer;
                display: flex;
                font-family: $archivo_bold;
                font-size: 21px;
                height: 36px;
                justify-content: center;
                right: 86px;
                position: absolute;
                width: 35px;
                svg {
                    g {
                        fill: transparent;
                    }
                    line {
                        stroke-width: 2px;
                    }
                }
                .search-input {
                    display: none;
                    position: relative;
                    input {
                        background: none;
                        border: none;
                        height: 36px;
                        width: 250px;
                        padding-left: 8px;
                        &::placeholder {
                            color: $black;
                        }
                    }
                    span {
                        font-family: $archivo_semibold;
                        font-size: 12px;
                        position: absolute;
                        right: 15px;
                        top: 10px;
                    }
                }
                &.active {
                    display: flex;
                    justify-content: flex-end;
                    width: auto;
                    padding-right: 8px;
                    .search-input {
                        display: flex;
                    }
                }
            }
        }
        &.compress {
            .tabs {  
                transform: translateX(-81px);  
                .tab {
                    width: 95px;
                }
            }
            .botonera {
                max-width: 125px;
                transform: translateX(-135px);                
            }
        }
    }
}