.brb-slider {
    width: 100%;
    height: 100vh;

    .slick-slider {
        width: 100%;
        height: 100%;
        overflow: hidden;
        .slick-list {
            width: 100%;
            height: 100%;
            .slick-track {
                width: 100%;
                height: 100%;
                .slick-slide {
                    div {
                        width: 100%;
                        height: 100%;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    .jaume {
                        background-size: cover;
                        background-position: center;
                    }
                }
            }
            
        }
    }
}