.profesionales {
    flex-shrink: 0;
    width: calc(100% - 66px);
    padding-left: 33px;
    padding-right: 33px;
    .preheader {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 28px;
        .title {
            display: flex;
            align-items: center;
            span {
                display: inline-block;
                width: 25px;
                height: 25px;
                background-image: url('../../assets/img/sprite.png');
                background-position: -147px -380px;
                margin-right: 10px;
            }
            h1 {
                font-family: $archivo_bold;
                font-size: 28px;
                color: var(--colorPrincipal);
            }
        }
        .botonera {
            display: flex;
            justify-content: flex-end;
            flex: 1;
            transition: transform 0.3s;
            & > div {
                margin-left: 8px;
            }
            .btn-add {
                width: 35px;
                height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $azul2;
                font-family: $archivo_bold;
                font-size: 21px;
                cursor: pointer;
            }

            .btn-search {
                align-items: center;
                background-color: $azul2;
                cursor: pointer;
                display: flex;
                font-family: $archivo_bold;
                font-size: 21px;
                height: 36px;
                justify-content: center;
                position: relative;
                width: 35px;
                svg {
                    g {
                        fill: transparent;
                    }
                    line {
                        stroke-width: 2px;
                    }
                }
                .search-input {
                    display: none;
                    position: relative;
                    input {
                        background: none;
                        border: none;
                        height: 100%;
                        width: 250px;
                        padding-left: 8px;
                        &::placeholder {
                            color: $black;
                        }
                    }
                    span {
                        font-family: $archivo_semibold;
                        font-size: 12px;
                        position: absolute;
                        right: 15px;
                        top: 0px;
                    }
                }
                &.active {
                    display: flex;
                    justify-content: flex-end;
                    width: auto;
                    padding-right: 8px;
                    .search-input {
                        display: flex;
                    }
                }
            }
        }
        &.compress {
            .botonera {
                transform: translateX(-135px);
                
            }
        }
    }
    .table-profesionales {
        margin-top: 43px;
        width: 100%;
        max-height: calc(100vh - 200px);
        overflow: auto;
        .row-profesionales {
            align-items: center;
            background-color: #FAFAFA;
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            height: 85px;
            position: relative;
            margin-bottom: 5px;
            width: 100%;
            .color {
                flex-shrink: 0;
                width: 9px;
                height: 100%;
            }
            .avatar {
                background-size: cover;
                background-position: center;
                border-radius: 50%;
                flex-shrink: 0;
                width: 54px;
                height: 54px;
                margin-left: 21px;
                img {
                    width: 100%;
                }
            }
            .nombre {
                margin-left: 29px;
                width: 100%;
                font-family: $archivo_bold;
                font-size: 14px;
            }
            .stars {
                flex-shrink: 0;
                width: 92px;
                margin-left: 92px;
                .titulo {
                    font-family: $archivo_regular;
                    font-size: 8px;
                }
                .rating {
                    display: flex;
                    margin-top: 6px;
                    .rating-item {
                        margin-right: 3px;
                        svg {
                            fill: transparent;
                            width: 15px;
                            height: 14px;
                        }
                        &.active {
                            svg {
                                stroke: rgb(255,216,0);
                                fill: rgb(255,216,0);
                            }
                        }
                    }
                }
            }
            .botonera {
                flex-shrink: 0;
                margin-left: 86px;
                display: flex;
                .boton {
                    background-color: $azul3;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 50px;
                    height: 50px;
                    margin-right: 13px;
                    cursor: pointer;
                    &:last-of-type {
                        margin-right: 0px;
                    }
                    &:hover {
                        background-color: $azul_extraflojo;
                    }
                }
            }
            .btn-menu {
                flex-shrink: 0;
                margin-left: 58px;
                margin-bottom: 27px;
                cursor: pointer;
                & > span {
                    align-items: center;
                    display: inline-flex;
                    justify-content: center;
                    width: 35px;
                    height: 36px;
                }
                .submenu {
                    position: absolute;
                    display: none;
                    opacity: 0;
                    background-color: $white;
                    border: 1px solid $calendar-border-gray;
                    box-shadow: 0px 2px 10px #cccccc;
                    top: 10px;
                    right: 30px;
                    list-style: none;
                    width: 140px;
                    padding: 0px 20px;
                    transition: opacity 0.3s;
                    &.active {
                        display: block;
                        opacity: 1;
                    }
                    li {
                        display: flex;
                        align-items: center;
                        height: 40px;
                        border-bottom: 1px solid $calendar-border-gray;
                        cursor: pointer;
                        &:last-of-type {
                            border: none;
                        }
                        &:hover {
                            color: var(--colorPrincipal);
                        }
                    }
                }
                &.active {
                    .submenu {
                        display: block;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.profesional {
    width: 100%;
    .profesional-wrapper {
        width: 963px;
        .header {
            display: flex;
            justify-content: space-between;
            min-height: 36px;
            width: 100%;
            .nav-back {
                align-items: center;
                color: $azul3;
                cursor: pointer;
                display: flex;
                font-family: $archivo_bold;
                font-size: 20px;
                p {
                    margin-left: 9px;
                }
            }

            .botonera {
                align-items: center;
                display: flex;
                transition: transform 0.3s;
                .btn-mini {
                    background: none;
                    border: none;
                    height: 36px;
                    margin-left: 10px;
                    width: 36px;
                    &.btn-editar {
                        svg {
                            height: 16px;
                            width: 17px;
                        }
                    }
                    &.btn-eliminar {
                        svg {
                            height: 17px;
                            width: 17px;
                        }
                    }
                }
                .btn {
                    background: none;
                    background-color: $gestion_bg_menu;
                    border: none;
                    color: var(--colorPrincipal);
                    font-family: $archivo_bold;
                    font-size: 16px;
                    height: 36px;
                    margin-left: 10px;
                    width: 120px;
                    &.btn-crear {
                        background-color: var(--colorPrincipal);
                        color: $gestion_bg_menu;
                    }
                    &:hover {
                        opacity: 0.3;
                    }
                }
                .btn:active {
                    background-color: #EDD8BB; 
                }
            }
            &.compress {
                .botonera {
                    transform: translateX(-135px);
                    
                }
            }
        }
        .profesional-body {
            margin-top: 18px;
            h1 {
                font-family: $archivo_bold;
                font-size: 26px;
            }
            .prof-main {
                align-items: center;
                background-color: $azul2;
                color: $gris_oscuro;
                display: flex;
                height: 100px;
                justify-content: space-between;
                margin-top: 12px;
                position: relative;
                width: 100%;
                .main-left {
                    align-items: center;
                    display: flex;
                    .avatar {
                        background-size: cover;
                        background-position: center;
                        border-radius: 50%;
                        cursor: pointer;
                        height: 61px;
                        margin-left: 30px;
                        position: relative;
                        width: 58px;

                        svg {
                            left: 1px;
                            position: relative;
                            top: 2px;
                        }
                        .avatar-bg {
                            align-items: center;
                            background-color: rgba(82,79,79,0.7);
                            border-radius: 50%;
                            color: $white;
                            display: flex;
                            font-family: $archivo_bold;
                            font-size: 21px;
                            height: 100%;
                            justify-content: center;
                            left: 0px;
                            position: absolute;
                            top: 0px;
                            width: 100%;
                        }
                    }
                    .data {
                        margin-left: 22px;
                        max-width: 548px;
                        min-width: 300px;
                        position: relative;
                        .name {
                            align-items: center;
                            display: flex;
                            input {
                                background-color: transparent;
                                border: none;
                                font-family: $archivo_bold;
                                font-size: 16px;
                                min-width: 30px;
                                max-width: 515px;
                                padding: 5px 8px;
                                &::placeholder {
                                    color: $gris_oscuro;
                                }
                            }
                        }
                        .rate {
                            align-items: center;
                            display: flex;
                            margin-top: 10px;
                            .rating {
                                display: flex;
                                .rating-item {
                                    margin-right: 3px;
                                    svg {
                                        fill: transparent;
                                        stroke: $gris_oscuro;
                                        stroke-width: 1px;
                                    }
                                    &.active {
                                        svg {
                                            fill: $star_fill;
                                            stroke: $star_fill;
                                        }
                                    }
                                    &.clickable {
                                        cursor: pointer;
                                    }
                                }
                            }
                            .rates-number {
                                font-size: 8px;
                                margin-left: 10px;
                            }
                        }
                    }
                    .picker {
                        cursor: pointer;
                        display: flex;
                        margin-left: 10px;
                        position: relative;
                        width: 250px;
                        .swatch {
                            border-radius: 50%;
                            height: 10px;
                            position: relative;
                            width: 10px;
                        }
                        .popover {
                            position: absolute;
                            left: 20px;
                            top: 0px;
                            z-index: 3;
                        }
                    }
                }
                .col-2 {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;
                    width: 258px;
                    .col-item {
                        align-items: center;
                        background-color: $azul2;
                        display: flex;
                        font-family: $archivo_bold;
                        font-size: 16px;
                        height: 62px;
                        justify-content: space-between;
                        padding: 0px 14px 0px 26px;
                        width: 100%;
                        .ylm-toggle {
                            p {
                                display: none;
                            }
                        }
                    }
                    .delays {
                        align-items: center;
                        display: flex;
                        min-width: 230px;
                        .delay {
                            align-items: center;
                            cursor: pointer;
                            display: flex;
                            position: relative;
                            .delay-select {
                                align-items: center;
                                display: flex;
                                margin-left: 8px;
                                p {
                                    font-family: $archivo_bold;
                                    font-size: 14px;
                                    &.underline {
                                        text-decoration: underline;
                                    }
                                }
                                & > span {
                                    margin-left: 9px;
                                }
                                .delay-options {
                                    background-color: $white;
                                    border: 1px solid var(--colorPrincipal);
                                    border-radius: 10px;
                                    top: 25px;
                                    box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.15);
                                    color: $black;
                                    display: none;
                                    list-style: none;
                                    padding: 0px;
                                    position: absolute;
                                    width: 166px;
                                    z-index: 3;
                                    li {
                                        align-items: center;
                                        border-bottom: 1px solid var(--colorPrincipal);
                                        color: var(--colorPrincipal);
                                        cursor: pointer;
                                        display: flex;
                                        padding: 14px 16px;
                                        position: relative;
                                        .option-check {
                                            border: 1px solid var(--colorPrincipal);
                                            display: inline-block;
                                            height: 15px;
                                            position: relative;
                                            width: 15px;
                                            &.active {
                                                span {
                                                    border-bottom: 1px solid var(--colorPrincipal);
                                                    border-left: 1px solid var(--colorPrincipal);
                                                    display: block;
                                                    height: 5px;
                                                    left: 2px;
                                                    top: 3px;
                                                    transform: rotate(-45deg);
                                                    position: absolute;
                                                    width: 9px;
                                                }
                                            }
                                        }
                                        p {
                                            font-family: $archivo_regular;
                                            margin-left: 10px;
                                            position: relative;
                                            top: 1px;
                                        }
                                        &:last-of-type {
                                            border: none;
                                        }
                                        &:hover {
                                            opacity: 0.8;
                                        }
                                    }
                                    &.active {
                                        display: block;
                                    }
                                }
                            }
                        }
                        .delay-time {
                            align-items: center;
                            cursor: pointer;
                            display: flex;
                            margin-left: 10px;
                            position: relative;
                            .delay-time-select {
                                align-items: center;
                                display: flex;
                                margin-left: 8px;
                                p {
                                    font-family: $archivo_bold;
                                    font-size: 14px;
                                    &.underline {
                                        text-decoration: underline;
                                    }
                                }
                                & > span {
                                    margin-left: 9px;
                                }
                                .delay-time-options {
                                    background-color: $white;
                                    border: 1px solid var(--colorPrincipal);
                                    border-radius: 10px;
                                    top: 25px;
                                    box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.15);
                                    color: $black;
                                    display: none;
                                    list-style: none;
                                    padding: 0px;
                                    position: absolute;
                                    right: 0;
                                    width: 166px;
                                    z-index: 3;
                                    li {
                                        align-items: center;
                                        border-bottom: 1px solid var(--colorPrincipal);
                                        color: var(--colorPrincipal);
                                        cursor: pointer;
                                        display: flex;
                                        padding: 14px 16px;
                                        &:last-of-type {
                                            border: none;
                                        }
                                        &:hover {
                                            opacity: 0.8;
                                        }
                                        .option-check {
                                            border: 1px solid var(--colorPrincipal);
                                            display: inline-block;
                                            height: 15px;
                                            position: relative;
                                            width: 15px;
                                            &.active {
                                                span {
                                                    border-bottom: 1px solid var(--colorPrincipal);
                                                    border-left: 1px solid var(--colorPrincipal);
                                                    display: block;
                                                    height: 5px;
                                                    left: 2px;
                                                    top: 3px;
                                                    transform: rotate(-45deg);
                                                    position: absolute;
                                                    width: 9px;
                                                }
                                            }
                                        }
                                        p {
                                            font-family: $archivo_regular;
                                            margin-left: 10px;
                                            position: relative;
                                            top: 1px;
                                        }
                                    }
                                    &.active {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                }
                .ylm-toggle {
                    cursor: default;
                }
            }
            .prof-tabs {
                align-items: center;
                display: flex;
                justify-content: space-between;
                height: 40px;
                margin-top: 22px;
                .prof-tab {
                    align-items: center;
                    background-color: $azul2;
                    color: var(--colorPrincipal);
                    cursor: pointer;
                    display: flex;
                    font-size: 16px;
                    justify-content: center;
                    height: 100%;
                    width: calc( (100% - 15px) / 4 );
                    .tab-icon {
                        display: inline-flex;
                        font-family: $archivo_medium;
                    }
                    p {
                        margin-left: 13px;
                    }
                    &.active {
                        background-color: var(--colorPrincipal);
                        color: $white;
                        font-family: $archivo_bold;
                    }
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            .prof-secondary {
                height: calc( 100vh - 400px);
                overflow: auto;
                .horario {
                    .add-row {
                        align-items: center;
                        border-radius: 20px;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 10px;
                        padding: 0px 10px 0px 25px;
                        & > p {
                            color: var(--colorPrincipal);
                            font-family: $archivo_semibold;
                            font-size: 16px;
                        }
                        .add-sucursal {
                            align-items: center;
                            color: var(--colorPrincipal);
                            cursor: pointer;
                            display: flex;
                            font-family: $archivo_semibold;
                            font-size: 16px;
                            height: 38px;
                            position: relative;
                            .add-sucursal-options {
                                color: $gris_oscuro;
                                display: none;
                                font-family: $archivo_regular;
                                font-size: 14px;
                                list-style: none;
                                padding: 5px 8px 5px 19px;
                                position: absolute;
                                right: 0px;
                                top: 25px;
                                width: 200px;
                                /* .check-option {
                                    span {
                                        background-color: $azul_extraflojo;
                                        border: none;
                                        border-radius: 50%;
                                        &::after {
                                            background-color: var(--colorPrincipal);
                                            border: none;
                                            border-radius: 50%;
                                            height: 8px;
                                            left: 4px;
                                            top: 4px;
                                            width: 8px;
                                        }
                                    }
                                } */
                                .sin-sucursales {
                                    font-family: $archivo_bold;
                                    padding: 10px 5px;
                                }
                                &.active {
                                    display: block;
                                }
                            }
                        }
                    }
                    .horario-sucursales {
                        position: relative;
                        height: calc( 100vh - 448px );
                        overflow: auto;
                    }
                    .sucursal-item {
                        cursor: pointer;
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 3px;
                        margin-top: 10px;
                        .name {
                            align-items: center;
                            background-color: $azul2;
                            border-radius: 20px 20px 20px 20px;
                            color: var(--colorPrincipal);
                            display: flex;
                            font-family: $archivo_bold;
                            font-size: 16px;
                            height: 38px;
                            justify-content: space-between;
                            padding: 0px 10px 0px 25px;
                            width: 100%;
                        }
                        .horarios {
                            display: flex;
                            flex-wrap: wrap;
                            margin-top: 10px;
                            height: 0px;
                            transition: height .3s;
                            width: 100%;
                            .horarios-days-columns-row {
                                display: flex;
                                justify-content: space-between;
                                width: 100%;
                                .horario-day-column {
                                    display: flex;
                                    flex-direction: column;
                                    width: calc( (100% - 60px) / 7 );
                                    .horario-column-header {
                                        align-items: center;
                                        background-color: $gris_flojo;
                                        border-radius: 5px;
                                        color: $gris_medio;
                                        display: flex;
                                        font-size: 15px;
                                        height: 40px;
                                        justify-content: center;
                                        width: 100%;
                                    }
                                    .horario-item {
                                        align-items: center;
                                        border: 1px solid $gris_flojo;
                                        border-radius: 5px;
                                        color: $gris_medio;
                                        cursor: pointer;
                                        display: flex;
                                        justify-content: center;
                                        height: 40px;
                                        margin-top: 6px;
                                        position: relative;
                                        width: 100%;
                                        .horario-delete {
                                            cursor: pointer;
                                            position: absolute;
                                            right: -7px;
                                            top: -5px;
                                        }
                                        .doble-hora-input {
                                            height: 100%;
                                            width: 100%;
                                            .horario-label {
                                                align-items: center;
                                                display: flex;
                                                justify-content: center;
                                                height: 100%;
                                                width: 100%;
                                            }
                                            .selector-wrapper {
                                                top: 43px;
                                            }
                                        }
                                    }
                                }
                            }
                            .horario {
                                display: flex;
                                flex-direction: column;
                                width: 175px;
                                .dias {
                                    display: flex;
                                    font-family: $archivo_bold;
                                    font-size: 16px;
                                    /* height: 34px; */
                                    overflow: hidden;
                                    width: calc(100% - 10px);
                                    .text {
                                        display: flex;
                                        flex-wrap: wrap;
                                        .separador {
                                            margin: 0px 3px;
                                            &.coma {
                                                margin: 0px 3px 0px 0px;
                                            }
                                        }
                                    }
                                    .chevron {
                                        position: relative;
                                        margin-left: 5px;
                                        top: -2px;
                                    }
                                }
                                .horas {
                                    margin-top: 8px;
                                    margin-bottom: 18px;
                                    .hora {
                                        align-items: center;
                                        display: flex;
                                        font-family: $archivo_medium;
                                        font-size: 14px;
                                        margin-bottom: 2px;
                                        .hora-input {
                                            position: relative;
                                            .h-input {
                                                align-items: center;
                                                display: flex;
                                                font-family: $archivo_bold;
                                                font-size: 14px;
                                                height: 30px;
                                                justify-content: flex-start;
                                                width: 70px;
                                                &.active {
                                                    border: 2px solid var(--colorPrincipal);
                                                }
                                            }
                                            .selector-wrapper {
                                                background-color: $white;
                                                box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.15);
                                                display: none;
                                                padding: 10px;
                                                position: absolute;
                                                top: 32px;
                                                .selectors {
                                                    align-items: center;
                                                    display: flex;
                                                    .selector {
                                                        color: var(--colorPrincipal);
                                                        width: 50px;
                                                        .selector-value {
                                                            font-family: $archivo_bold;
                                                            font-size: 20px;
                                                            padding: 10px;
                                                            text-align: center;
                                                            width: 100%;
                                                        }
                                                        .btn {
                                                            display: flex;
                                                            justify-content: center;
                                                            padding: 10px 0px;
                                                            &.btn-add {
                                                                transform: rotate(180deg);
                                                            }
                                                        }
                                                    }
                                                    .separator {
                                                        font-family: $archivo_bold;
                                                        font-size: 20px;
                                                        position: relative;
                                                        top: -1px;
                                                    }
                                                }
                                                & > button {
                                                    background-color: var(--colorPrincipal);
                                                    border: none;
                                                    color: $white;
                                                    font-family: $archivo_medium;
                                                    font-size: 14px;
                                                    margin-top: 10px;
                                                    padding: 5px 0px;
                                                    width: 100%;
                                                }
                                                &.active {
                                                    display: block;
                                                    z-index: 5;
                                                }
                                            }
                                        }
                                        .sep-a {
                                            margin: 0px 9px;
                                        }
                                    }
                                }
                            }
                        }
                        &.show {
                            .name {
                                border-radius: 20px 20px 0px 0px;
                            }
                            .horarios {
                                height: 100%;
                            }
                        }
                    }
                }

                .descansos {
                    padding-top: 24px;
                    .descansos-tabs {
                        display: flex;
                        justify-content: space-between;
                        .descansos-tab {
                            align-items: center;
                            background-color: #FAFAFA;
                            color: var(--colorPrincipal);
                            cursor: pointer;
                            display: flex;
                            font-family: $archivo_bold;
                            height: 35px;
                            justify-content: center;
                            width: calc(50% - 2px);
                            &.active {
                                background-color: $azul3;
                            }
                        }
                    }
                    .vacacional {
                        margin-top: 3px;
                        .vacacional-add {
                            align-items: center;
                            border-radius: 20px;
                            cursor: pointer;
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 10px;
                            min-height: 38px;
                            padding: 0px 21px 0px 21px;
                            position: relative;
                            p {
                                font-family: $archivo_semibold;
                                font-size: 16px;
                            }
                            span {
                                position: relative;
                                top: 1px;
                            }

                        }
                        .vacacional-item {
                            background-color: $azul3;
                            color: var(--colorPrincipal);
                            display: flex;
                            margin-bottom: 8px;
                            min-height: 83px;
                            padding: 12px 21px;
                            gap: 20px;
                            & > div {
                                display: flex;
                                flex-direction: column;
                                min-height: 20px;
                                min-width: 20px;
                                .item-header {
                                    color: #7B7B7B;
                                    font-family: $archivo_bold;
                                    font-size: 10px;
                                    margin-bottom: 15px;
                                    text-transform: uppercase;
                                }
                                .item-content {
                                    align-items: center;
                                    display: flex;
                                    height: 40px;
                                }
                            }
                            .item-tipology {
                                flex: 1 110px;
                                .vacacional-type {
                                    width: 100%;
                                    .vacacional-type-select {
                                        .selector {
                                            align-items: center;
                                            color: var(--colorPrincipal);
                                            cursor: pointer;
                                            display: flex;
                                            font-family: $archivo_bold;
                                            font-size: 16px;
                                            justify-content: space-between;
                                            position: relative;
                                            span {
                                                position: relative;
                                                top: -1px;
                                                path {
                                                    stroke: var(--colorPrincipal);
                                                }
                                            }
                                        }
                                        .vacacional-type-options {
                                            display: none;
                                            &.active {
                                                display: block;
                                            }
                                        }
                                    }
                                }
                            }
                            .item-period {
                                flex: 1 350px;
                                .item-content {
                                    gap: 10px;
                                }
                                .vacacional-period {
                                    width: 100%;
                                    .vacacional-period-select {
                                        .selectors {
                                            display: flex;
                                            color: var(--colorPrincipal);
                                            .selector {
                                                align-items: center;
                                                border: 2px solid $black;
                                                border-radius: 30px;
                                                cursor: pointer;
                                                display: flex;
                                                font-family: $archivo_bold;
                                                font-size: 16px;
                                                margin-right: 10px;
                                                padding: 10px 11px;
                                                position: relative;
                                                &:last-of-type {
                                                    margin-right: 0px;
                                                }
                                                span {
                                                    margin-right: 9px;
                                                    position: relative;
                                                    top: 1px;
                                                    path {
                                                        fill: var(--colorPrincipal);
                                                    }
                                                }
                                                .date-text {
                                                    align-items: flex-start;
                                                    display: flex;
                                                    span {
                                                        margin: 0 6px;
                                                    }
                                                }
                                            }
                                        }
                                        .vacacional-period-options {
                                            border: none;
                                            display: none;
                                            flex-direction: row;
                                            left: 0px;
                                            min-height: 265px;
                                            top: 65px;
                                            padding: 0px;
                                            width: 270px;
                                            &.active {
                                                display: flex;
                                            }
                                            .period-calendar {
                                                width: 265px;
                                            }
                                            .period-time {
                                                width: 188px;
                                                .time-selector {
                                                    width: 100%;
                                                    .time-row {
                                                        display: flex;
                                                        gap: 2px;
                                                        justify-content: space-between;
                                                        position: relative;
                                                        width: 100%;
                                                        .time-item {
                                                            padding: 14px 0px;
                                                            flex: 1;
                                                            .time-item-title {
                                                                font-family: $archivo_medium;
                                                                font-size: 10px;
                                                                margin-bottom: 5px;
                                                                text-align: center;
                                                                text-transform: uppercase;
                                                            }
                                                            .item-command {
                                                                align-items: center;
                                                                display: flex;
                                                                font-family: $archivo_bold;
                                                                font-size: 22px;
                                                                justify-content: center;
                                                                .item-number {
                                                                    font-size: 30px;
                                                                    text-align: center;
                                                                    width: 35px;
                                                                }
                                                                .cmd-btn {
                                                                    cursor: pointer;
                                                                    text-align: center;
                                                                    width: 25px;
                                                                }
                                                            }
                                                        }
                                                        .item-title {
                                                            background-color: var(--colorPrincipal);
                                                            border-radius: 10px;
                                                            color: $white;
                                                            font-family: $archivo_medium;
                                                            font-size: 10px;
                                                            left: 50%;
                                                            padding: 4px 8px;
                                                            position: absolute;
                                                            top: 0px;
                                                            text-transform: uppercase;
                                                            transform: translateX(-50%);
                                                        }
                                                    }
                                                    .all-time-selector {
                                                        align-items: center;
                                                        background-color: var(--colorPrincipal);
                                                        color: $white;
                                                        display: flex;
                                                        font-family: $archivo_medium;
                                                        font-size: 14px;
                                                        height: 40px;
                                                        justify-content: center;
                                                        margin-top: 2px;
                                                        .check {
                                                            border: 1px solid $white;
                                                            height: 16px;
                                                            margin-right: 10px;
                                                            position: relative;
                                                            width: 16px;
                                                            .checked {
                                                                border-left: 2px solid $white;
                                                                border-bottom: 2px solid $white;
                                                                height: 6px;
                                                                left: 2px;
                                                                margin-right: 10px;
                                                                position: absolute;
                                                                top: 3px;
                                                                transform: rotate(-45deg);
                                                                width:10px;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .item-repeat {
                                flex: 1 307px;
                                .repeat-selector {
                                    align-items: center;
                                    display: flex;
                                    font-family: $archivo_medium;
                                    .repeat-item {
                                        align-items: center;
                                        border: 2px solid $black;
                                        border-radius: 20px;
                                        cursor: pointer;
                                        display: flex;
                                        height: 36px;
                                        margin-right: 4px;
                                        padding: 0px 10px;
                                        &.active {
                                            background-color: var(--colorPrincipal);
                                            color: var(--white);
                                        }
                                    }
                                }
                            }
                            .item-borrar {
                                align-items: center;
                                cursor: pointer;
                                flex: 1 30px;
                                justify-content: flex-end;
                                margin-bottom: 12px;
                                path {
                                    fill: var(--colorPrincipal);
                                }
                            }
                            .new-item-txt {
                                cursor: pointer;
                                font-family: $archivo_semibold;
                                font-size: 16px;
                            }
                            .new-item-row {
                                align-items: center;
                                color: $gris_oscuro;
                                display: flex;
                                justify-content: space-between;
                                font-family: $archivo_medium;
                                width: 100%;
                                .calendar-row {
                                    align-items: center;
                                    display: flex;
                                    width: 491px;
                                    span {
                                        color: $azul6;
                                        margin: 0px 19px;
                                    }
                                    .date-row {
                                        align-items: center;
                                        display: flex;
                                        &.del {
                                            span {
                                                margin: 0px 12px 0px 0px;
                                            }
                                        }
                                        .date-input {
                                            align-items: center;
                                            background-color: $white;
                                            cursor: pointer;
                                            display: flex;
                                            justify-content: center;
                                            height: 30px;
                                            position: relative;
                                            width: 131px;
                                            .vacacional-calendar {
                                                font-family: $archivo_regular;
                                                font-size: 10px;
                                                left: 0px;
                                                position: absolute;
                                                top: 30px;
                                                width: 287px;
                                            }
                                        }
                                        .time-input {
                                            align-items: center;
                                            background-color: $white;
                                            display: flex;
                                            height: 30px;
                                            justify-content: center;
                                            margin-left: 3px;
                                            width: 70px;
                                            .hora-input {
                                                width: 100%;
                                                .h-input {
                                                    font-family: $archivo_medium;
                                                    justify-content: center;
                                                }
                                            }
                                        }
                                    }
                                    &.hide-hours {
                                        .time-input {
                                            display: none !important;
                                        }
                                    }
                                }
                                .ylm-checkbox {
                                    margin-left: 12px;
                                    flex: 1 0 auto;
                                    p {
                                        font-family: $archivo_regular;
                                        font-size: 14px;
                                    }
                                }
                            }


                            
                            .react-calendar {
                                position: relative;
                                z-index: 5;
                                .react-calendar__navigation {
                                    text-align: center;
                                    padding: 8px 0px 12px;
                                    button {
                                        font-size: 15px;
                                    }
                                    .react-calendar__navigation__label span {
                                        font-family: $archivo_bold;
                                        font-size: 12px;
                                        color: $black;
                                    }
                                }
                                .react-calendar__viewContainer {
                                    .react-calendar__month-view__weekdays__weekday {
                                        font-size: 7px;
                                    }
                                    .react-calendar__tile {
                                        border: none !important;
                                        background-color: transparent;
                                        padding: 8px 12px !important;
                                        margin: 0px;
                                        abbr {
                                            font-size: 10px;
                                        }
                                    }
                                    .react-calendar__tile--active,
                                    .react-calendar__tile--hasActive {
                                        background: var(--colorPrincipal);
                                        color: $white;
                                        font-family: $archivo_bold;
                                    }
                                    .react-calendar__tile--rangeStart {
                                        border-radius: 15px 0px 0px 15px !important;
                                    }
                                    .react-calendar__tile--rangeEnd {
                                        border-radius: 0px 15px 15px 0px !important;
                                    }
                                    .react-calendar__tile--rangeBothEnds,
                                    .react-calendar__tile--hasActive {
                                        border-radius: 100px;
                                    }
                                    .react-calendar__tile--rangeBothEnds {
                                        border-radius: 100px !important;
                                    }
                                }
                            }
                            /* .react-calendar {
                                .react-calendar__navigation {
                                    padding: 10px 0px;
                                }
                                .react-calendar__navigation button {
                                    background: none;
                                    border: none;
                                    font-size: 20px;
                                }
                                .react-calendar__navigation__label span {
                                    font-family: "Archivo-Bold" !important;
                                    font-size: 14px;
                                }
                                .react-calendar__month-view__weekdays__weekday {
                                    font-size: 12px;
                                    padding-bottom: 5px;
                                }
                                .react-calendar__tile {
                                    padding: 5px 5px !important;
                                    margin: 0px;
                                }
                                .react-calendar__tile--active {
                                    background: none;
                                }
                                .react-calendar__tile abbr {
                                    font-size: 11px;
                                }
                                .react-calendar__tile--active abbr {
                                    background-color: var(--colorPrincipal);
                                    border-radius: 100px;
                                    color: $white;
                                    padding: 3px 5px;
                                }
                            } */
                        }
                        .botonera {
                            align-items: center;
                            display: flex;
                            .btn {
                                align-items: center;
                                background-color: $white;
                                cursor: pointer;
                                display: flex;
                                height: 50px;
                                justify-content: center;
                                margin-right: 14px;
                                width: 50px;
                                &:last-of-type {
                                    margin-right: 0px;
                                }
                                &:hover {
                                    opacity: 0.8;
                                }
                            }
                        }
                    }
                }
                .tab-servicios {
                    .ylm-select {
                        align-items: center;
                        cursor: pointer;
                        flex-direction: row;
                        span {
                            height: 17px;
                            margin-left: 12px;
                            width: 17px;
                            &::after{
                                border-color: $white;
                                width: 7px;
                            }
                        }
                        .ylm-select-options {
                            border: none;
                            border-radius: 10px;
                            box-shadow: 0px 2px 10px #cccccc;
                            padding: 0px;
                            top: 35px;
                            width: 318px;
                            .select-option {
                                border-bottom: 1px solid var(--colorPrincipal);
                                color: var(--colorPrincipal);
                                font-family: $archivo_regular;
                                font-size: 14px;
                                padding: 14px 12px 14px 19px;
                                &.active {
                                    & > span::after {
                                        border-color: var(--colorPrincipal);
                                    }
                                }
                                &:last-of-type {
                                    border-bottom: none;
                                }
                            }
                            &.active {
                                border: 1px solid var(--colorPrincipal);
                                max-height: 277px;
                            }
                        }
                    }
                    .add-servicio {
                        background-color: $azul3;
                        color: var(--colorPrincipal);
                        display: flex;
                        flex-direction: column;
                        font-family: $archivo_semibold;
                        font-size: 16px;
                        margin-top: 3px;
                        padding: 0px 21px 0px 33px;
                        .row-1 {
                            align-items: center;
                            display: flex;
                            justify-content: space-between;
                            min-height: 74px;
                            padding: 12px 0px;
                            .botonera {
                                align-items: center;
                                display: flex;
                                .button {
                                    align-items: center;
                                    background-color: $white;
                                    display: flex;
                                    height: 50px;
                                    justify-content: center;
                                    margin-left: 15px;
                                    width: 50px;
                                }
                            }
                        }
                        .row-2 {
                            .servicios-list {
                                align-items: center;
                                display: flex;
                                flex-wrap: wrap;
                                max-height: calc(100vh - 477px);
                                overflow: auto;
                                padding: 0px 0px 30px;
                                width: 100%;
                                .servicio-list-item {
                                    align-items: center;
                                    background-color: $white;
                                    border-radius: 20px;
                                    display: flex;
                                    justify-content: space-between;
                                    margin: 5px 20px 5px 0px;
                                    min-height: 43px;
                                    padding: 10px 20px 10px 20px;
                                    .btn-delete {
                                        cursor: pointer;
                                        margin-left: 20px;
                                        margin-right: -5px;
                                    }
                                }
                            }
                        }
                    }
                }
                .permisos {
                    .ylm-select {
                        align-items: center;
                        cursor: pointer;
                        flex-direction: row;
                        width: 350px;
                        span {
                            margin-left: 12px;
                        }
                        .ylm-select-options {
                            background-color: var(--color6);
                            border: none;
                            border-radius: 10px;
                            box-shadow: 0px 2px 10px #cccccc;
                            top: 35px;
                            padding: 0px;
                            width: 318px;
                            .select-option {
                                border-bottom: 1px solid var(--colorPrincipal);
                                font-family: $archivo_regular;
                                font-size: 14px;
                                padding: 14px 5px 14px 5px;
                                &:last-of-type {
                                    border: none;
                                }
                            }
                            &.active {
                                border: 1px solid var(--colorPrincipal);
                                max-height: 221px;
                            }
                        }
                    }
                    .add-permiso {
                        align-items: center;
                        background-color: $azul3;
                        color: var(--colorPrincipal);
                        display: flex;
                        font-family: $archivo_semibold;
                        font-size: 16px;
                        height: 70px;
                        margin-top: 3px;
                        padding: 0px 21px 0px 33px;
                        .add-permiso-options {
                            &.active {
                                max-height: 277px;
                            }
                            .select-option {
                                color: var(--colorPrincipal);
                                span {
                                    border-width: 1px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .single-profesional {
            padding: 44px 30px 21px;
        }
    }
}