.empresas {
    flex-shrink: 0;
    height: calc(100vh - 85px);
    width: calc(100% - 66px);
    padding-left: 33px;
    padding-right: 33px;
    position: relative;
    .preheader {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 28px;
        .title {
            align-items: center;
            color: var(--colorPrincipal);
            display: flex;
            span {
                display: inline-block;
                width: 25px;
                height: 25px;
                background-image: url('../../assets/img/sprite.png');
                background-position: -147px -380px;
                margin-right: 10px;
            }
            h1 {
                font-family: $archivo_bold;
                font-size: 28px;
                color: var(--colorPrincipal);
            }
        }
        .botonera {
            display: flex;
            justify-content: flex-end;
            flex: 1;
            & > div {
                margin-left: 8px;
            }
            .btn-add {
                width: 35px;
                height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $azul2;
                font-family: $archivo_bold;
                font-size: 21px;
                cursor: pointer;
            }

            .btn-search {
                align-items: center;
                background-color: var(--color6);
                color: var(--colorPrincipal);
                cursor: pointer;
                display: flex;
                font-family: $archivo_bold;
                font-size: 21px;
                height: 36px;
                justify-content: center;
                position: relative;
                width: 35px;
                svg {
                    g {
                        fill: transparent;
                    }
                    line {
                        stroke-width: 2px;
                    }
                }
                .search-input {
                    display: none;
                    position: relative;
                    input {
                        background: none;
                        border: none;
                        height: 100%;
                        width: 250px;
                        padding-left: 8px;
                        &::placeholder {
                            color: $black;
                        }
                    }
                    span {
                        font-family: $archivo_semibold;
                        font-size: 12px;
                        position: absolute;
                        right: 15px;
                        top: 0px;
                    }
                }
                &.active {
                    display: flex;
                    justify-content: flex-end;
                    width: auto;
                    padding-right: 8px;
                    .search-input {
                        display: flex;
                    }
                }
            }
        }
    }
    .table-empresas {
        display: flex;
        flex-direction: column;
        margin-top: 47px;
        .table-scrollable {
            height: calc(100vh - 293px);
            overflow: auto;
        }
        .table-row {
            border-bottom: 1px solid $gris4;
            cursor: pointer;
            display: flex;  
            padding: 12px 2px 12px 10px;
        }
        .column {
            display: flex;
            margin-right: 5px;
            overflow: hidden;
            span {
                margin-left: 22px;
            }
            &.name {
                flex-basis: 213px;
                min-width: 213px;
                @include xl() {
                    flex-basis: 260px;
                    min-width: 260px;
                }
                @include xxl() {
                    flex-basis: 29%;
                    min-width: 29%;
                }
            }
            &.phone {
                flex-basis: 110px;
                min-width: 110px;
                @include xxl() {
                    flex-basis: 10%;
                    min-width: 10%;
                }
            }
            &.email {
                flex-basis: 246px;
                min-width: 246px;
                @include xl() {
                    flex-basis: 219px;
                    min-width: 219px;
                }
                @include xxl() {
                    flex-basis: 20%;
                    min-width: 20%;
                }
            }
            &.sector {
                flex-basis: 128px;
                min-width: 128px;
                @include xxl() {
                    flex-basis: 10%;
                    min-width: 10%;
                }
            }
            &.plan {
                flex-basis: 140px;
                min-width: 140px;
                @include xxl() {
                    flex-basis: 208px;
                    min-width: 208px;
                }
                @include xxl() {
                    flex-basis: 15%;
                    min-width: 15%;
                }
                p {
                    margin: 0px 5px;
                    &:first-of-type {
                        margin-left: 0px;
                    }
                }
            }
            &.btns {
                position: relative;
                flex-basis: 20px;
                min-width: 20px;
                display: flex;
                justify-content: flex-end;
                overflow:initial;
                .btn-menu {
                    flex-shrink: 0;
                    cursor: pointer;
                    position: relative;
                    & > span {
                        align-items: center;
                        display: inline-flex;
                        justify-content: center;
                        width: 35px;
                    }
                    .submenu {
                        position: absolute;
                        display: none;
                        opacity: 0;
                        background-color: $white;
                        border: 1px solid $calendar-border-gray;
                        box-shadow: 0px 2px 10px #cccccc;
                        top: 10px;
                        right: 30px;
                        list-style: none;
                        width: 140px;
                        padding: 0px 20px;
                        transition: opacity 0.3s;
                        &.active {
                            display: block;
                            opacity: 1;
                        }
                        li {
                            display: flex;
                            align-items: center;
                            height: 40px;
                            border-bottom: 1px solid $calendar-border-gray;
                            cursor: pointer;
                            &:last-of-type {
                                border: none;
                            }
                            &:hover {
                                color: var(--colorPrincipal);
                            }
                        }
                    }
                    &.active {
                        .submenu {
                            display: block;
                            opacity: 1;
                        }
                    }
                }
            }
        }
        .headers-row {          
            .column {
                font-family: $archivo_bold;
                span {
                    position: relative;
                    top: -1px;
                    &.desc {
                        top: 2px;
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
    .paginacion {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 15px;
        .pag-btn {
            align-items: center;
            background-color: $azul2;
            color: var(--colorPrincipal);
            cursor: pointer;
            display: flex;
            font-family: $archivo_bold;
            font-size: 16px;
            justify-content: center;
            height: 35px;
            margin: 0px 5px;
            width: 35px;
        }
        .pag-number {
            margin: 3px 20px 0px;
        }
    }
}

.empresa {
    width: 100%;
    .empresa-wrapper {
        width: 963px;
        .header {
            display: flex;
            justify-content: space-between;
            min-height: 36px;
            width: 100%;
            .nav-back {
                align-items: center;
                color: $azul3;
                cursor: pointer;
                display: flex;
                font-family: $archivo_bold;
                font-size: 20px;
                p {
                    margin-left: 9px;
                }
            }

            .botonera {
                align-items: center;
                display: flex;
                .btn-mini {
                    background: none;
                    border: none;
                    height: 36px;
                    margin-left: 10px;
                    width: 36px;
                    &.btn-editar {
                        svg {
                            height: 16px;
                            width: 17px;
                        }
                    }
                    &.btn-eliminar {
                        svg {
                            height: 17px;
                            width: 17px;
                        }
                    }
                }
                .btn {
                    background: none;
                    background-color: $gestion_bg_menu;
                    border: none;
                    color: var(--colorPrincipal);
                    font-family: $archivo_bold;
                    font-size: 16px;
                    height: 36px;
                    margin-left: 10px;
                    width: 120px;
                    &.btn-crear {
                        background-color: var(--colorPrincipal);
                        color: $gestion_bg_menu;
                    }
                    &:hover {
                        opacity: 0.3;
                    }
                }
                .btn:active {
                    background-color: #EDD8BB; 
                }
            }
        }
        .empresa-body {
            margin-top: 18px;
            h1 {
                font-family: $archivo_bold;
                font-size: 26px;
            }
            .user-main {
                align-items: center;
                color: $gris_oscuro;
                display: flex;
                justify-content: space-between;
                margin-top: 12px;
                position: relative;
                width: 100%;

            }
            .col-1 {
                align-items: center;
                background-color: var(--color6);
                display: flex;
                height: 128px;
                width: calc(100% - 263px);
                .avatar {
                    background-size: cover;
                    background-position: center;
                    border-radius: 50%;
                    cursor: pointer;
                    height: 73px;
                    margin-left: 30px;
                    position: relative;
                    width: 73px;

                    svg {
                        height: 73px;
                        left: 1px;
                        position: relative;
                        top: 2px;
                        width: 73px;
                    }
                    .avatar-bg {
                        align-items: center;
                        background-color: rgba(82,79,79,0.7);
                        border-radius: 50%;
                        color: $white;
                        display: flex;
                        font-family: $archivo_bold;
                        font-size: 21px;
                        height: 100%;
                        justify-content: center;
                        left: 0px;
                        position: absolute;
                        top: 0px;
                        width: 100%;
                    }
                }
                .data-group {
                    align-items: center;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    width: 100%;
                    .data {
                        display: flex;
                        justify-content: space-between;
                        margin-left: 22px;
                        min-width: 300px;
                        padding-right: 20px;
                        position: relative;
                        width: 100%;
                        .name {
                            width: 176px;
                            input {
                                background-color: transparent;
                                border: none;
                                font-family: $archivo_bold;
                                font-size: 16px;
                                padding: 5px 8px;
                                width: 100%;
                                &::placeholder {
                                    color: $gris_oscuro;
                                }
                            }
                        }
                    }
                    .contact-data {
                        align-items: center;
                        display: flex;
                        margin-left: 22px;
                        margin-top: 19px;
                        width: 100%;
                        .contact-input {
                            margin-right: 8px;
                            input {
                                border: none;
                                color: var(--colorPrincipal);
                                height: 30px;
                                text-align: left;
                                font-family: $archivo_regular;
                                font-size: 16px;
                                padding: 0px 8px;
                                position: relative;
                                width: 100%;
                                &::placeholder {
                                    color: $black;
                                }
                            }
                            &.phone {
                                flex: 0 0 126px;
                                input {
                                    text-align: left;
                                }
                            }
                            &.email {
                                flex: 1 1 auto;
                                &:disabled {
                                    color: var(--colorPrincipal);
                                }
                                .email-msg {
                                    bottom: 6px;
                                    color: var(--color5);
                                    font-size: 12px;
                                    position: absolute;
                                    padding-left: 10px;
                                }
                            }
                            &.sector {                                
                                color: var(--colorPrincipal);
                                flex: 0 0 150px;
                            }
                        }
                    }
                }
            }
            .col-2 {
                background-color: var(--color6);
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 128px;
                padding: 20px 14px 20px 26px;
                width: 258px;
                .col-item {
                    align-items: center;
                    display: flex;
                    font-family: $archivo_bold;
                    font-size: 16px;
                    justify-content: space-between;
                    width: 100%;
                    &.plan-price {
                        color: var(--colorPrincipal);
                        font-family: $archivo_bold;
                        font-size: 50px;
                        span {
                            font-size: 20px;
                        }
                    }
                }
            }
        }

        .business-plan {
            width: 100%;
            .business-plan-select {
                .selector {
                    align-items: center;
                    color: var(--black);
                    cursor: pointer;
                    display: flex;
                    font-family: $archivo_bold;
                    font-size: 16px;
                    justify-content: space-between;
                    position: relative;
                    span {
                        position: relative;
                        top: -1px;
                        path {
                            stroke: var(--black);
                        }
                    }
                }
                .business-plan-options {
                    background-color: var(--color6);
                    border-color: var(--colorPrincipal);
                    display: none;
                    font-family: $archivo_regular;
                    font-size: 14px;
                    padding: 0px;
                    top: 30px;
                    &.active {
                        border-radius: 10px;
                        display: block;
                        max-height: 130px;
                    }
                    li {
                        border-color: var(--colorPrincipal);
                        padding: 13px 10px 13px 25px;
                    }
                }
            }
        }
        .business-sector {
            width: 100%;
            .business-sector-select {
                .selector {
                    align-items: center;
                    color: var(--colorPrincipal);
                    cursor: pointer;
                    display: flex;
                    font-size: 16px;
                    justify-content: space-between;
                    position: relative;
                    span {
                        position: relative;
                        top: -1px;
                        path {
                            stroke: var(--black);
                        }
                    }
                }
                .business-sector-options {
                    background-color: var(--color6);
                    border-color: var(--colorPrincipal);
                    display: none;
                    font-family: $archivo_regular;
                    font-size: 14px;
                    padding: 0px;
                    top: 30px;
                    &.active {
                        border-radius: 10px;
                        display: block;
                        max-height: 130px;
                    }
                    li {
                        border-color: var(--colorPrincipal);
                        padding: 13px 10px 13px 25px;
                    }
                }
            }
        }
        .user-contract {
            display: flex;
            justify-content: space-between;
            margin-top: 40px;
            h3 {
                color: var(--colorSecundario);
                font-weight: normal;
                margin-bottom: 13px;
            }
            .contract {
                width: 285px;
                .contract-file {
                    color: var(--gris1);
                    font-size: 9px;
                    position: relative;
                    width: 100%;
                    p {
                        align-items: center;
                        background-color: var(--gris3);
                        border-radius: 8px;
                        display: flex;
                        height: 40px;
                        padding: 0px 70px 0px 15px;
                        span {
                            margin-right: 6px;
                            svg {
                                fill: var(--gris1);
                            }
                        }
                    }
                    button {
                        background: none;
                        background-color: var(--colorPrincipal);
                        border: none;
                        border-radius: 8px;
                        color: var(--white);
                        height: 40px;
                        position: absolute;
                        right: 0px;
                        top: 0px;
                        width: 60px;
                    }
                }
            }
            .lock {
                width: 213px;
                .lock-selector {
                    align-items: center;
                    background-color: var(--gris3);
                    border-radius: 8px;
                    display: flex;
                    height: 40px;
                    justify-content: space-between;
                    padding: 0px 17px;
                    width: 100%;
                    .lock-toggle {
                        cursor: pointer;
                        .toggle-bg {
                            background-color: var(--white);
                            border-radius: 40px;
                            height: 11px;
                            position: relative;
                            width: 34px;
                            .toggle-switch {
                                background-color: var(--gris5);
                                border: 2px solid var(--white);
                                border-radius: 50%;
                                height: 19px;
                                left: 0px;
                                position: absolute;
                                top: -4px;
                                transition: transform 0.3s;
                                width: 19px;
                            }
                        }
                    }
                    &.active {
                        background-color: var(--colorSecundario);
                        i {
                            color: var(--colorPrincipal);
                        }
                        .lock-toggle {
                            .toggle-bg {
                                .toggle-switch {
                                    background-color: var(--colorPrincipal);
                                    transform: translateX(16px);
                                }
                            }
                        }
                    }
                }
            }
        }
        .pagos {
            margin-top: 55px;
            h3 {
                color: var(--colorSecundario);
                font-weight: normal;
                margin-bottom: 5px;
            }
            .pagos-table {
                .pagos-row {
                    align-items: center;
                    color: $gris_oscuro;
                    display: flex;
                    justify-content: flex-start;
                    border-bottom: 1px solid $calendar-pasado;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    & > div {
                        padding-left: 15px;
                        flex: 0 0 auto;
                    }
                    &.headers {
                        font-family: $archivo_bold;
                        padding-bottom: 5px;
                        .factura {
                            padding-left: 0px;
                            cursor: default;
                        }
                    }
                    .plan { flex-basis: 218px; }
                    .facturado { flex-basis: 210px; }
                    .cuota { flex-basis: 167px; }
                    .pago { 
                        flex-basis: 200px;
                        font-family: $archivo_bold;
                        &.pendiente {
                            color: $table_red_text;
                        }
                    }
                    .factura { 
                        flex: 1 1 auto; 
                        padding-left: 20px;
                        cursor: pointer;
                        svg {
                            path {
                                fill: $gris_oscuro;
                            }
                        }
                    }
                }
            }
        }
    }
}

.single-empresa {
    padding: 44px 30px;
}