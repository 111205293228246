.alta-cliente {
    position: absolute;
    left: 0px;
    background-color: #535AF1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 75px 0px 86px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 0px;
    z-index: 20;
    opacity: 1;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    top: 0px;
    @include lg(){
        padding: 0px 0px 0px;
        display: flex;
        align-items: flex-start;
    }
    a {
        color: var(--white);
        font-family: $archivo_medium;
        margin-top: 30px;
        text-decoration: none;
        &:hover {
            opacity: 0.7;
        }
    }
    &.active {
        padding: 75px 0px 86px;
        top: 0px;
        height: 100vh;
        position: fixed;
        opacity: 1;
    }
    ::placeholder {
        color: $font;
    }
    .form-container {
        width: calcula(375, 325);
        margin: 0 auto;
        text-align: center;
        @include lg(){
            width: 856px;
        }
        .form-header {
            display: flex;
            justify-content: center;
            img {
                width: 152px;
            }
        }
        h1 {
            color: var(--white);
            font-family: $archivo_semibold;
            font-size: 17px;
            font-weight: normal;
            line-height: 23px;
            margin-top: 26px;
        }
        .steps-indicator {
            margin: 30px auto 0;
            position: relative;
            // width: 623px;
            width:430px;
            .steps-line {
                background-color: var(--white);
                height: 2px;
                left: 5px;
                position: absolute;
                top: 20px;
                width: calc(100% - 10px);
            }
            .steps-container {
                align-items: center;
                display: flex;
                justify-content: space-between;
                position: relative; 
                .step {
                    align-items: center;
                    background-color: #afb4f9;
                    border-radius: 50%;
                    color: var(--white);
                    display: flex;
                    font-family: $archivo_bold;
                    font-size: 16px;
                    height: 42px;
                    justify-content: center;
                    width: 42px;
                    &.active {
                        background-color: var(--color5);
                    }
                }
            }
        }
        .steps-screens {
            height: 550px;
            text-align: left;
            overflow-x: hidden;
            overflow-y: auto;
            position: relative;
            @include lg(){
                
            }
            h2 {
                color: var(--white);
                font-family: $archivo_bold;
                font-size: 20px;
                line-height: 22px;
                margin-left: 22px;
                margin-top: 32px;
            }

            form {
                margin-top: 8px;
                .form-row {
                    display: flex;
                    gap: 16px;
                    justify-content: space-between;
                }
                .form-input {
                    background-color: var(--white);
                    border-radius: 100px;
                    flex: 1;
                    font-family: $archivo_medium;
                    margin-top: 14px;
                    padding: 7px 27px 8px;
                    position: relative;
                    label {
                        color: #D6D6D6;
                        display: block;
                        font-size: 14px;
                    }
                    input {
                        border: none;
                        background-color: transparent;
                        font-size: 20px;
                        padding-right: 30px;
                        width: 100%;
                    }
                    .error-indicator {
                        position: absolute;
                        right: 20px;
                        top: 19px;
                    }
                }
                .form-btn {
                    display: flex;
                    justify-content: center;
                    margin-top: 105px;
                    button {
                        background-color: transparent;
                        border: 1px solid var(--white);
                        border-radius: 100px;
                        color: var(--white);
                        font-family: $archivo_bold;
                        font-size: 18px;
                        height: 55px;
                        width: 274px;
                    }
                }
            }
        }
    }
    .modal-planes {
        .plan-excerpt {
            font-family: $archivo_regular;
            font-size: 12px;
            line-height: 19px;
        }
    }
}

.steps-tab {
    opacity: 0;
    position: absolute;
    transform: translateX(100%);
    transition: transform 0.3s;
    width: 100%;
    &.active {
        opacity: 1;
        transform: translateX(0%);
    }
    &.tab-suscripcion {
        h2 {
            margin-left: 0px !important;
        }
        .form-btn {
            display: flex;
            justify-content: center;
            margin-top: 20px;
            button {
                background-color: transparent;
                border: 1px solid var(--white);
                border-radius: 100px;
                color: var(--white);
                font-family: $archivo_bold;
                font-size: 18px;
                height: 55px;
                width: 274px;
            }
        }
    }
    &.tab-pago {
        h2 {
            margin-left: 0px !important;
            text-align: center;
        }
        .payment {
            display: flex;
            justify-content: center;
            margin-top: 20px;
            iframe {
                border: none;
                height: 405px;
                width: 400px;
            }
        }
        .form-btn {
            display: flex;
            justify-content: center;
            margin-top: 20px;
            button {
                background-color: transparent;
                border: 1px solid var(--white);
                border-radius: 100px;
                color: var(--white);
                font-family: $archivo_bold;
                font-size: 18px;
                height: 55px;
                width: 274px;
            }
        }
    }
    .planes-row {
        display: flex;
        gap: 6px;
        justify-content: space-between;
        margin-top: 20px;
        .plan-item {
            background-color: var(--white);
            border-radius: 20px;
            color: var(--colorPrincipal);
            font-family: $archivo_regular;
            font-size: 14px;
            padding: 38px 20px 10px 20px;
            position: relative;
            width: 33%;
            .title-row {
                padding: 0px 8px;
                .title {
                    font-family: $archivo_semibold;
                    font-size: 22px;
                    line-height: 24px;
                }
            }
            .price {
                font-family: $archivo_bold;
                font-size: 50px;
                margin-top: 4px;
                padding: 0px 8px;
                span {
                    font-size: 19px;
                }
            }
            .recomendado {
                background-color: var(--color5);
                border-radius: 100px;
                color: var(--white);
                font-family: $archivo_semibold;
                font-size: 19px;
                left: 50%;
                line-height: 21px;
                padding: 12px 33px;
                position: absolute;
                top: -22px;
                transform: translateX(-50%);
            }
            .description {
                line-height: 19px;
                margin-top: 9px;
                padding: 0px 8px;
            }
            .plan-btn {
                align-items: center;
                display: flex;
                justify-content: center;
                margin-top: 20px;
                button {
                    background: none;
                    border: 3px solid var(--colorPrincipal);
                    border-radius: 100px;
                    color: var(--colorPrincipal);
                    font-family: $archivo_bold;
                    font-size: 20px;
                    height: 64px;
                    width: 232px;
                    &.inverse {
                        background-color: var(--colorPrincipal);
                        color: var(--white);
                    }
                }
            }
            .more-link {
                align-items: center;
                display: flex;
                font-family: $archivo_bold;
                font-size: 16px;
                justify-content: center;
                margin-top: 16px;
            }
        }
    }
}