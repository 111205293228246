.recover-password {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0 auto;
    width: calc(100% - 30px);
    max-width: 600px;
    border: 3px solid $black;
    margin-top: 180px;
    padding: 66px 10px 0px;
    margin: 20px 15px;
    .logo {
        text-align: center;
        img {
            width: 55%;
        }
    }
    .separador {
        text-align: center;
        margin-top: 53px;
        margin-bottom: 81px;
    }
    h3 {
        font-size: 19px;
        line-height: 30px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: #a98e65;
        margin-bottom: 10px;
    }
    .text {
        font-size: 19px;
        text-align: center;
        margin-top: 23px;
        p {
            line-height: 31px;
        }
    }
    .link {
        padding: 64px 0px 88px;
        text-align: center;
        a {
            display: block;
            font-weight: bold;
            font-size: 23px;
            text-decoration: none;
            background-color: #a08d67;
            color: #ffffff;
            margin: 0 auto;
            max-width: 66%;
            padding: 19px 20px;
        }
    }
}