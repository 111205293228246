.dropdown-date,
.dropdown-time {
    position: relative;
    
    input[type="date"],
    input[type="time"] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 1;
        
        // Estilos específicos para Safari
        &::-webkit-calendar-picker-indicator {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: transparent;
            color: transparent;
            cursor: pointer;
        }
    }

    .date-display,
    .time-display {
        position: relative;
        z-index: 0;
        // ... estilos existentes ...
    }
} 