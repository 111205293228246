.account-confirmation {
    align-items: center;
    background-color: #535AF1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    left: 0px;
    height: 100vh;
    justify-content: center;
    opacity: 1;
    overflow: hidden;
    padding: 75px 0px 86px;
    position: absolute;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    top: 0px;
    width: 100vw;
    z-index: 20;
    @include lg(){
        padding: 0px 0px 0px;
        display: flex;
        align-items: center;
    }
    .form-header {
        display: flex;
        justify-content: center;
        img {
            width: 152px;
        }
        .btn-menu {
            position: absolute;
            right: 25px;
            width: 31px;
            height: 32px;
            margin-top: 5px;
            // background-image: url('../../assets/img/sprite.png');
            background-position: -517px -186px;
            cursor: pointer;
            z-index: 10;
            @include lg(){
                margin-top: 0px;
                right: 107px;
                width: 50px;
                height: 32px;
                background-position: -293px -138px;
                display: none;
            }
            &:hover {
                background-position: -294px -191px;
                @include lg(){
                    background-position: -79px -140px;
                }
            }
        }
    }
    h1 {
        color: var(--white);
        font-family: $archivo_bold;
        font-size: 28px;
        line-height: 31px;
        margin-bottom: 32px;
        margin-top: 98px;
    }
    .text {
        color: var(--white);
        font-family: $archivo_medium;
        line-height: 26px;
        margin-bottom: 32px;
        text-align: center;
        width: 450px;
    }
    button {
        background: none;
        border: 1px solid var(--white);
        border-radius: 100px;
        color: var(--white);
        font-family: $archivo_bold;
        font-size: 18px;
        height: 55px;
        margin-bottom: 28px;
        width: 275px;
    }
    .message {
        color: var(--white);
        font-family: $archivo_medium;
        font-size: 15px;
        text-align: center;
        width: 225px;
    }

}

.login-container, .register-container, .renew-container {
    position: absolute;
    left: 0px;
    background-color: #535AF1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 75px 0px 86px;
    overflow: hidden;
    width: 100%;
    height: 0px;
    z-index: 20;
    opacity: 1;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    top: 0px;
    @include lg(){
        padding: 0px 0px 0px;
        display: flex;
        align-items: center;
    }
    a {
        color: var(--white);
        font-family: $archivo_medium;
        margin-top: 30px;
        text-decoration: none;
        &:hover {
            opacity: 0.7;
        }
    }
    &.active {
        padding: 75px 0px 86px;
        top: 0px;
        height: 100vh;
        position: fixed;
        opacity: 1;
    }
    ::placeholder {
        color: $font;
    }
    .btn-desktop {
        position: absolute;
        right: 25px;
        width: 31px;
        height: 32px;
        margin-top: 5px;
        background-image: url('../../assets/img/sprite.png');
        background-position: -517px -186px;
        cursor: pointer;
        z-index: 10;
        display: none;
        @include lg(){
            display: block;
            margin-top: 0px;
            top: 86px;
            right: 113px;
            width: 31px;
            height: 32px;
            background-position: -517px -186px;
        }
        &:hover {
            background-position: -294px -191px;
            @include lg(){
                background-position: -474px -186px;
            }
        }
    }
    .form-container {
        width: calcula(375, 325);
        margin: 0 auto;
        text-align: center;
        @include lg(){
            width: 275px;
        }
        .form-header {
            display: flex;
            justify-content: center;
            img {
                width: 152px;
            }
            .btn-menu {
                position: absolute;
                right: 25px;
                width: 31px;
                height: 32px;
                margin-top: 5px;
                // background-image: url('../../assets/img/sprite.png');
                background-position: -517px -186px;
                cursor: pointer;
                z-index: 10;
                @include lg(){
                    margin-top: 0px;
                    right: 107px;
                    width: 50px;
                    height: 32px;
                    background-position: -293px -138px;
                    display: none;
                }
                &:hover {
                    background-position: -294px -191px;
                    @include lg(){
                        background-position: -79px -140px;
                    }
                }
            }
        }
        h1 {
            color: var(--white);
            font-family: $archivo_medium;
            font-size: 14px;
            font-weight: normal;
            margin-top: 50px;
        }
        form {
            display: flex;
            flex-direction: column;
            margin-top: 23px;
            height: calc( 100vh - 135px);
            overflow: auto;
            @include lg(){
                margin-top: 42px;
                height: auto;
            }
            .form-item {
                position: relative;
                width: 100%;
                p {
                    font-family: $archivo_medium;
                    font-size: 16px;
                    color: $gold;
                    position: absolute;
                    left: 0px;
                    bottom: -25px;
                    overflow: hidden;
                    height: 0px;
                    transition: height 0.3s;
                    &.active {
                        height: 17px;
                    }
                }
            }
            .error-text {
                bottom: -22px;
                color: var(--white) !important;
                font-family: $archivo_regular !important;
                font-size: 12px !important;
                font-weight: normal;
                margin-left: 15px;
            }
            .auth__input {
                background: $white;
                border: none;
                border-radius: 22px;
                font-family: $archivo_semibold;
                font-size: 16px;
                padding: 0px 10px 0px 27px;
                margin-top: 28px;
                width: 100%;
                height: 45px;
                &:focus {
                    outline: unset;
                }
                &::placeholder {
                    color: $gris_d6;
                }
                &[disabled]{
                    color: $disabled;
                    border-color: $disabled;
                    &::placeholder {
                        color: $disabled;
                    }
                }
            }
            .chk-recordarme {
                font-family: $archivo_semibold;
                color: $white;
                display: flex;
                justify-content: center;
                margin-top: 24px;
                label.container {
                    padding-top: 4px;
                    font-size: 14px;
                }
                .checkmark {
                    width: 21px;
                    height: 21px;
                    border-color: $white;
                    background-color: transparent;
                    &::after {
                        left: 6px;
                        top: 1px;
                        width: 5px;
                        height: 11px;
                        border-color: $white;
                        border-width: 0px 3px 3px 0px;
                    }
                }
            }
            .form-messages {
                font-family: $archivo_medium;
                font-size: 16px;
                line-height: 23px;
                margin-top: 30px;
                cursor: pointer;
                p {
                    color: $white;
                    &.no-email {
                        margin-top: 15px;
                    }
                }
                a {
                    color: $white;
                    text-decoration: none;
                }
                &.recover {
                    p {
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
            }
            
            .form-remember {
                height: 0px;
                overflow: hidden;
                transition: height .3s;
                input {
                    width: 100%;
                }
                &.active {
                    height: 150px;
                }
                p {
                    &.error-message {
                        height: 0px;
                        overflow: hidden;
                        font-family: $archivo_medium;
                        font-size: 16px;
                        color: $gold;
                        line-height: 2.3rem;
                        transition: height .3s;
                        &.active {
                            height: 32px;
                        }
                    }
                    &.remember-text {
                        font-family: $archivo_medium;
                        font-size: 16px;
                        margin-top: 1rem;
                        line-height: 1.7rem;
                        color: $white;
                    }
                }
            }

            button {
                margin: 0;
                background: none;
                font-family: $archivo_bold;
                border: 1px solid $white;
                border-radius: 39px;
                font-size: 18px;
                color: $white;
                height: 54px;
                margin-top: 32px;
                width: 214px;
                overflow: hidden;
                padding: 0px;
                display: none;
                &.visible {
                    display: inline-block;
                }
                &.btn-enviar-email {
                    margin-top: 0px;
                }
                &:hover {
                    background-color: $white;
                    color: #535AF1;
                    border-color: $gold;
                }
            }
        }
        
    }
    &.recover {
        form {
            margin-top: 6px;
        }
    }
    &.reset-pwd {
        h1 {
            text-align: center;
            transform: translateX(-63px);
            width: 401px;
        }
        form {
            margin-top: 16px;
            button {
                width: 100%;
            }
        }
    }
}

.register-container {
    .form-container {
        @include lg(){
            max-height: calc(100vh - 150px);
            overflow: auto;
        }
        .form-header {
            position: fixed;
            background-color: $login-background-opacity;
            width: calcula(375, 325);
            padding: 0px 0px 20px;
            @include lg(){
                width: 100%;
            }
            .btn-menu {
                right: 0;
            }
        }
        form {
            .form-messages {
                overflow: hidden;
                height: 0px;
                transition: height 0.3s;
                &.active {
                    height: 84px;
                }
            }
            button {
                border: 1px solid $white;
                width: 100%;
                @include lg(){
                    width: 170px;
                    margin-right: 25px;
                }
            }
        }
    }
}