.home-carousel {
    .slick-slider {
        width: 100%;
        height: 100%;
        overflow: hidden;
        .slick-list {
            width: calc(100% - 62px);
            margin-left: 31px;
            height: 100%;
            min-height: 511px;
            @include lg(){
                min-height: 528px;
            }
            .slick-track {
                width: 100%;
                height: 100%;
                .slick-slide {
                    div {
                        
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            
        }
        .slick-arrow {
            left: 10px;
            width: 15px;
            height: 25px;
            background-image: url('../../assets/img/sprite.png');
            background-position: -163px -2px;
            @include lg(){
                left: 0px;
            }
            &::before {
                content: '';
            }
            &.slick-next {
                left: auto;
                right: 10px;
                background-position: -194px -2px;
                @include lg(){
                    left: auto;
                    right: 0px;
                }
            }
        }
    }

    .carousel-item {
        position: relative;
        height: 528px;
        margin: 0px 11px;
        background-size: cover;
        background-position: center;
        .carousel-content {
            display: flex;
            flex-direction: column;
            color: $white;
            padding: 40px 35px 0px;
            transition: all .3s;
            height: calc( 100% - 40px);
            @include lg(){
                opacity: 0;
            }
            &:hover {
                opacity: 1;
            }
            h3 {
                font-family: $archivo_bold;
                font-size: 41px;
            }
            .descripcion {
                font-family: $archivo_semibold;
                font-size: 16px;
                line-height: 23px;
                padding-top: 24px;
            }
            .separador {
                display: block;
                width: 18px;
                height: 3px;
                background-color: $white;
                margin-top: 18px;
            }
            .precio {
                font-family: $archivo_bold;
                font-size: 25px;
                padding-top: 14px;
            }
            .boton {
                position: absolute;
                bottom: 45px;
                width: calc( 100% - 70px);
                a {     
                    text-decoration: none;
                    display: block;
                    text-align: center;
                    margin: 0 auto;
                    background: none;
                    border: 3px solid $white;
                    font-family: $archivo_bold;
                    color: $white;
                    font-size: 20px;
                    padding: 13px 0px;
                    width: 100%;
                    &:hover {
                        color: $gold;
                        border-color: $gold;
                    }
                }
            }
        }                   
    }
}