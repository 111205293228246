.descuentos {
    height: calc( 100vh - 203px );
    overflow: auto;
    .descuentos-grid {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
        margin-top: 44px;
        width: 100%;
        .descuento-card {
            cursor: pointer;
            height: 142px;
            padding-bottom: 11px;
            position: relative;
            width: 220px;
            .card-main {
                border-radius: 10px;
                height: 100%;
                padding: 15px 17px 5px 17px;
                .card-row {
                    display: flex;
                    justify-content: space-between;
                    &.row-descuento {
                        align-items: center;
                    }
                }
                .descuento-type {
                    font-family: $archivo_medium;
                    font-size: 9px;
                    margin-top: 2px;
                    text-transform: uppercase;
                }
                .descuento-title {
                    font-family: $archivo_semibold;
                    font-size: 16px;
                    height: 24px;
                    line-height: 19px;
                    overflow: hidden;
                }
                .descuento-amount {
                    font-family: $archivo_bold;
                    font-size: 35px;
                    span {
                        font-size: 20px;
                    }
                }
            }
            .descuento-expiration {
                display: inline-block;
                position: absolute;
                bottom: 0px;
                left: 17px;
                p {
                    align-items: center;
                    background-color: var(--colorSecundario);
                    border: 1px solid;
                    border-radius: 10px;
                    display: flex;
                    padding: 4px 10px;
                    svg {
                        margin-top: -1px;
                        margin-right: 4px;
                    }
                }
            }
            .descuento-overlay {
                align-items: center;
                background-color: rgba(0,0,0,0.75);
                border-radius: 10px;
                display: flex;
                height: 100%;
                justify-content: center;
                left: 0px;
                position: absolute;
                top: 0px;
                width: 100%;
                .overlay-buttons {
                    display: flex;
                    button {
                        background: none;
                        background-color: $white;
                        border: none;
                        border-radius: 50%;
                        height: 45px;
                        margin: 0px 4px;
                        width: 45px;
                        svg {
                            margin-top: 3px;
                            margin-left: 1px;
                        }
                        &.trash {
                            svg {
                                height: 15px;
                                width: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.descuento {
    width: 100%;
    input {
        color: $gris_fuerte;
        font-family: $archivo_regular;
        font-size: 14px;
        &:disabled {
            background-color: transparent;
            color: $black;
        }
    }
    textarea {
        color: $gris_fuerte;
        &:disabled {
            background-color: transparent;
            color: $black;
        }
    }
    .descuento-wrapper {
        width: 963px;
        .single-descuento {
            padding: 44px 30px 0px;
            .header {
                display: flex;
                justify-content: space-between;
                min-height: 36px;
                width: 100%;
                .nav-back {
                    align-items: center;
                    color: $azul3;
                    cursor: pointer;
                    display: flex;
                    font-family: $archivo_bold;
                    font-size: 20px;
                    p {
                        margin-left: 9px;
                    }
                }
                .botonera {
                    align-items: center;
                    display: flex;
                    transition: transform 0.3s;
                    .btn-mini {
                        background: none;
                        border: none;
                        height: 36px;
                        margin-left: 10px;
                        width: 36px;
                        &.btn-editar {
                            svg {
                                height: 16px;
                                width: 17px;
                            }
                        }
                        &.btn-eliminar {
                            svg {
                                height: 17px;
                                width: 17px;
                            }
                        }
                    }
                    .btn {
                        background: none;
                        background-color: $gestion_bg_menu;
                        border: none;
                        color: var(--colorPrincipal);
                        font-family: $archivo_bold;
                        font-size: 16px;
                        height: 36px;
                        margin-left: 10px;
                        width: 120px;
                        &.btn-crear {
                            background-color: var(--colorPrincipal);
                            color: $gestion_bg_menu;
                        }
                        &:hover {
                            opacity: 0.3;
                        }
                    }
                    .btn:active {
                        background-color: #EDD8BB; 
                    }
                }
                &.compress {
                    .botonera {
                        transform: translateX(-135px);                
                    }
                }
            }
            h3 {
                font-weight: 300;
                font-size: 16px;
                margin-bottom: 10px;
            }
            input {
                &::placeholder {
                    color: $gris_fuerte;
                }
            }
            textarea {
                border: none;
                font-family: $archivo_regular;
                font-size: 14px;
                line-height: 23px;
                margin-top: 8px;
                min-height: 74px;
                resize: none;
                width: 100%;
                &::placeholder {
                    color: $gris_fuerte;;
                    font-style: italic;
                }
            }
            .name {
                margin-top: 19px;
                input {
                    border: none;
                    font-family: $archivo_bold;
                    font-size: 26px;
                    width: 100%;
                }
            }
            .descuento-main {
                height: calc( 100vh - 254px);
                margin-top: 25px;
                overflow: auto;
                width: 100%;
                .descuento-data-row {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    .descuento-data {
                        flex: 1 1 auto;
                        .descuento-radios {
                            align-items: center;
                            display: flex;
                            margin-bottom: 32px;
                            .descuento-radio-group {
                                align-items: center;
                                display: flex;
                                margin-right: 100px;
                                .ylm-radio {
                                    margin-right: 20px;
                                }
                            }
                        }
                    }
                    .descuento-price {
                        padding: 0px 0px 0px 25px;
                        position: relative;
                        width: 146px;
                        .price-select {
                            align-items: center;
                            border-radius: 10px;
                            display: flex;
                            height: 103px;
                            justify-content: center;
                            .number {
                                align-items: center;
                                display: flex;
                                flex-direction: column;
                                margin-top: 5px;
                                .number-row {
                                    align-items: flex-end;
                                    display: flex;
                                    font-family: $archivo_bold;
                                    font-size: 38px;
                                    justify-content: center;
                                    margin-left: 10px;
                                    .only-number {
                                        align-items: center;
                                        display: flex;
                                        justify-content: center;
                                        input {
                                            background-color: transparent;
                                            border: none;
                                            font-family: $archivo_bold;
                                            font-size: 38px;
                                            text-align: center;
                                            width: 85px;
                                        }
                                    }
                                    .number-symbol {
                                        font-size: 25px;
                                        margin-right: 10px;
                                        padding-bottom: 5px;
                                    }
    
                                }
                                .open-btn {
                                    cursor: pointer;
                                    margin-top: 5px;
                                    svg {
                                        height: 7px;
                                        width: 14px;
                                    }
                                }
                            }
                            .type-selector {
                                background-color: $white;
                                border: 1px solid #E3E0E0;
                                box-shadow: 0px 2px 10px #cccccc;
                                list-style: none;
                                padding: 0px 19px;
                                position: absolute;
                                right: 0px;
                                top: 86px;
                                width: 140px;
                                li {
                                    border-bottom: 1px solid #E3E0E0;
                                    padding: 10px 0px;
                                    &:last-of-type {
                                        border: none;
                                    }
                                    .ylm-radio {
                                        p {
                                            font-size: 14px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
            }
            .selectors-title {
                margin-top: 30px;
            }
            .descuento-selectors {
                display: flex;
                flex-wrap: wrap;
                gap: 7px;
                .descuento-selector {
                    border-radius: 10px;
                    display: flex;
                    height: 71px;
                    padding: 8px;
                    position: relative;
                    width: 220px;
                    .left {
                        .icon-bg {
                            align-items: center;
                            background-color: $white;
                            border-radius: 10px;
                            display: flex;
                            height: 54px;
                            justify-content: center;
                            width: 54px;
                        }
                    }
                    .right {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        flex: 1 1 auto;
                        margin-left: 15px;
                        .title {
                            font-size: 11px;
                            margin-top: 5px;
                            text-transform: uppercase;
                        }
                    }

                    .i-categorias {
                        svg {
                            height: 24px;
                            width: 21px;
                        }
                    }

                    &.prepago {
                        .right {
                            flex-direction: row;
                            .ylm-toggle {
                                flex: 1 1 auto;
                                justify-content: space-between;
                                margin-right: 10px;
                            }
                            p {
                                font-size: 11px;
                                line-height: 16px;
                                margin-left: 0;
                                margin-right: 16px;
                                text-transform: uppercase;
                            }
                        }
                    }
                    &.horario-especial {
                        .right {
                            flex-direction: row;
                            p {
                                font-size: 11px;
                                line-height: 16px;
                                margin-left: auto;
                                margin-right: 16px;
                                text-transform: uppercase;
                            }
                            .toggle-chevron {
                                align-items: center;
                                display: flex;
                            }
                            .btn-chevron {
                                align-items: center;
                                cursor: pointer;
                                display: flex;
                                margin-left: 5px;
                                margin-right: 10px;
                                transition: all 0.3s;
                                svg {
                                    width: 10px;
                                    height: 7px;
                                }
                            }
                        }
                    }
                    .horarios-especial-dialog {
                        left: -227px;
                        position: absolute;
                        top: 61px;
                        width: 903px;
                        .horarios {
                            display: flex;
                            flex-wrap: wrap;
                            margin-top: 10px;
                            height: 0px;
                            transition: height .3s;
                            width: 100%;
                            .horarios-days-columns-row {
                                background-color: $white;
                                display: flex;
                                justify-content: space-between;
                                padding: 10px 0px;
                                width: 100%;
                                .horario-day-column {
                                    display: flex;
                                    flex-direction: column;
                                    width: calc( (100% - 60px) / 7 );
                                    .horario-column-header {
                                        align-items: center;
                                        background-color: $gris_flojo;
                                        border-radius: 5px;
                                        color: $gris_medio;
                                        display: flex;
                                        font-size: 15px;
                                        height: 40px;
                                        justify-content: center;
                                        width: 100%;
                                    }
                                    .horario-item {
                                        align-items: center;
                                        border: 1px solid $gris_flojo;
                                        border-radius: 5px;
                                        color: $gris_medio;
                                        cursor: pointer;
                                        display: flex;
                                        justify-content: center;
                                        height: 40px;
                                        margin-top: 6px;
                                        position: relative;
                                        width: 100%;
                                        .horario-delete {
                                            cursor: pointer;
                                            position: absolute;
                                            right: -7px;
                                            top: -5px;
                                        }
                                        .doble-hora-input {
                                            height: 100%;
                                            width: 100%;
                                            .horario-label {
                                                align-items: center;
                                                display: flex;
                                                justify-content: center;
                                                height: 100%;
                                                width: 100%;
                                            }
                                            .selector-wrapper {
                                                top: 43px;
                                            }
                                        }
                                    }
                                }
                            }
                            .horario {
                                display: flex;
                                flex-direction: column;
                                width: 175px;
                                .dias {
                                    display: flex;
                                    font-family: $archivo_bold;
                                    font-size: 16px;
                                    /* height: 34px; */
                                    overflow: hidden;
                                    width: calc(100% - 10px);
                                    .text {
                                        display: flex;
                                        flex-wrap: wrap;
                                        .separador {
                                            margin: 0px 3px;
                                            &.coma {
                                                margin: 0px 3px 0px 0px;
                                            }
                                        }
                                    }
                                    .chevron {
                                        position: relative;
                                        margin-left: 5px;
                                        top: -2px;
                                    }
                                }
                                .horas {
                                    margin-top: 8px;
                                    margin-bottom: 18px;
                                    .hora {
                                        align-items: center;
                                        display: flex;
                                        font-family: $archivo_medium;
                                        font-size: 14px;
                                        margin-bottom: 2px;
                                        .hora-input {
                                            position: relative;
                                            .h-input {
                                                align-items: center;
                                                display: flex;
                                                font-family: $archivo_bold;
                                                font-size: 14px;
                                                height: 30px;
                                                justify-content: flex-start;
                                                width: 70px;
                                                &.active {
                                                    border: 2px solid var(--colorPrincipal);
                                                }
                                            }
                                            .selector-wrapper {
                                                background-color: $white;
                                                box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.15);
                                                display: none;
                                                padding: 10px;
                                                position: absolute;
                                                top: 32px;
                                                .selectors {
                                                    align-items: center;
                                                    display: flex;
                                                    .selector {
                                                        width: 50px;
                                                        .selector-value {
                                                            font-family: $archivo_bold;
                                                            font-size: 20px;
                                                            padding: 10px;
                                                            text-align: center;
                                                            width: 100%;
                                                        }
                                                        .btn {
                                                            display: flex;
                                                            justify-content: center;
                                                            padding: 10px 0px;
                                                            &.btn-add {
                                                                transform: rotate(180deg);
                                                            }
                                                        }
                                                    }
                                                    .separator {
                                                        font-family: $archivo_bold;
                                                        font-size: 20px;
                                                        position: relative;
                                                        top: -1px;
                                                    }
                                                }
                                                & > button {
                                                    background-color: var(--colorPrincipal);
                                                    border: none;
                                                    color: $white;
                                                    font-family: $archivo_medium;
                                                    font-size: 14px;
                                                    margin-top: 10px;
                                                    padding: 5px 0px;
                                                    width: 100%;
                                                }
                                                &.active {
                                                    display: block;
                                                    z-index: 5;
                                                }
                                            }
                                        }
                                        .sep-a {
                                            margin: 0px 9px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .descuento-selector {
                    .promocion-selector {
                        margin-bottom: 6px;
                        width: 100%;
                        .select-main {
                            align-items: center;
                            cursor: pointer;
                            display: flex;
                            justify-content: space-between;
                            padding-right: 10px;
                            width: 100%;
                            p {
                                font-size: 18px;
                            }
                            .btn-chevron {
                                transition: all 0.3s;
                            }
                        }
                        .select-options {
                            background-color: var(--color6);
                            border: 1px solid var(--colorPrincipal);
                            border-radius: 10px;
                            font-size: 14px;
                            list-style: none;
                            max-height: 191px;
                            overflow: auto;
                            padding: 0px;
                            position: absolute;
                            right: 0px;
                            top: 79px;
                            width: 318px;
                            z-index: 2;
                            &.on-left {
                                left: 0;
                                right: auto;
                            }
                            &.opt-servicios {
                                width: 320px;
                            }
                            &.calendario {
                                background-color: var(--colorSecundario);
                                border: none;
                                width: 245px;
                            }
                            li {
                                align-items: center;
                                border-bottom: 1px solid var(--colorPrincipal);
                                color: var(--colorPrincipal);
                                cursor: pointer;
                                display: flex;
                                padding: 12px 20px;
                                &:last-of-type {
                                    border-bottom: none;
                                }
                                &.with-calendar {
                                    padding: 0px;
                                }
                                .checkbox-bg {
                                    border: 1px solid var(--colorPrincipal);
                                    height: 16px;
                                    margin-right: 10px;
                                    position: relative;
                                    width: 16px;
                                    min-width: 16px;
                                    .checkbox-item {
                                        border-bottom: 2px solid var(--colorPrincipal);
                                        border-left: 2px solid var(--colorPrincipal);
                                        height: 5px;
                                        left: 3px;
                                        position: absolute;
                                        top: 4px;
                                        transform: rotate(-45deg);
                                        width: 8px;
                                    }
                                }
                            }
                        }
                    }
                    &.left {
                        .select-options {
                            left: 0px;
                            right: auto;
                        }
                    }
                    &.top {
                        .select-options {
                            bottom: 80px;
                            top: auto;
                        }
                        .horarios-especial-dialog {
                            bottom: 80;
                            left: -227px;
                            top: auto;
                            z-index: 10;
                        }
                    }
                    &.sel-caducidad,
                    &.sel-birthdate {
                        .select-options {
                            max-height: none;
                        }
                    }

                    .descuento-select-date {
                        .nunca {
                            align-items: center;
                            background-color: #F8F8F8;
                            display: flex;
                            justify-content: center;
                            padding: 7px 0px;
                            .nunca-check {
                                display: flex;
                            }
                        }
                    }

                    .react-calendar {
                        .react-calendar__navigation {
                            padding: 10px 0px;
                        }
                        .react-calendar__navigation button {
                            background: none;
                            border: none;
                            font-size: 20px;
                        }
                        .react-calendar__navigation__label span {
                            font-family: "Archivo-Bold" !important;
                            font-size: 14px;
                        }
                        .react-calendar__month-view__weekdays__weekday {
                            font-size: 12px;
                            padding-bottom: 5px;
                        }
                        .react-calendar__tile {
                            padding: 5px 5px !important;
                            margin: 0px;
                        }
                        .react-calendar__tile--active {
                            background: none;
                        }
                        .react-calendar__tile abbr {
                            font-size: 11px;
                        }
                        .react-calendar__tile--active abbr {
                            background-color: var(--colorPrincipal);
                            border-radius: 100px;
                            color: $white;
                            padding: 3px 5px;
                        }
                    }
                }
            }
        }
    }
}