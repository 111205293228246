.modal-gestion {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 100;
    display: none;
    justify-content: center;
    align-items: center;
    .modal-bg {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.9);
    }
    .modal-wrapper {
        position: relative;
        width: 600px;
        background-color: $white;
        min-height: 100px;
        padding: 47px 81px; 
        .modal-title {
            h3 {
                font-family: $archivo_bold;
                font-size: 23px;
                color: $azul_flojo;
            }
        }
        .modal-body {
            margin-top: 26px;
            color: $modal_texto;
            p {
                font-family: $archivo_medium;
                font-size: 18px;
                line-height: 27px;
            }
        }
        .modal-footer {
            .buttons {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-top: 51px;
                .btn {
                    font-family: $archivo_bold;
                    font-size: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 154px;
                    height: 45px;
                    margin-right: 25px;
                    cursor: pointer;
                    &.btn-confirm {
                        color: $white;
                        background-color: var(--colorPrincipal);
                    }
                    &.btn-cancel {
                        color: $black;
                        background-color: #F2F2F2;
                    }
                }
            }
        }
        .modal-close {
            position: absolute;
            top: 0px;
            right: -53px;
            width: 53px;
            height: 54px;
            border: 3px solid $white;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            span {
                display: inline-block;
                width: 17px;
                height: 16px;
                background-image: url('../../assets/img/sprite.png');
                background-position: -64px -419px;
            }
        }
    }
    &.active {
        display: flex;
    }
}