.gestion {
    position: relative;
    padding-top: 80px;
    background-color: $white;
    width: 100%;
    min-height: 100vh;
    display: flex;
    font-family: $archivo_regular;
    overflow: hidden;
    
    .filters {
        position: relative;
        box-sizing: border-box;
        width: 61px;
        padding: 33px 0px 0px;
        flex-shrink: 0;
        background-color: $gestion_bg_filters;
        box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.15);
        transition: transform 0.3s;
        &.hide {
            width: 0px !important;
            overflow: hidden;
        }
        @include lg(){
            padding: 33px 0px 0px 0px;
            width: 196px;
            transition: width .3s;
        }
        .btn-minimize {
            position: absolute;
            right: -11px;
            bottom: 33px;
            z-index: 3;
            cursor: pointer;
            width: 23px;
            height: 23px;
            border-radius: 50%;
            background-color: $azul_flojo;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 1px;
            svg {
                fill: transparent;
                stroke: $white;
                stroke-width: 2px;
                transform:rotate(270deg);
                -ms-transform:rotate(270deg);
                -webkit-transform:rotate(270deg);
                transition: transform 0.3s;
                -webkit-transition: transform 0.3s;
            }
            
        }
        .filters-header {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $archivo_bold;
            font-size: 15px;
            padding: 0px 20px 0px 25px;
            @include lg(){
                justify-content: space-between;
            }
            h3 {
                display: none;
                @include lg(){
                    display: block;
                }
            }
            span {
                display: inline-block;
                width: 22px;
                height: 16px;
                /* background-image: url('../../assets/img/sprite.png');
                background-position: -111px -420px; */
                cursor: pointer;
                margin-top: 2px;
                @include lg(){
                    margin-top: 0px;
                }
            }
        }
        .filters-list {
            margin-top: 29px;
            max-height: calc( 100vh - 172px );
            overflow: auto;
            display: none;
            @include lg(){
                display: block;
            }
            .filters-section {
                .section-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 23px 20px 23px 25px;
                    background-color: $white;
                    cursor: pointer;
                    h2 {
                        font-family: $archivo_bold;
                        font-size: 15px;
                        text-transform: uppercase;
                        color: $azul_flojo;
                    }
                    svg {
                        path {
                            fill: transparent;
                            stroke-width: 2px;
                        }
                        transform:rotate(180deg);
                        -ms-transform:rotate(180deg);
                        -webkit-transform:rotate(180deg);
                        transition: transform 0.3s;
                        -webkit-transition: transform 0.3s;
                    }
                }
                .filter-options {
                    overflow: hidden;
                    transition: height 0.3s;
                    -webkit-transition: height 0.3s;
                    padding: 20px 0px;
                    background-color: #EDF4FD;
                    .filter {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-family: $archivo_regular;
                        background-color: #EDF4FD;
                        padding: 10px 20px 10px 25px;
                        label {
                            font-size: 14px;
                        }
                        .indicator {
                            display: inline-block;
                            height: 8px;
                            width: 8px;
                            border-radius: 50%;
                        }
                        &.deleted {
                            display: none;
                        }
                        .checkmark {
                            border: none;
                            background-color: $white;
                            &::after {
                                left: 5px;
                                border-color: var(--colorPrincipal);
                            }
                        }
                        .checkmark-bg {
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 16px;
                            width: 16px;
                            border: none;
                            background-color: #fff;
                            .checkmark-item {
                                position: absolute;
                                left: 5px;
                                top: 2px;
                                width: 6px;
                                height: 10px;
                                border: solid #797777;
                                border-width: 0 2px 2px 0;
                                transform: rotate(45deg);
                                border-color: #535AF1;
                            }
                        }
                    }
                }
                &.opened {
                    .section-header {
                        svg {
                            transform:rotate(0);
                            -ms-transform:rotate(0);
                            -webkit-transform:rotate(0);
                        }
                        img {
                            transform:rotate(180deg);
                            -ms-transform:rotate(180deg);
                            -webkit-transform:rotate(180deg);
                        }
                    }
                    .filter-options {
                        height: auto;
                    }
                }
            }
        }
        &.mini {
            width: 61px;
            .btn-minimize {
                padding-left: 1px;
                svg {
                    transform:rotate(90deg);
                    -ms-transform:rotate(90deg);
                    -webkit-transform:rotate(90deg);
                }
                span {
                    background-position: -34px -420px;
                }
            }
            .filters-header {
                justify-content: center;
                padding: 0px 16px 0px 17px;
                h3 {
                    display: none;
                }
                span {
                    margin-top: 2px;
                    
                }
            }
            .filters-list {
                display: none;
            }
        }
    }
    .calendar {
        position: relative;
        overflow: auto;
        @include lg(){
            overflow: hidden;
            width: 100%;
        }
        .loading-citas {
            align-items: center;
            display: flex;
            height: 100%;
            justify-content: center;
            left: 0px;
            opacity: 1;
            position: absolute;
            top: 0px;
            width: 100%;
            z-index: 5;
            .bg-loading {
                align-items: center;
                background-color: $gris_flojo;
                display: flex;
                height: 100%;
                justify-content: center;
                left: 0px;
                opacity: 0.5;
                position: absolute;
                top: 0px;
                width: 100%;
            }
            p {
                font-family: $archivo_bold;
                font-size: 35px;
                z-index: 6;
            }
        }
    }
    .fondo-no-click {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100vh;
        background-color: rgba(255,255,255, 0.8);
        filter: blur(8px);
        -webkit-filter: blur(8px);
        z-index: 3;
        transform: translate(-100%, 0px);
        transition: all 0.1s;
        &.active {
            transform: translate( 0px, 0px);
        }
    }
}



.ylm-select {
    display: flex;
    flex-direction: column;
    .ylm-select-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}