.homepage {

    h4 {
        font-family: $archivo_bold;
        font-size: 17px;
        color: $gold;
        text-transform: uppercase;
        letter-spacing: 5px;
        padding-bottom: 48px;
        text-align: center;
        @include lg(){
            font-size: 16px;
            padding-bottom: 34px;
        }
    }
    .ylm-slider {
        position: relative;
        width: 100%;
        height: 100vh;
        .slogan-container {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            h2 {
                width: 350px;
                text-align: center;
                font-size: 45px;
                color: $white;
                @include lg(){
                    width: 650px;
                    font-size: 63px;
                }
            }
        }
        .holidays {
            background-image: url('../../assets/img/slider-6-mb.jpg') !important;
            h2 {
                width: 310px;
                font-size: 42px;
            }
            h4 {
                color: $white;
                font-family: gastromond,sans-serif;
                font-size: 22px;
                text-transform: initial;
                margin-top: 17px;
                letter-spacing: 1px;
                padding-bottom: 0px;
                .xs {
                    @include lg(){
                        display: none;
                    }
                } 
                .lg {
                    display: none;
                    @include lg(){
                        display: inline-block;
                    }
                }
            }
            @include lg(){
                background-image: url('../../assets/img/slider-6.jpg') !important;
                h2 {
                    width: 900px;
                    font-size: 63px;
                }
                h4 {
                    font-size: 34px;
                    margin-top: 13px;
                }
            }
        }
        .botonera {
            position: absolute;
            bottom: 30px;
            padding: 20px 0px;
            width: 100%;
            text-align: center;
            button {
                margin: 0 auto;
                background: none;
                border: 3px solid $white;
                font-family: $archivo_bold;
                color: $white;
                font-size: 25px;
                padding: 16px 22px;
                &:hover {
                    color: $gold;
                    border-color: $gold;
                }
            }
        }
    }

    .phrase {
        position: relative;
        background-color: $home-beige;
        @include lg(){
            padding: 243px 0px 244px;
        }
        .draw {
            position: absolute;
            width: 204px;
            height: 45px;
            background-image: url('../../assets/img/sprite.png');
            background-position: -3px -314px;
            top: 46px;
            left: 33px;
            @include lg(){
                width: 255px;
                height: 55px;
                top: 72px;
                left: 80px;
                background-position: -255px -70px;
            }
        }
        .text-container {
            width: calcula(375, 303);
            margin-left: calcula(375, 36);
            padding-top: 184px;
            padding-bottom: 127px;
            @include lg(){
                width: 705px;
                margin-left: calcula(1336, 348);
                padding-top: 0px;
                padding-bottom: 0px;
            }
            p {
                font-family: $archivo_semibold;
                font-size: 25px;
                line-height: 32px;
                @include lg(){
                    font-size: 43px;
                    line-height: 52px;
                }
            }
        }
    }
    .servicios {
        padding: 58px 0px 55px;
        @include lg(){
            width: 900px;
            margin-left: calc((100% - 900px) / 2);
            padding: 128px 0px 147px;
        }
        @include xl(){
            width: 1114px;
            margin-left: calc((100% - 1114px) / 2);
        }
        h4 {
            margin-left: 43px;
            padding-bottom: 51px;
            @include lg(){
                padding-bottom: 80px;
            }
        }
    }
    .contacto {     
        position: relative;   
        background-color: $home-black;
        padding-bottom: 83px;
        padding-top: 194px;
        @include lg(){
            padding-top: 0px;
        }
        .draw {
            position: absolute;
            width: 202px;
            height: 50px;
            top: 59px;
            right: 34px;
            background-image: url('../../assets/img/sprite.png');
            background-position: -229px -313px;
            @include lg(){
                width: 255px;
                height: 56px;
                top: 86px;
                right: 111px;
                background-position: -4px -183px;
            }
        }
        .contacto-container {
            display: flex;
            flex-direction: column;
            margin-left: calc((100% - 303px) / 2);
            @include lg(){
                flex-direction: row;
                margin-left: calc((100% - 900px) / 2);
                padding: 160px 0px 0px;
                width: 900px;
            }
            @include xl(){
                flex-direction: row;
                margin-left: calc((100% - 1032px) / 2);
                padding: 160px 0px 0px;
                width: 1032px;
            }
            .imagen {
                width: 303px;
                height: 386px;
                background-image: url('../../assets/img/contacto.jpg');
                background-size: cover;
                background-position: center;
                @include lg(){
                    width: 434px;
                    height: 610px;
                }
            }
            .text-container {
                width: 313px;
                padding-top: 105px;
                @include lg(){
                    width: 605px;
                    padding-left: 164px;
                    padding-top: 128px;
                }
                h4 {
                    padding-bottom: 43px; 
                    @include lg(){
                        padding-bottom: 36px; 
                    }
                }
                h3 {
                    font-family: $archivo_semibold;
                    font-size: 33px;
                    line-height: 40px;
                    color: $white;
                    @include lg(){
                        font-size: 43px;
                        line-height: 50px;
                        padding-right: 8px;
                    }
                }
                .horarios {
                    color: $gold;
                    font-family: $archivo_semibold;
                    font-size: 20px;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    padding-top: 73px;
                    @include lg(){
                        padding-top: 33px;
                    }
                    p {
                        display: flex;
                        flex-direction: column;
                        line-height: 34px;
                        margin-bottom: 27px;
                        @include lg(){
                            display: block;
                            line-height: 37px;
                            margin-bottom: 0px;
                        }
                    }
                    .horas {
                        font-size: 25px;
                    }
                }
            }
            &.adresses {
                padding-top: 61px;
                justify-content: space-between;
                @include lg(){
                    padding-top: 115px;
                }
                h4 {
                    padding-bottom: 29px; 
                }
                .adresses-left {
                    display: flex;
                    flex-direction: column;
                    @include lg(){
                        flex-direction: row;
                    }
                    .adress {
                        margin-bottom: 56px;
                        @include lg(){
                            margin-bottom: 0px;
                            width: 260px;
                        }
                        @include xl(){
                            width: 290px;
                        }
                        p {
                            font-family: $archivo_semibold;
                            color: $white;
                            font-size: 33px;
                            line-height: 40px;
                            max-width: 260px;
                            @include lg(){
                                font-size: 43px;
                                line-height: 52px;
                                max-width: auto;
                            }
                        }
                        &:first-of-type {
                            @include lg(){
                                margin-right: 83px;
                            }
                            @include xl(){
                                margin-right: 96px;
                            }
                        }
                    }
                }
                .adresses-right {
                    font-family: $archivo_semibold;
                    padding-top: 12px;
                    @include lg(){
                        padding-top: 0px;
                    }
                    a {
                        color: $white;
                        text-decoration: none;
                    }
                    & > a {
                        font-size: 43px;
                        line-height: 52px;
                    }
                    .social {
                        display: flex;
                        align-items: center;
                        a {
                            display: flex;
                            align-items: center;
                            font-size: 20px;
                            line-height: 52px;
                        }
                        span {
                            display: inline-block;
                            width: 30px;
                            height: 30px;
                            background-image: url('../../assets/img/sprite.png');
                        }
                        .facebook {                            
                            background-position: -214px -137px;
                        }
                        .instagram {                            
                            background-position: -246px -137px;
                            margin-right: 12px;
                        }
                    }
                }
            }
        }
    }
}
