header {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 90px;
    color: $white;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 63px;
    @include lg(){
        padding-top: 67px;
        align-items: center;
    }
    @include xxl(){
        padding-top: 112px;
    }
    
    .user-header {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        &.hidden {
            display: none;
        }

        .logo {
            position: relative;
            width: 195px;
            height: 69px;
            // background-image: url('../../assets/img/logo-home.png');
            // background-size: cover;
            // background-position: top center;
            z-index: 10;
            @include lg(){
                height: 90px;
                width: 255px;
            }
        }
    
        .btn-login {
            display: none;
            position: absolute;
            right: 25px;
            width: 33px;
            height: 28px;
            margin-top: 5px;
            background-image: url('../../assets/img/sprite.png');
            background-position: -294px -191px;
            cursor: pointer;
            z-index: 10;
            @include lg(){
                display: block;
                margin-top: 0px;
                top: 25px;
                right: 153px;
                width: 30px;
                height: 28px;
                background-position: -370px -5px;
            }
            @include xl(){
                right: 200px;
            }
            &.logged {
                @include lg(){
                    top: 23px;
                    background-position: -407px -5px;
                }
            }
            &:hover {
                background-position: -294px -191px;
                @include lg(){
                    background-position: -514px -5px;
                }
                &.logged {
                    background-position: -294px -191px;
                    @include lg(){
                        background-position: -549px -5px;
                    }
                }
            }
        }
    
        .btn-menu {
            position: absolute;
            right: 25px;
            width: 33px;
            height: 28px;
            margin-top: 5px;
            background-image: url('../../assets/img/sprite.png');
            background-position: -294px -191px;
            cursor: pointer;
            z-index: 10;
            @include lg(){
                margin-top: 0px;
                top: 23px;
                right: 60px;
                width: 50px;
                height: 28px;
                background-position: -6px -140px;
            }
            @include xl(){
                right: 107px;
            }
            &:hover {
                background-position: -294px -191px;
                @include lg(){
                    background-position: -79px -140px;
                }
            }
            
        }
    }
    &.dark {
        color: $black;
        .logo {
            //background-image: url('../../assets/img/logo.png');
        }
        .btn-login {
            background-position: -294px -191px;
            @include lg(){
                background-position: -444px -5px;
            }
            &.logged {
                background-position: -479px -5px;
            }
            &:hover {
                background-position: -294px -191px;
                @include lg(){
                    background-position: -514px -5px;
                }
                &.logged {
                    background-position: -294px -191px;
                    @include lg(){
                        background-position: -549px -5px;
                    }
                }
            }
        }
        .btn-menu {
            background-position: -376px -190px;
            @include lg(){
                background-position: -144px -140px;
            }            
            &:hover {
                background-position: -376px -190px;
                @include lg(){
                    background-position: -79px -140px;
                }
            }
        }
    }
    .menu {
        position: absolute;
        opacity: 0;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 0px;
        background-color: $home-black;
        transition: opacity 0.3s;
        overflow: hidden;
        .menu-body-container {
            position: relative;
            margin-top: 180px;
            height: calc( 100vh - 200px );
            overflow: auto;
        }
        .menu-body {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            min-height: 467px;
            @include lg(){
                padding-top: 20px;
                padding-bottom: 20px;
                margin-top: 0px;
                height: calc( 100vh - 270px );
            }
            @include xxl(){
                margin-top: 70px;
                height: calc( 100vh - 270px );
            }
            .separador {
                @include lg(){
                    padding-bottom: 36px;
                }
                &:last-of-type {
                    
                    @include lg(){
                        padding-top: 50px;
                        padding-bottom: 0px;
                    }
                }
                span {
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    background-image: url('../../assets/img/sprite.png');
                    background-position: -391px -139px;
                }
            }
            a {
                position: relative;
                font-family: $archivo_regular;
                font-size: 25px;
                text-transform: uppercase;
                text-decoration: none;
                color: $white;
                letter-spacing: 3px;
                @include lg(){
                    font-size: 30px;
                    margin: 33px 0px;
                }
                &:hover {
                    font-family: $archivo_bold;
                    color: $gold;
                    &::after {
                        position: absolute;
                        left: 0px;
                        bottom: -2px;
                        content: '';
                        width: 100%;
                        height: 3px;
                        background-color: $gold;
                    }
                }
            }
            .mobile-login {
                @include lg(){
                    display: none;
                }
                span {
                    display: inline-block;
                    width: 36px;
                    height: 36px;
                    background-image: url('../../assets/img/sprite.png');
                    background-position: -270px -5px;
                }
                &.logged {
                    span {
                        background-position: -323px -3px;
                    }
                }
            }
        }
    }
    &.opened {
        position: fixed;
        .logo {
            //background-image: url('../../assets/img/logo-dark.png');
        }
        .menu {
            height: 100vh;
            opacity: 1;
        }
        .btn-login {
            background-position: -294px -191px;
            @include lg(){
                background-position: -370px -5px;
            }
            &:hover {
                background-position: -294px -191px;
                @include lg(){
                    background-position: -514px -5px;
                }
                &.logged {
                    background-position: -294px -191px;
                    @include lg(){
                        background-position: -549px -5px;
                    }
                }
            }
        }
        
        .btn-menu {
            width: 31px;
            height: 32px;
            background-position: -431px -186px;
            @include lg(){
                height: 32px;
                background-position: -293px -138px;
            }
            &:hover {
                background-position: -431px -186px;
                @include lg(){
                    background-position: -342px -138px;
                }
            }
        }
    }
    &.home {
        padding-top: 54px;
        @include lg(){
            padding-top: 67px;
            align-items: center;
        }
        @include xxl(){
            padding-top: 112px;
        }
    }
    &.admin {
        padding-top: 0px;
        height: 73px;
        background-color: var(--colorPrincipal);

        .admin-header {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left {
                .logo {
                    width: 204px;
                    height: 25px;
                    margin-left: 22px;
                    display: block;
                    position: relative;
                    // background-image: url('../../assets/img/logo_admin.png');
                    // background-size: cover;
                    // background-position: top center;
                    z-index: 10;
                    @include lg(){
                        height: 27px;
                        width: 223px;
                    }
                }
            }
        }
        .right {
            .btn-logout {
                cursor: pointer;
                margin-right: 35px;
                span {
                    display: inline-block;
                    width: 17px;
                    height: 17px;
                    background-image: url('../../assets/img/sprite.png');
                    background-position: -65px -380px;
                }
            }
        }
    }
}