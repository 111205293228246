.notificaciones {
    flex-shrink: 0;
    width: calc(100% - 66px);
    padding-left: 33px;
    padding-right: 33px;
    .preheader {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 28px;
        .title {
            display: flex;
            align-items: center;
            span {
                display: inline-block;
                width: 25px;
                height: 25px;
                background-image: url('../../assets/img/sprite.png');
                background-position: -147px -380px;
                margin-right: 10px;
            }
            h1 {
                font-family: $archivo_bold;
                font-size: 28px;
                color: var(--colorPrincipal);
            }
        }
        .botonera {
            display: flex;
            justify-content: flex-end;
            flex: 1;
            & > div {
                margin-left: 8px;
            }
            .btn-add {
                width: 35px;
                height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $azul2;
                font-family: $archivo_bold;
                font-size: 21px;
                cursor: pointer;
            }

            .btn-search {
                align-items: center;
                background-color: $azul2;
                cursor: pointer;
                display: flex;
                font-family: $archivo_bold;
                font-size: 21px;
                height: 36px;
                justify-content: center;
                position: relative;
                width: 35px;
                svg {
                    g {
                        fill: transparent;
                    }
                    line {
                        stroke-width: 2px;
                    }
                }
                .search-input {
                    display: none;
                    position: relative;
                    input {
                        background: none;
                        border: none;
                        height: 100%;
                        width: 250px;
                        padding-left: 8px;
                        &::placeholder {
                            color: $black;
                        }
                    }
                    span {
                        font-family: $archivo_semibold;
                        font-size: 12px;
                        position: absolute;
                        right: 15px;
                        top: 0px;
                    }
                }
                &.active {
                    display: flex;
                    justify-content: flex-end;
                    width: auto;
                    padding-right: 8px;
                    .search-input {
                        display: flex;
                    }
                }
            }
        }
    }
    .table-notificaciones {
        margin-top: 47px;
        .col-nombre {
            flex: 0 0 205px;
        }
        .col-mensaje {
            flex: 1;
            // max-width: 500px;
            overflow: hidden;
        }
        .col-list {
            display: flex;
            flex: 0 0 186px;
            justify-content: center;
            margin-left: 10px;
            p {
                font-family: $archivo_bold;
                margin-left: 3px;
            }
        }
        .col-btn {
            flex: 0 0 40px;
            .btn-menu {
                cursor: pointer;
                flex-shrink: 0;
                position: relative;
                & > span {
                    align-items: center;
                    display: inline-flex;
                    height: 19px;
                    justify-content: center;
                    margin-top: -12px;
                    width: 35px;
                }
                .submenu {
                    position: absolute;
                    display: none;
                    opacity: 0;
                    background-color: $white;
                    border: 1px solid $calendar-border-gray;
                    box-shadow: 0px 2px 10px #cccccc;
                    top: 10px;
                    right: 30px;
                    list-style: none;
                    width: 140px;
                    padding: 0px 20px;
                    transition: opacity 0.3s;
                    &.active {
                        display: block;
                        opacity: 1;
                    }
                    li {
                        display: flex;
                        align-items: center;
                        height: 40px;
                        border-bottom: 1px solid $calendar-border-gray;
                        cursor: pointer;
                        &:last-of-type {
                            border: none;
                        }
                        &:hover {
                            color: var(--colorPrincipal);
                        }
                    }
                }
                &.active {
                    .submenu {
                        display: block;
                        opacity: 1;
                    }
                }
            }
        }
        .table-headers {
            border-bottom: 1px solid var(--gris2);
            display: flex;
            font-family: $archivo_bold;
            padding: 0px 0px 5px 10px;
        }
        .table-body {
            .notificacion-row {
                border-bottom: 1px solid var(--gris2);
                cursor: pointer;
                display: flex;
                padding: 12px 0px 12px 10px;
            }
        }
    }
}

.notificacion {
    width: 100%;
    .notificacion-wrapper {
        width: 963px;
        .header {
            display: flex;
            justify-content: space-between;
            min-height: 36px;
            width: 100%;
            .nav-back {
                align-items: center;
                color: $azul3;
                cursor: pointer;
                display: flex;
                font-family: $archivo_bold;
                font-size: 20px;
                p {
                    margin-left: 9px;
                }
            }

            .botonera {
                align-items: center;
                display: flex;
                .btn-mini {
                    background: none;
                    border: none;
                    height: 36px;
                    margin-left: 10px;
                    width: 36px;
                    &.btn-editar {
                        svg {
                            height: 16px;
                            width: 17px;
                        }
                    }
                    &.btn-eliminar {
                        svg {
                            height: 17px;
                            width: 17px;
                        }
                    }
                }
                .btn {
                    background: none;
                    background-color: $gestion_bg_menu;
                    border: none;
                    color: var(--colorPrincipal);
                    font-family: $archivo_bold;
                    font-size: 16px;
                    height: 36px;
                    margin-left: 10px;
                    width: 120px;
                    &.btn-crear {
                        background-color: var(--colorPrincipal);
                        color: $gestion_bg_menu;
                    }
                    &:hover {
                        opacity: 0.3;
                    }
                }
                .btn:active {
                    background-color: #EDD8BB; 
                }
            }
        }
        .notificacion-body {
            margin-top: 18px;
            h1 {
                font-family: $archivo_bold;
                font-size: 26px;
            }
            & > input {
                border: none;
                font-family: $archivo_bold;
                font-size: 26px;
            }
            .noti-main {
                h3 {
                    color: var(--colorSecundario);
                    font-size: 16px;
                }
                textarea {
                    background-color: var(--gris3);
                    border: none;
                    border-radius: 10px;
                    font-family: $archivo_regular;
                    min-height: 83px;
                    padding: 10px 18px;
                    resize: none;
                    width: 100%;
                    &::placeholder {
                        color: $gris_fuerte;;
                        font-style: italic;
                    }
                }
                .mensaje-container {
                    margin-top: 25px;
                    textarea {
                        margin-top: 8px;
                    }
                }
            }
            .noti-selectors {
                display: flex;
                flex-wrap: wrap;
                gap: 7px;
                .noti-selector {
                    background-color: var(--gris3);
                    border-radius: 10px;
                    display: flex;
                    height: 71px;
                    padding: 8px;
                    width: 220px;
                    .left {
                        .icon-bg {
                            align-items: center;
                            background-color: $white;
                            border-radius: 10px;
                            display: flex;
                            height: 54px;
                            justify-content: center;
                            width: 54px;
                        }
                    }
                    .right {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        flex: 1 1 auto;
                        margin-left: 15px;
                        .title {
                            font-size: 11px;
                            text-transform: uppercase;
                        }
                        .ylm-toggle {
                            p {
                                color: var(--gris1);
                            }
                            .toggle-bg {
                                background-color: var(--gris4);
                                .toggle-item {
                                    background-color: var(--gris5);
                                }
                            }
                            &.active {
                                p {
                                    color: var(--colorPrincipal);
                                }
                                .toggle-bg {
                                    background-color: var(--white);
                                    .toggle-item {
                                        background-color: var(--colorPrincipal);
                                    }
                                }
                            }
                        }
                    }

                    &.pauses {
                        .right {
                            flex-direction: row;
                            .right-block {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                margin-right: 10px;
                                flex: 1 1 auto;
                                &:nth-child(2) {
                                    margin-right: 0px;
                                }
                                .values {
                                    align-items: center;
                                    cursor: pointer;
                                    display: flex;
                                    justify-content: space-between;
                                    margin-bottom: 6px;
                                    width: 100%;
                                    p {
                                        font-size: 20px;
                                    }
                                    svg {
                                        margin-right: 8px;
                                    }
                                }
                                .block-input {
                                    display: flex;
                                    justify-content: flex-start;
                                    input {
                                        background-color: transparent;
                                        border: none;
                                        font-size: 20px;
                                        padding: 5px 10px 2px;
                                        text-align: left;
                                        width: calc( 100% - 8px);
                                    }

                                }
                            }
                        }
                    }
                    &.segmentar {
                        .right {
                            flex-direction: row;
                            p {
                                font-size: 11px;
                                line-height: 16px;
                                margin-left: auto;
                                margin-right: 5px;
                                text-transform: uppercase;
                                width: 90px;
                            }
                        }
                    }
                    &.destacar {
                        .right {
                            flex-direction: row;
                            p {
                                font-size: 11px;
                                line-height: 16px;
                                margin-left: auto;
                                margin-right: 5px;
                                text-transform: uppercase;
                                width: 90px;
                            }
                        }
                    }
                    &.enviar {
                        .right {
                            flex-direction: row;
                            p {
                                font-size: 11px;
                                line-height: 16px;
                                margin-left: auto;
                                margin-right: 5px;
                                text-transform: uppercase;
                                width: 90px;
                            }
                        }
                    }
                    &.active {
                        background-color: var(--colorSecundario);
                    }
                }
                .segmentar-row {
                    display: flex;
                    height: 71px;
                    margin-left: -8px;
                    overflow: hidden;
                    position: relative;
                    transition: width .3s;
                    width: 0px;
                    &.active {
                        margin-left: 0px;
                        overflow: visible;
                        width: calc(100% - 227px);
                    }
                    .row-selector {
                        border-radius: 10px;
                        display: flex;
                        height: 71px;
                        margin-right: 8px;
                        padding: 14px 4px 10px 8px;
                        width: 175px;
                        &:last-of-type {
                            margin-right: 0px;
                        }
                        .right {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            flex: 1 1 auto;
                            margin-left: 12px;
                            .title {
                                font-size: 11px;
                                text-transform: uppercase;
                            }
                            .ylm-difusion-select {
                                margin-bottom: 6px;
                                position: relative;
                                width: 100%;
                                .select-main {
                                    align-items: center;
                                    cursor: pointer;
                                    display: flex;
                                    justify-content: space-between;
                                    padding-right: 10px;
                                    width: 100%;
                                    p {
                                        font-size: 18px;
                                    }
                                }
                                .select-options {
                                    background-color: var(--white);
                                    border: 1px solid var(--colorPrincipal);
                                    border-radius: 10px;
                                    list-style: none;
                                    padding: 0px 0px;
                                    position: absolute;
                                    left: -19px;
                                    right: auto;
                                    top: 40px;
                                    width: 245px;
                                    z-index: 2;
                                    &.right {
                                        left: auto;
                                        right: -10px;
                                    }
                                    li {
                                        align-items: center;
                                        border-bottom: 1px solid var(--colorPrincipal);
                                        color: var(--colorPrincipal);
                                        cursor: pointer;
                                        display: flex;
                                        padding: 14px 12px 14px 19px;
                                        &:last-of-type {
                                            border-bottom: none;
                                        }
                                        .checkbox-bg {
                                            border: 1px solid $gris_oscuro;
                                            height: 16px;
                                            margin-right: 10px;
                                            position: relative;
                                            width: 16px;
                                            .checkbox-item {
                                                border-bottom: 2px solid $gris_oscuro;
                                                border-left: 2px solid $gris_oscuro;
                                                height: 6px;
                                                left: 2px;
                                                position: absolute;
                                                top: 3px;
                                                transform: rotate(-45deg);
                                                width: 10px;
                                            }
                                        }
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }
        .single-notificacion {
            padding: 44px 30px 21px;
        }
    }
}