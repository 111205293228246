@font-face {
    font-family: 'FuturaStd-Book';
    src: url('../../assets/fonts/FuturaStd-Book.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/FuturaStd-Book.otf') format('opentype'), url('../../assets/fonts/FuturaStd-Book.woff') format('woff'), url('../../assets/fonts/FuturaStd-Book.ttf') format('truetype'), url('../../assets/fonts/FuturaStd-Book.svg#FuturaStd-Book') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Archivo-Bold';
    src: url('../../assets/fonts/Archivo-Bold.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Archivo-Bold.otf') format('opentype'), url('../../assets/fonts/Archivo-Bold.woff') format('woff'), url('../../assets/fonts/Archivo-Bold.ttf') format('truetype'), url('../../assets/fonts/Archivo-Bold.svg#Archivo-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Archivo-Medium';
    src: url('../../assets/fonts/Archivo-Medium.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Archivo-Medium.otf') format('opentype'), url('../../assets/fonts/Archivo-Medium.woff') format('woff'), url('../../assets/fonts/Archivo-Medium.ttf') format('truetype'), url('../../assets/fonts/Archivo-Medium.svg#Archivo-Medium') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Archivo-Regular';
    src: url('../../assets/fonts/Archivo-Regular.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Archivo-Regular.otf') format('opentype'), url('../../assets/fonts/Archivo-Regular.woff') format('woff'), url('../../assets/fonts/Archivo-Regular.ttf') format('truetype'), url('../../assets/fonts/Archivo-Regular.svg#Archivo-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Archivo-SemiBold';
    src: url('../../assets/fonts/Archivo-SemiBold.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Archivo-SemiBold.otf') format('opentype'), url('../../assets/fonts/Archivo-SemiBold.woff') format('woff'), url('../../assets/fonts/Archivo-SemiBold.ttf') format('truetype'), url('../../assets/fonts/Archivo-SemiBold.svg#Archivo-SemiBold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght@9..144,500;9..144,600;9..144,700&family=Poppins:wght@500;600;700&family=Work+Sans:wght@500;600;700&display=swap');

// Fuentes 
$futura_book: 'FuturaStd-Book';
$archivo_bold: 'Archivo-Bold';
$archivo_medium: 'Archivo-Medium';
$archivo_regular: 'Archivo-Regular';
$archivo_semibold: 'Archivo-SemiBold';

.gastromond {
    font-family: gastromond,sans-serif;
    font-weight: 400;
    font-style: normal;
}

.poppins { font-family: 'Poppins', serif; }
.fraunces { font-family: 'Fraunces', serif; }
.worksans { font-family: 'Work Sans', sans-serif; }
