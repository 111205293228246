
// Colors
$primary: #1da3e1;
$gold: #a98e65;
$dark-grey: #363636;
$medium-grey: #505050;
$light-grey: #797777;
$grey2: #B9B5B5;
$grey3: #F7F7F7;

$background: #fff;

$azul_fuerte: #535AF1;
$azul_fuerte_50: rgba(83, 90, 241, 0.5);
$azul_fuerte_trans: rgba(83, 90, 241, 0.9);
$azul_flojo: #94BDF2;
$azul_extraflojo: #EFF5FD;
$azul_comentario: #B1C1D6;
$azul_select: #EDF4FD;
$azul_borde_select: #D9EAFF;
$azul2: #F8FBFF;
$azul3: #D6E8FE;
$azul4: rgba(251, 253, 255, 0.75);
$azul5: #F3F7FD;
$azul6: #8CBAF7;
$gris_boton_bg: #F7F7F7;
$gris_boton: #B9B5B5;
$gris_flojo: #F4EFEF;
$gris_medio: #7B7B7B;
$gris_fuerte: #524F4F;
$gris_oscuro: #393D40;
$gris3: #C9C5C5;
$gris4: #E3E0E0;
$gris5: #707070;
$gris_d6: #D6D6D6;

$rojo1: #F67F8C;

$verde1: #B9FFC4;

$menu_amarillo: #FDFEAE;

$text-grey: #d8d8d8;
$text-placeholder-grey: #a8a7a7;

$font: #1d1d1b;
$font-inactive: #d0d1d2;
$font-light: #ffffff;
$background: #505050;

$white: #fff;
$black: #000000;
$black2: #030303;
$google-blue: #4285f4;
$button-active-blue: #1669f2;

$home-beige: rgba(224, 222, 212, 0.7);
$home-black: #1d1d1b;

$bg-beige: #e0ded4;

$form-border-color: #000000;
$form-border-color-inactive: #d0d1d2;
$login-background: #e0ded4;
$login-background-opacity: rgba(224, 222, 212, 0.98);

$calendar-border-gray: #E3E0E0;
$calendar-pasado: #E3E0E0;
$calendar: #666666;

$unactive-menu: #a5a5a5;

$form-message-text: #9f8577;
$disabled: rgba(0,0,0,0.3);

$gestion_hover: #E3E1D8;
$gestion_bg_menu: #F7F7F7;
$gestion_bg_filters: #F8FBFF;
$section_border: #EFECE7;

$select_publicado: #D2FAFF;
$select_borrador: rgba(246, 127, 140, .4);

$modal_texto: #707070;

$modal_reservar_bg: #343434;
$modal_reservar_text: #CBBBA3;

$modal_bottom_grey: rgba(57, 61, 64, 0.70);

$star_fill: #FFD800;

$table_red_text: #F67F8C;

// Medidas
$pHor: 15px;
$franja-height: 25px;

// Variables para los tamaños de Media Queries
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1336px;
