.citas-list {
    border-bottom: 1px solid $calendar-border-gray;
    max-height: calc( 100vh - 300px);
    overflow: auto;
    max-width: 902px;
    margin: 0 auto;

    .citas-filter {
        margin-bottom: 20px;
        border-bottom: 1px solid $calendar-border-gray;
        width: 250px;
        input {
            font-family: $archivo_regular;
            font-size: 14px;
            border: none;
            background-color: transparent;
            padding: 3px 3px 3px 10px;
            width: 100%;
            &::placeholder {
                color: $calendar-border-gray;
            }
        }
    }
    
    .cita-container {
        padding: 10px 10px;
        border-top: 1px solid $calendar-border-gray;
        width: 100%;
        .cita {
            position: relative;
            font-family: $archivo_regular;
            font-size: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            cursor: pointer;
            .btn-cita {
                flex-shrink: 0;
                width: 20px;
                & > div {
                    padding-bottom: 2px;
                }
                .minus { 
                    display: none;
                 }
            }
            .date {
                flex-shrink: 0;
                width: 120px;
            }
            .service {
                margin-left: 35px;
                width: 100%;
            }
            .user {
                margin-left: 35px;
                width: 100%;
            }
            .random {
                flex-shrink: 0;
                margin-left: 10px;
                padding-top: 4px;
                width: 30px;
                svg {
                    width: 21px;
                    height: 21px;
                }
            }
            .promo {
                flex-shrink: 0;
                margin-left: 10px;
                width: 50px;
                span {
                    font-family: $archivo_bold;
                    font-size: 12px;
                    text-transform: uppercase;
                    background-color: $azul_extraflojo;
                    color: var(--colorPrincipal);
                    padding: 2px 6px;
                }
            }
            .comments {
                position: relative;
                flex-shrink: 0;
                margin-left: 35px;
                width: 50px;
                .badge {
                    position: absolute;
                    top: -7px;
                    left: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    background-color: $azul_flojo;
                    span {
                        font-family: $archivo_bold;
                        font-size: 8px;
                        color: $white;
                    }
                }
            }
            .indicator {
                flex-shrink: 0;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                margin: 0px 10px 4px 1px;
            }
            &.passed {
            }
        }
        .cita-data-row {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin: 0px 34px 0px 20px;
            .cita-data {
                display: flex;
                width: 475px;
                margin-top: 10px;
                padding-top: 10px;
                color: #797979;
                border-top: 1px solid $calendar-border-gray;
                .name {
                    width: 156px;
                    flex-shrink: 0;
                }
                a {
                    color: $gold;
                    text-decoration: none;
                }
                span {
                    margin: 0px 13px;
                }
            }
            .buttons {
                display: flex;
                margin-top: -15px;
                .btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 50px;
                    height: 50px;
                    border: 1px solid $calendar-border-gray;
                    margin-left: 12px;
                    cursor: pointer;
                    background-color: $white;
                    span {
                        display: inline-block;
                        width: 24px;
                        height: 22px;
                        background-image: url('../../assets/img/sprite.png');
                    }
                    &.btn-edit {
                        span {
                            background-position: -32px -380px;
                        }
                    }
                    &.btn-delete {
                        span {
                            width: 23px;
                            height: 23px;
                            background-position: -71px -263px;
                        }
                    }
                    &:hover {
                        background-color: $gold;
                        &.btn-edit {
                            span {
                                background-position: -4px -380px;
                            }
                        }
                        &.btn-delete {
                            span {
                                background-position: -257px -380px;
                            }
                        }
                    }
                }
            }
        }
        &:hover,
        &.active {
            background-color: $azul_extraflojo;
        }
    }
}