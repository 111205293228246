.servicios {
    height: calc( 100vh - 169px );
    overflow: auto;
    .servicios-grid {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 24px;
        padding-bottom: 20px;
        width: 100%;
        &.dnd {
            & > div {
                gap: 10px;
            }
        }
        .servicio-card {
            background-color: #FAFAFA;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 220px;
            position: relative;
            width: 170px;
            .card-main {
                & > div {
                    overflow: hidden;
                }
                .servicio-img {
                    background-position: center;
                    background-size: cover;
                    border-radius: 10px 10px 0px 0px;
                    height: 88px;
                    width: 100%;
                }
                .servicio-title {
                    display: flex;
                    font-family: $archivo_bold;
                    font-size: 12px;
                    justify-content: center;
                    height: 27px;
                    margin: 10px 20px;
                    text-align: center;
                }
                .servicio-description {
                    display: flex;
                    font-size: 10px;
                    height: 35px;
                    justify-content: center;
                    line-height: 14px;
                    padding: 0px 20px 5px;
                    text-align: center;
                    p {
                        overflow: hidden;
                    }
                }
                .servicio-sucursales {
                    display: flex;
                    font-family: $archivo_semibold;
                    font-size: 10px;
                    justify-content: center;
                    padding: 10px 20px;
                    text-align: center;
                }
            }
            footer {
                align-items: center;
                border-radius: 0px 0px 10px 10px;
                color: $white;
                display: flex;
                font-family: $archivo_semibold;
                font-size: 11px;
                justify-content: space-between;
                height: 25px;
                padding: 0px 10px;
                & > div {
                    align-items: center;
                    display: flex;
                }
                .footer-left {
                    & > div {
                        align-items: center;
                        display: flex; 
                        margin-right: 4px;
                        svg {
                            margin-right: 4px;
                        }                               
                    }
                    .time {
                        svg {
                            margin-top: 2px;
                        }
                    }
                    .pauses {
                        svg {
                            margin-top: 1px;
                        }
                    }
                }
                .footer-right {
                    align-items: center;
                    display: flex;
                    justify-content: flex-end; 
                    & > div {
                        align-items: center;
                        display: flex; 
                        margin-left: 4px;
                        svg {
                            margin-right: 4px;
                        }                             
                    }
                    .price {
                        svg {
                            margin-top: 1px;
                        }
                    }
                }
            }
            .servicio-overlay {
                align-items: center;
                background-color: rgba(0,0,0,0.75);
                border-radius: 10px;
                display: flex;
                height: 100%;
                justify-content: center;
                left: 0px;
                position: absolute;
                top: 0px;
                width: 100%;
                .overlay-buttons {
                    display: flex;
                    button {
                        background: none;
                        background-color: $white;
                        border: none;
                        border-radius: 50%;
                        height: 45px;
                        margin: 0px 4px;
                        width: 45px;
                        svg {
                            margin-top: 3px;
                            margin-left: 1px;
                        }
                        &.trash {
                            svg {
                                height: 15px;
                                width: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.toggle-dnd {
    align-items: center;
    background-color: var(--color6);
    border-radius: 8px;
    bottom: 20px;
    color: var(--colorPrincipal);
    cursor: pointer;
    display: flex;
    left: 50%;
    padding: 8px 20px 8px 11px;
    position: absolute;
    transform: translateX(-50%);
    svg {
        margin-right: 10px;
    }
    &.active {
        background-color: var(--colorPrincipal);
        color: $white;
    }
    &:hover {
        opacity: 0.8;
    }
}

.servicio {
    width: 100%;
    input {
        color: $gris_fuerte;
        font-family: $archivo_regular;
        &:disabled {
            background-color: transparent;
            color: $black;
        }
    }
    textarea {
        border-radius: 10px;
        color: $gris_fuerte;
        min-height: 80px;
        padding: 5px 15px;
        &:disabled {
            background-color: transparent;
            color: $black;
        }
    }
    .servicio-wrapper {
        width: 963px;
        .single-servicio {
            padding: 44px 30px 10px;
            .header {
                display: flex;
                justify-content: space-between;
                min-height: 36px;
                width: 100%;
                .nav-back {
                    align-items: center;
                    color: $azul3;
                    cursor: pointer;
                    display: flex;
                    font-family: $archivo_bold;
                    font-size: 20px;
                    p {
                        margin-left: 9px;
                    }
                }
                .botonera {
                    align-items: center;
                    display: flex;
                    transition: transform 0.3s;
                    .btn-mini {
                        background: none;
                        border: none;
                        height: 36px;
                        margin-left: 10px;
                        width: 36px;
                        &.btn-editar {
                            svg {
                                height: 16px;
                                width: 17px;
                            }
                        }
                        &.btn-eliminar {
                            svg {
                                height: 17px;
                                width: 17px;
                            }
                        }
                    }
                    .btn {
                        background: none;
                        background-color: $gestion_bg_menu;
                        border: none;
                        color: var(--colorPrincipal);
                        font-family: $archivo_bold;
                        font-size: 16px;
                        height: 36px;
                        margin-left: 10px;
                        width: 120px;
                        &.btn-crear {
                            background-color: var(--colorPrincipal);
                            color: $gestion_bg_menu;
                        }
                        &:hover {
                            opacity: 0.3;
                        }
                    }
                    .btn:active {
                        background-color: #EDD8BB; 
                    }
                }
                &.compress {
                    .botonera {
                        transform: translateX(-135px);                
                    }
                }
            }
            input {
                &::placeholder {
                    color: $gris_fuerte;
                }
            }
            textarea {
                font-family: $archivo_regular;
                font-size: 14px;
                line-height: 23px;
                margin-top: 8px;
                resize: none;
                &::placeholder {
                    color: $gris_fuerte;;
                    font-style: italic;
                }
            }
            .name {
                margin-top: 19px;
                input {
                    border: none;
                    font-family: $archivo_bold;
                    font-size: 26px;
                    width: 100%;
                }
            }
            .servicio-main {
                display: flex;
                height: calc( 100vh - 248px);
                margin-top: 25px;
                overflow: auto;
                width: 100%;
                .servicio-left {
                    min-width: 220px;
                    .image-container {
                        background-size: cover;
                        background-position: center;
                        border-radius: 10px;
                        cursor: pointer;
                        height: 357px;
                        width: 100%;
                    }
                }
                .servicio-right {
                    flex: 1 1 auto;
                    margin-left: 39px;
                    .servicio-data {
                        display: flex;
                        .servicio-texts {
                            flex: 1 1 auto;
                            .servicio-text {
                                margin-bottom: 20px;
                                textarea {
                                    border: none;
                                    width: 100%;
                                }
                                .idiomas {
                                    display: flex;
                                    margin-top: 10px;
                                    .btn-idioma {
                                        align-items: center;
                                        border-radius: 50%;
                                        color: $white;
                                        cursor: pointer;
                                        display: flex;
                                        font-family: $archivo_bold;
                                        font-size: 12px;
                                        height: 37px;
                                        justify-content: center;
                                        margin-right: 8px;
                                        width: 37px;
                                    }
                                }
                            }
                        }
                        .servicio-price {
                            margin-left: 39px;
                            width: 105px;
                            .price-item {
                                align-items: center;
                                border-radius: 10px;
                                display: flex;
                                font-family: $archivo_bold;
                                font-size: 30px;
                                height: 105px;
                                justify-content: center;
                                position: relative;
                                width: 105px;
                                input {
                                    background: none;
                                    border: none;
                                    font-family: $archivo_bold;
                                    font-size: 30px;
                                    text-align: right;
                                    padding-right: 30px;
                                    width: 100%;
                                }
                                span {
                                    display: inline-block;
                                    position: absolute;
                                    right: 9px;
                                    top: 37px;
                                }
                            }
                        }
                    }
                    .servicio-selectors {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 7px;
                        .servicio-selector {
                            border-radius: 10px;
                            display: flex;
                            height: 71px;
                            padding: 8px;
                            position: relative;
                            width: 210px;
                            .left {
                                .icon-bg {
                                    align-items: center;
                                    background-color: $white;
                                    border-radius: 10px;
                                    display: flex;
                                    height: 54px;
                                    justify-content: center;
                                    width: 54px;
                                }
                            }
                            .right {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                flex: 1 1 auto;
                                margin-left: 15px;
                                .title {
                                    font-size: 11px;
                                    text-transform: uppercase;
                                }
                            }

                            &.pauses {
                                .right {
                                    flex-direction: row;
                                    .right-block {
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: space-between;
                                        margin-right: 10px;
                                        flex: 1 1 auto;
                                        &:nth-child(2) {
                                            margin-right: 0px;
                                        }
                                        .values {
                                            align-items: center;
                                            cursor: pointer;
                                            display: flex;
                                            justify-content: space-between;
                                            margin-bottom: 6px;
                                            width: 100%;
                                            p {
                                                font-size: 20px;
                                            }
                                            svg {
                                                margin-right: 8px;
                                            }
                                        }
                                        .block-input {
                                            display: flex;
                                            justify-content: flex-start;
                                            input {
                                                background-color: transparent;
                                                border: none;
                                                font-size: 20px;
                                                padding: 5px 10px 2px;
                                                text-align: left;
                                                width: calc( 100% - 8px);
                                            }

                                        }
                                    }
                                }
                            }
                            &.prepago {
                                .right {
                                    flex-direction: row;
                                    p {
                                        font-size: 11px;
                                        margin-left: auto;
                                        margin-right: 16px;
                                        text-transform: uppercase;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .ylm-sucursal-select {
        margin-bottom: 6px;
        width: 100%;
        .select-main {
            align-items: center;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            padding-right: 10px;
            width: 100%;
            p {
                font-size: 20px;
            }
        }
    }
    .pauses {
        position: relative;
        .select-options {
            background-color: var(--color6);
            border: 1px solid var(--colorPrincipal);
            border-radius: 10px;
            padding: 21px 21px 21px 32px;
            position: absolute;
            right: 0px;
            top: calc(100% + 5px);
            width: 355px;
            z-index: 2;
            .duracion {
                h5 {
                    font-family: $archivo_regular;
                    font-size: 14px;
                    font-weight: 300;
                    margin-bottom: 4px;
                }
                input {
                    background-color: transparent;
                    border: none;
                    border-bottom: 1px solid;
                    font-size: 16px;
                    width: 116px;
                }
                & > p {
                    border-bottom: 1px solid;
                    font-size: 16px;
                    padding: 1px 2px;
                    width: 116px;
                }
            }
            .descansos {
                align-items: center;
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
                width: 100%;
            }

            .descansos-form {
                display: flex;
                gap: 10px;
                justify-content: space-between;
                margin-top: 25px;
                .descansos-form-item {
                    width: 33%;
                    h5 {
                        font-family: $archivo_regular;
                        font-size: 14px;
                        font-weight: 300;
                        margin-bottom: 6px;
                    }
                    input {
                        background-color: transparent;
                        border: none;
                        border-bottom: 1px solid;
                        font-size: 16px;
                        width: 100%;
                    }
                }
            }
        }
    }
}