.reservar-container {
    padding-top: 246px;
    @include lg(){
        padding-bottom: 30px;
    }
    
    &.extra-padding {
        padding-bottom: 530px;
        &.form-active {
            padding-bottom: 0;
        }
    }
    .form-container {
        width: calcula(375, 325);
        margin: 0 auto;
        @include lg(){
            width: 600px;
        }
    }
    .reservar-form {
        h1 {
            color: $gold;
            font-family: $archivo_bold;
            font-size: 17px;
            text-transform: uppercase;
            letter-spacing: 6px;
            margin-bottom: 31px;
            @include lg(){
                font-size: 19px;
                line-height: 28px;
            }
        }

        .brb-select {
            position: relative;
            .brb-select-container {
                color: $font-inactive;
                font-size: 19px;
                padding: 10px 0px 3px;
                border-bottom: 2px solid $form-border-color-inactive;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 21px;
                z-index: 1;
                pointer-events: unset;
                @include lg(){
                    padding: 10px 0px;
                    font-size: 20px;
                }
                p {
                    font-family: $archivo_semibold;
                }

                .right-zone {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    min-width: 120px;
                    p {
                        margin-right: 40px;
                        display: none;
                        @include lg(){
                            display: block;
                        }
                    }
                    span {
                        margin-right: 12px;
                        width: 23px;
                        height: 15px;
                        background-image: url('../../assets/img/sprite.png');
                        background-position: -222px -8px;
                        @include lg(){
                            margin-right: 20px;
                            width: 23px;
                            height: 15px;
                            background-position: -129px -3px;
                        }
                    }
                } 

                &.active {
                    &.visible {
                        .right-zone {
                            span {
                                background-position: -244px -8px;
                                @include lg(){
                                    background-position: -99px -3px;
                                }
                            }
                        }
                    }
                }

                &.visible {
                    color: $font;
                    border-color: $form-border-color;
                    pointer-events: initial;
                    cursor: pointer;
                    .right-zone {
                        span {
                            background-image: url('../../assets/img/sprite.png');
                            background-position: -222px -8px;
                            @include lg(){
                                background-position: -68px -3px;
                            }
                        }
                    }
                }
            }
            .brb-select-options {
                position: absolute;
                top: calc(100% - 2px);
                left: 0px;
                width: calc(100% - 2px);
                background-color: $white;
                height: 0px;
                overflow: auto;
                z-index: 3;
                transition: height 0.3s;
                box-sizing: content-box;
                &.active {
                    top: calc(100% - 4px);
                    border: 4px solid $form-border-color;
                    max-height: 491px;
                    height: auto;
                    @include lg(){
                        max-height: 198px;
                    }
                    &.select-date {
                        max-height: unset;
                    }
                    &.select-time {
                        max-height: 605px;
                    }
                }
                
                .brb-select-option {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    padding: 14px 15px;
                    font-size: 20px;
                    border-bottom: 2px solid $form-border-color;
                    background-color: $white;
                    cursor: pointer;
                    @include lg(){
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 18px;
                    }
                    &:last-of-type {
                        border-bottom: none;
                    }
                    p {
                        font-family: $archivo_semibold; 
                        padding-bottom: 8px;
                        @include lg(){
                            padding-bottom: 0px;
                        }
                    }
                    .right-container {
                        overflow: hidden;
                        padding-top: 6px;
                        @include lg(){
                            padding-top: 0px;
                        }
                        .price {
                            font-family: $archivo_semibold; 
                            font-size: 18px;
                        }
                        .horario, .adress {
                            font-family: $archivo_semibold; 
                            font-size: 18px;
                            @include lg(){
                                font-size: 16px; 
                            }
                        }
                        .horario {
                            font-size: 17px;
                            @include lg(){
                                font-size: 16px; 
                            }
                        }
                    }
                }

                &.select-servicio {
                    .right-container {
                        min-width: 60px;
                    }
                }

                &.select-sucursal {
                    .right-container {
                        min-width: 184px;
                    }
                }

                &.select-profesional {
                    .right-container {
                        min-width: 290px;
                    }
                }
            }
        }
    }
    .message {
        width: calcula(375, 325);
        margin: 39px auto 0;
        @include lg(){
            width: 600px;
        }
        p {
            font-family: $archivo_medium;
            font-size: 16px;
            line-height: 23px;
            color: $form-message-text;
            width: calcula(375, 282);
            @include lg(){
                width: 480px;
            }
        }
    }
    .botonera {
        position: relative;
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: flex-start;
        margin-top: 47px;
        margin-bottom: 170px;
        @include lg(){
            margin-top: 25px;
            flex-direction: row;
            margin-bottom: 96px;
        }
        button {
            margin: 0;
            background: none;
            font-family: $archivo_bold;
            font-size: 22px;
            border: 4px solid $font;
            padding: 15px 17px;
            margin-bottom: 15px;
            @include lg(){
                font-size: 20px;
                margin-right: 25px;
                padding: 10px 17px;
                margin-bottom: 0px;
            }
            &:hover {
                background-color: $gold;
                color: $white;
                border-color: $gold;
            }
            &.active {
                background-color: $gold;
                color: $white;
                border-color: $gold;
            }
        }
        .img-right {
            position: absolute;
            bottom: -110px;
            right: 0px;
            width: 202px;
            height: 48px;
            background-image: url('../../assets/img/sprite.png');
            background-position: -229px -314px;
            @include lg(){
                width: 222px;
                right: -178px;
                bottom: 0px;
                background-position: -6px -72px;
            }
            @include xl(){
                right: -278px;
            }
        }
    }
}

.react-calendar {
    font-family: $archivo_medium !important;
    padding: 0px 0px 15px;
    @include lg(){
        padding: 0px 15px 15px;
    }
    &__navigation {
        padding: 24px 0px;
        button {
            background: none;
            border: none;
            font-size: 20px;
        }
        &__label {
            span {
                font-family: $archivo_bold !important;
                font-size: 20px;
            }
        }
        &__prev2-button,
        &__next2-button {
            display: none;
        }
    }
    &__month-view {
        &__weekdays {
            &__weekday {
                color: var(--colorPrincipal);
                font-family: $archivo_regular;
                font-size: 15px;
                text-align: center;
                padding-bottom: 10px;
                abbr {
                    font-family: $archivo_regular;
                    text-transform: capitalize;
                    text-decoration: none;
                }
            }
        }
        &__days {
            &__day {
                &--neighboringMonth {
                    color: $calendar-pasado;
                }
            }
        }
    }
    &__tile {
        border: none;
        background-color: transparent;
        border-top: 1px solid $calendar-border-gray !important;
        padding: 25px 15px !important;
        margin: 0px;
        abbr {
            font-family: $archivo_medium !important;
            font-size: 16px;
            @include lg(){
                font-size: 19px;
            }
        }
        &--active {
            background-image: url('../../assets/img/sprite.png');
            background-position: -4px 10px;
            color: $gold;
            @include md(){
                max-width: 50px !important;
                margin: 0px 22px;
            }
            @include lg(){
                max-width: 14.2857% !important;
                margin: 0px;
            }
            abbr {
                font-family: $archivo_bold !important;
                font-size: 20px;
                position: relative;
                left: -3px;
                @include lg(){
                    font-size: 19px;
                    left: 0px;
                }
            }
            @include lg(){
                background-position: 12px 9px;
            }
            &:focus {
                border: none;
                outline: none;
            }
        }
        &:focus {
            border: none;
            outline: none;
        }
    }
}