.variantes {
    height: calc( 100vh - 169px );
    overflow: auto;
    .variantes-table {
        .variante-item {
            background-color: var(--color6);
            display: flex;
            margin-bottom: 5px;
            padding: 18px 0px 18px 28px;
            .item-col {
                cursor: pointer;
                margin-right: 7px;
                overflow: hidden;
                h5 {
                    font-family: $archivo_regular;
                    font-size: 14px;
                    font-weight: normal;
                    margin-bottom: 6px;
                }
                p {
                    font-family: $archivo_bold;
                    font-size: 14px;
                }
                &.col-variante {
                    flex: 1;
                }
                &.col-tiempo {
                    flex: 0 0 118px;
                }
                &.col-valor {
                    flex: 0 0 97px;
                }
                &.col-servicios {
                    flex: 0 0 127px;
                }
                &.col-productos {
                    flex: 0 0 259px;
                }
                &.col-btns {
                    position: relative;
                    flex-basis: 20px;
                    min-width: 20px;
                    display: flex;
                    justify-content: flex-end;
                    overflow:initial;
                    .btn-menu {
                        flex-shrink: 0;
                        cursor: pointer;
                        position: relative;
                        & > span {
                            align-items: center;
                            display: inline-flex;
                            justify-content: center;
                            width: 35px;
                        }
                        .submenu {
                            position: absolute;
                            display: none;
                            opacity: 0;
                            background-color: $white;
                            border: 1px solid $calendar-border-gray;
                            box-shadow: 0px 2px 10px #cccccc;
                            top: 10px;
                            right: 30px;
                            list-style: none;
                            width: 140px;
                            padding: 0px 20px;
                            transition: opacity 0.3s;
                            &.active {
                                display: block;
                                opacity: 1;
                            }
                            li {
                                display: flex;
                                align-items: center;
                                height: 40px;
                                border-bottom: 1px solid $calendar-border-gray;
                                cursor: pointer;
                                &:last-of-type {
                                    border: none;
                                }
                                &:hover {
                                    color: var(--colorPrincipal);
                                }
                            }
                        }
                        &.active {
                            .submenu {
                                display: block;
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}



.variante {
    width: 100%;
    input {
        color: $gris_fuerte;
        font-family: $archivo_regular;
        font-size: 14px;
        &:disabled {
            background-color: transparent;
            color: $black;
        }
    }
    textarea {
        color: $gris_fuerte;
        &:disabled {
            background-color: transparent;
            color: $black;
        }
    }
    .variante-wrapper {
        width: 963px;
        .single-variante {
            padding: 44px 30px;
            .header {
                display: flex;
                justify-content: space-between;
                min-height: 36px;
                width: 100%;
                .nav-back {
                    align-items: center;
                    color: $azul3;
                    cursor: pointer;
                    display: flex;
                    font-family: $archivo_bold;
                    font-size: 20px;
                    p {
                        margin-left: 9px;
                    }
                }
                .botonera {
                    align-items: center;
                    display: flex;
                    transition: transform 0.3s;
                    .btn-mini {
                        background: none;
                        border: none;
                        height: 36px;
                        margin-left: 10px;
                        width: 36px;
                        &.btn-editar {
                            svg {
                                height: 16px;
                                width: 17px;
                            }
                        }
                        &.btn-eliminar {
                            svg {
                                height: 17px;
                                width: 17px;
                            }
                        }
                    }
                    .btn {
                        background: none;
                        background-color: $gestion_bg_menu;
                        border: none;
                        color: var(--colorPrincipal);
                        font-family: $archivo_bold;
                        font-size: 16px;
                        height: 36px;
                        margin-left: 10px;
                        width: 120px;
                        &.btn-crear {
                            background-color: var(--colorPrincipal);
                            color: $gestion_bg_menu;
                        }
                        &:hover {
                            opacity: 0.3;
                        }
                    }
                    .btn:active {
                        background-color: #EDD8BB; 
                    }
                }
                &.compress {
                    .botonera {
                        transform: translateX(-135px);                
                    }
                }
            }
            input {
                &::placeholder {
                    color: $gris_fuerte;
                }
            }
            textarea {
                font-family: $archivo_regular;
                font-size: 14px;
                line-height: 23px;
                margin-top: 8px;
                resize: none;
                &::placeholder {
                    color: $gris_fuerte;;
                    font-style: italic;
                }
            }
            .name {
                margin-top: 19px;
                input {
                    border: none;
                    font-family: $archivo_bold;
                    font-size: 26px;
                    width: 100%;
                }
            }
            .variante-main {
                width: 100%;
                h3 {
                    color: var(--colorSecundario);
                    font-weight: normal;
                    margin-bottom: 7px;
                }
                .variante-description {
                    margin-top: 25px;
                    width: 100%;
                    textarea {
                        background-color: var(--color6);
                        border: none;
                        border-radius: 10px;
                        margin: 0px;
                        padding: 11px 18px;
                        width: 100%;
                    }
                }
                .variante-values {
                    display: flex;
                    gap: 23px;
                    justify-content: space-between;
                    margin-top: 25px;
                    .value-item {
                        width: 50%;
                        .value-selectors {
                            display: flex;
                            gap: 13px;
                            justify-content: space-between;
                            position: relative;
                            .value-selector {
                                width: 50%;
                                input {
                                    background-color: var(--color6);
                                    border: none;
                                    border-radius: 10px;
                                    overflow: hidden;
                                    padding: 12px 5px 12px 18px;
                                    width: 100%;
                                    &::placeholder {
                                        font-style: italic;
                                    }
                                }
                                .time-select {
                                    align-items: center;
                                    background-color: var(--color6);
                                    border-radius: 10px;
                                    display: flex;
                                    justify-content: space-between;
                                    padding: 12px 16px 12px 27px;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
                .variante-selectors {
                    display: flex;
                    gap: 23px;
                    justify-content: space-between;
                    margin-top: 25px;
                    width: 100%;
                }
            }
        }
    }
    .ylm-value-select {
        width: 100%;
        .select-main {
            align-items: center;
            background-color: var(--color6);
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            padding: 12px 16px 12px 27px;
            width: 100%;
            svg {
                transition: transform 0.3s;
            }
        }
        .select-options {
            li {
                font-size: 14px;
            }
        }
        &.active {
            .select-main {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .ylm-variante-select {
        align-items: center;
        background-color: var(--colorSecundario);
        border-radius: 10px;
        color: var(--black);
        display: flex;
        height: 71px;
        padding: 14px 14px 14px 8px;
        position: relative;
        width: 50%;
        .select-main {
            align-items: center;
            cursor: pointer;
            display: flex;
            width: 100%;
            .var-icon {
                align-items: center;
                background-color: var(--white);
                border-radius: 10px;
                display: flex;
                flex: 0 0 54px;
                height: 56px;
                justify-content: center;
                margin-right: 25px;
                width: 54px;
                svg {
                    height: 23px;
                    width: 21px;
                }
            }
            .var-data {
                flex: 1;
                h4 {
                    color: var(--colorPrincipal);
                    font-size: 11px;
                    font-weight: normal;
                    margin-bottom: 7px;
                    text-transform: uppercase;
                }
                p {
                    font-size: 20px;
                }
            }
            .btn-chevron {
                svg {
                    transition: transform 0.3s;
                }
                &.active {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
        }
        .select-options {
        }
    }
}