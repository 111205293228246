.ventas-screen {
    flex-shrink: 0;
    height: calc(100vh - 85px);
    width: calc(100% - 66px);
    padding-left: 33px;
    padding-right: 33px;
    position: relative;

    .preheader {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 28px;

        .title {
            display: flex;
            align-items: center;
            h1 {
                font-family: $archivo_bold;
                font-size: 28px;
                color: var(--colorPrincipal);
            }
        }

        .selector {
            display: flex;
            align-items: center;
            margin-right: 20px;
        }

        .botonera {
            display: flex;
            justify-content: flex-end;
            flex: 1;
            transition: transform 0.3s;
            & > div {
                margin-left: 8px;
            }

            .btn-add {
                width: 35px;
                height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: var(--color6);
                font-family: $archivo_bold;
                font-size: 21px;
                cursor: pointer;
            }

            .btn-search {
                align-items: center;
                background-color: var(--color6);
                cursor: pointer;
                display: flex;
                font-family: $archivo_bold;
                font-size: 21px;
                height: 36px;
                justify-content: center;
                position: relative;
                width: 35px;

                .search-input {
                    display: none;
                    position: relative;
                    input {
                        background: none;
                        border: none;
                        height: 100%;
                        width: 250px;
                        padding-left: 8px;
                        &::placeholder {
                            color: var(--colorPrincipal);
                        }
                    }
                }

                &.active {
                    display: flex;
                    justify-content: flex-end;
                    width: auto;
                    padding-right: 8px;
                    .search-input {
                        display: flex;
                    }
                }
            }
        }

        &.compress {
            .botonera {
                transform: translateX(-135px);
            }
        }
    }

    .filters {
		display: flex;
		grid-gap: 20px;
		gap: 20px;
		margin-top: 47px;
		margin-bottom: 30px;
		width: 100%;
		padding: 30px;
		box-shadow: none;
		border-radius: 31px;

        .filter-group {
            label {
                font-size: 12px;
                color: var(--colorPrincipal);
                margin-bottom: 8px;
                display: block;
            }

            .date-range, .time-range {
                display: flex;
                align-items: center;
                gap: 10px;
                background: var(--color6);
                padding: 8px 16px;
                border-radius: 20px;
				border: 1px solid #535AF1;

                input {
                    border: none;
                    background: transparent;
                    outline: none;
                    font-size: 15px;
                    color: var(--colorPrincipal);
					font-weight: bold;
                }

                span {
                    color: var(--colorPrincipal);
                }
            }

            .filter-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: var(--color6);
                padding: 12px 16px;
                border-radius: 20px;
                border: 1px solid var(--colorPrincipal);                
                cursor: pointer;
                position: relative;

                span {
                    color: var(--colorPrincipal);
                    font-size: 15px;
                    font-weight: bold;
                    
                }

                svg {
                    color: var(--colorPrincipal);
                }
            }
			

            .filter-dropdown {
                position: absolute;
				background: #ffffff;
				border-radius: 20px;
				min-width: 200px;
				margin-top: 4px;
				/* padding: 15px; */
				border: none;
				box-shadow: 0 2px 8px rgba(0, 0, 0, .1);
				z-index: 10;
				border: 1px solid #535AF1;

                .filter-option {
                    display: flex;
                    align-items: center;
                    padding: 12px;
                    cursor: pointer;
                    transition: all 0.2s ease;                    
                    margin-bottom: 4px;
                    color: var(--colorPrincipal);
                    font-family: $archivo_regular;
                    border-bottom: 1px solid;

                    &:hover {
                        background-color: rgba(255,255,255,0.5);
                    }

                    input[type="checkbox"] {
                        margin-right: 10px;
                        width: 20px;
                        height: 22px;
                        border: 1px solid var(--colorPrincipal);
                        border-radius: 4px;
                        appearance: none;
                        -webkit-appearance: none;
                        position: relative;
                        cursor: pointer;

                        &:checked {
                            background-color: var(--colorPrincipal);
                            &:after {
                                content: '';
                                position: absolute;
                                left: 5px;
                                top: 2px;
                                width: 5px;
                                height: 10px;
                                border: solid white;
                                border-width: 0 2px 2px 0;
                                transform: rotate(45deg);
                            }
                        }
                    }

                    span {
                        font-size: 14px;
                    }                    
                }				
				
            }
			

            .date-range {
				.date-input {
					position: relative;
					
					.date-display {
						cursor: pointer;
						padding: 4px;
						/* border: 1px solid var(--colorPrincipal); */
						border-radius: 4px;
						color: var(--colorPrincipal);
						font-weight: bold;
					
					}
					
					.calendar-dropdown {
						position: absolute;
						top: 100%;
						left: 0;
						z-index: 1000;
						display: none;
						background-color: white;
						box-shadow: 0 2px 10px rgba(0,0,0,0.1);
						border-radius: 4px;
						width: 365px;
						&.active {
							display: block;
						}
						
						.react-calendar {
							border: none;
							
							.react-calendar__tile--active {
								background: var(--colorPrincipal);
							}
							.react-calendar__tile {								
								padding: 10px 10px !important;
							}
							.react-calendar__tile abbr {
								font-size: 15px;
							}
								
						}
					}
				}
			}

            &.total-facturado {
                margin-left: auto;
                
                .total-amount {
                    display: flex;
                    align-items: center;
                    background: var(--color6);
                    padding: 8px 16px;
                    border-radius: 20px;                    
                    font-family: $archivo_bold;
                    font-size: 24px;
                    color: var(--colorPrincipal);
                }
            }
        }
    }

    .table-ventas {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        
        .table-scrollable {
            height: calc(100vh - 458px);
            overflow-y: auto;
            overflow-x: auto;
        }

        .table-row {
            display: flex;
            padding: 12px 2px 12px 10px;
            border-bottom: 1px solid rgba(83, 90, 241, 0.1);
            cursor: pointer;

            &.headers-row {
                cursor: default;
                background-color: var(--color6);
                padding: 10px 5px;
                border-radius: 20px;
                margin-bottom: 10px;
                
                .column {
                    .column-header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border: 1px solid var(--colorPrincipal);
                        padding: 14px;
                        border-radius: 20px;
                        background-color: var(--color6);
						p{
							color: var(--colorPrincipal);
						}
                    }

                    .filter-dropdown {
                        position: absolute;
						top: 43px;
						left: 0;
						background: #fff;
						border-radius: 20px;
						min-width: 200px;
						margin-top: 4px;
						/* padding: 15px; */
						border: none;
						box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
						z-index: 10;
						border: 1px solid var(--colorPrincipal);

                        .filter-option {
							display: flex;
							align-items: center;
							padding: 12px;
							cursor: pointer;
							transition: all 0.2s ease;							
							color: var(--colorPrincipal);
							font-family: "Archivo-Regular";
							border-bottom: 1px solid;
							

                            &:hover {
                                background-color: rgba(255,255,255,0.5);
                            }

                            input[type="checkbox"] {
                                margin-right: 10px;
                                width: 20px;
                                height: 22px;
                                border: 1px solid var(--colorPrincipal);
                                border-radius: 4px;
                                appearance: none;
                                -webkit-appearance: none;
                                position: relative;
                                cursor: pointer;
								min-width: 20px;

                                &:checked {
                                    background-color: var(--colorPrincipal);
                                    &:after {
                                        content: '';
                                        position: absolute;
                                        left: 5px;
                                        top: 2px;
                                        width: 5px;
                                        height: 10px;
                                        border: solid white;
                                        border-width: 0 2px 2px 0;
                                        transform: rotate(45deg);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &:not(.headers-row) {
                &:hover {
                    background-color: rgba(83, 90, 241, 0.05);
                }
            }
        }

        .column {
            display: flex;
            margin-right: 5px;            
            align-items: center;
            position: relative;

            &.cliente { width: 15%; min-width: 120px; }
            &.servicio { width: 15%; min-width: 120px; }
            &.descuento { width: 12%; min-width: 100px; }
            &.variante { width: 12%; min-width: 100px; }
            &.producto { width: 15%; min-width: 120px; }
            &.metodoPago { width: 12%; min-width: 100px; }
            &.importe { width: 10%; min-width: 80px; }
            &.download { 
                width: 5%; 
                min-width: 40px;
                
                .column-header {
                    justify-content: center;
                    padding: 14px 10px;
                    
                    :global(.download-csv) {
                        background: none;
                        border: none;
                        padding: 0;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        
                        svg {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
        }
    }

    .loading-ventas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        
        .bg-loading {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255,255,255,0.8);
        }
        
        p {
            position: relative;
            z-index: 1;
            font-family: $archivo_bold;
        }
    }
}

.ventas-screen .filter-dropdown .dropdown-filter .filter-option:last-child {
    border-bottom: none !important;
}