.modal-ylm {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 1100;
    display: none;
    justify-content: center;
    align-items: center;
    .modal-bg {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.9);
    }
    .modal-wrapper {
        position: relative;
        width: calc( 100% - 30px);
        background-color: $white;
        min-height: 100px;
        margin: 0px 15px;
        padding: 47px 30px;
        @include lg(){
            margin: 0px;
            width: 600px;
            padding: 47px 81px;
        }
        .modal-title {
            h3 {
                font-family: $archivo_bold;
                font-size: 23px;
                color: $azul_flojo;
                margin-bottom: 11px;
            }
            p {
                font-family: $archivo_regular;
                font-size: 16px;
            }
        }
        .modal-body {
            position: relative;
            margin-top: 26px;
            color: $modal_texto;
            max-height: calc( 100vh - 226px );
            overflow: auto;
            p {
                font-family: $archivo_medium;
                font-size: 18px;
                line-height: 22px;
                margin-bottom: 10px;
            }
            .form-input {
                input {
                    background-color: #F7F7F7;
                    border: none;
                    font-family: $archivo_regular;
                    font-size: 16px;
                    margin-bottom: 10px;
                    min-height: 36px;
                    padding: 0px 10px 0px 30px;
                    width: 100%;
                    &::placeholder {
                        color: var(--admin-placeholder-color);
                    }
                }
            }
        }
        .modal-footer {
            margin-top: 36px;
            .buttons {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .btn {
                    font-family: $archivo_bold;
                    font-size: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 154px;
                    height: 45px;
                    margin-left: 0px;
                    margin-right: 25px;
                    cursor: pointer;
                    &.btn-confirm {
                        color: $white;
                        background-color: var(--colorPrincipal);
                    }
                    &.btn-cancel {
                        color: var(--colorPrincipal);
                        background-color: #EFEEE9;
                    }
                }
            }
        }
        .modal-close {
            position: absolute;
            top: 0px;
            right: 0px;
            width: 53px;
            height: 54px;
            border: 3px solid $white;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            @include lg(){
                right: -53px;
            }
            span {
                display: inline-block;
                width: 17px;
                height: 16px;
                background-image: url('../../assets/img/sprite.png');
                background-position: -87px -419px;
                @include lg(){
                    background-position: -64px -419px;}
            }
        }
    }
    &.active {
        display: flex;
    }

    &.admin-password,
    &.email-notifications {
        .modal-wrapper {
            @include lg(){
                padding: 47px 62px;
            }
            .modal-footer {
                margin-top: 16px;
            }
        }
    }

    &.email-notifications {
        .en-input {
            align-items: center;
            background-color: #F7F7F7;
            border-radius: 8px;
            display: flex;
            margin-bottom: 11px;
            min-height: 36px;
            width: 100%;

            label {
                font-family: $archivo_regular;
                font-size: 16px;
                margin-left: 30px;
            }

            input {
                background: none;
                border: none;
                flex: 1;
                font-family: $archivo_bold;
                font-size: 16px;
                margin-left: 10px;
                min-height: 36px;
                padding-right: 10px;
            }
        }
    }
}

.modal-bottom-ylm {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 100;
    display: none;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 20px;
    .modal-bg {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }
    .modal-wrapper {
        position: relative;
        background-color: $modal_bottom_grey;
        margin: 0px 15px;
        padding: 10px 30px;
        border-radius: 10px;
        transform: translate(0px, 100px);
        @include lg(){
            margin: 0px;
            padding: 16px 30px;
            min-width: 500px;
            transition: all .3s;
        }
        .modal-body {
            position: relative;
            color: $modal_texto;
            max-height: calc( 100vh - 226px );
            overflow: auto;
            text-align: center;
            p {
                font-family: $archivo_bold;
                color: $white;
                font-size: 16px;
                line-height: 25px;
                margin-bottom: 15px;
                &:last-of-type {
                    margin-bottom: 0px;
                }
            }
        }
    }
    &.active {
        display: flex;
        .modal-wrapper {
            transform: translate(0px, 0px);
        }
    }
}


.modal-bottom-wrapper {
    position: fixed;
    background-color: $modal_bottom_grey;
    bottom: 0px;
    left: 50%;
    margin: 0px 15px;
    padding: 10px 30px;
    border-radius: 10px;
    transform: translate(-50%, 100px);
    transition: all .3s;
    z-index: 1000;
    @include lg(){
        margin: 0px;
        padding: 16px 30px;
        min-width: 500px;
    }
    .modal-body {
        position: relative;
        color: $modal_texto;
        max-height: calc( 100vh - 226px );
        overflow: auto;
        text-align: center;
        p {
            font-family: $archivo_bold;
            color: $white;
            font-size: 16px;
            line-height: 25px;
            margin-bottom: 15px;
            text-align: center;
            &:last-of-type {
                margin-bottom: 0px;
            }
        }
    }
    &.active {
        transform: translate(-50%, -10px);
    }
}