.productos {
    //height: calc( 100vh - 169px );
    overflow: auto;
    .productos-grid {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 44px;
        width: 100%;
		max-height: calc(100vh - 200px);
        .producto-card {
            background-color: #FAFAFA;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 220px;
            position: relative;
            width: 171px;
            .card-main {
                & > div {
                    overflow: hidden;
                }
                .producto-img {
                    background-position: center;
                    background-size: cover;
                    border-radius: 10px 10px 0px 0px;
                    height: 171px;
                    width: 100%;
                }
                .producto-title {
                    align-items: flex-end;
                    display: flex;
                    font-family: $archivo_bold;
                    font-size: 12px;
                    justify-content: center;
                    height: 27px;
                    margin: 0px 5px;
                }
                .producto-marca {
                    color: $light-grey;
                    display: flex;
                    font-family: $archivo_bold;
                    font-size: 12px;
                    height: 22px;
                    justify-content: center;
                    line-height: 14px;
                    padding: 2px 5px 5px;
                    p {
                        overflow: hidden;
                    }
                }
                .producto-sucursales {
                    display: flex;
                    font-family: $archivo_semibold;
                    font-size: 10px;
                    justify-content: center;
                    padding: 10px 20px
                }
            }
            .producto-overlay {
                align-items: center;
                background-color: rgba(0,0,0,0.75);
                border-radius: 10px;
                display: flex;
                height: 100%;
                justify-content: center;
                left: 0px;
                position: absolute;
                top: 0px;
                width: 100%;
                .overlay-buttons {
                    display: flex;
                    button {
                        background: none;
                        background-color: $white;
                        border: none;
                        border-radius: 50%;
                        height: 45px;
                        margin: 0px 4px;
                        width: 45px;
                        svg {
                            margin-top: 3px;
                            margin-left: 1px;
                        }
                        &.trash {
                            svg {
                                height: 15px;
                                width: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.producto {
    width: 100%;
    input {
        color: $gris_fuerte;
        font-family: $archivo_regular;
        font-size: 14px;
        &:disabled {
            background-color: transparent;
            color: $black;
        }
    }
    textarea {
        color: $gris_fuerte;
        &:disabled {
            background-color: transparent;
            color: $black;
        }
    }
    .producto-wrapper {
        width: 963px;
        .single-producto {
            padding: 44px 30px;
            .header {
                display: flex;
                justify-content: space-between;
                min-height: 36px;
                width: 100%;
                .nav-back {
                    align-items: center;
                    color: $azul3;
                    cursor: pointer;
                    display: flex;
                    font-family: $archivo_bold;
                    font-size: 20px;
                    p {
                        margin-left: 9px;
                    }
                }
                .botonera {
                    align-items: center;
                    display: flex;
                    transition: transform 0.3s;
                    .btn-mini {
                        background: none;
                        border: none;
                        height: 36px;
                        margin-left: 10px;
                        width: 36px;
                        &.btn-editar {
                            svg {
                                height: 16px;
                                width: 17px;
                            }
                        }
                        &.btn-eliminar {
                            svg {
                                height: 17px;
                                width: 17px;
                            }
                        }
                    }
                    .btn {
                        background: none;
                        background-color: $gestion_bg_menu;
                        border: none;
                        color: var(--colorPrincipal);
                        font-family: $archivo_bold;
                        font-size: 16px;
                        height: 36px;
                        margin-left: 10px;
                        width: 120px;
                        &.btn-crear {
                            background-color: var(--colorPrincipal);
                            color: $gestion_bg_menu;
                        }
                        &:hover {
                            opacity: 0.3;
                        }
                    }
                    .btn:active {
                        background-color: #EDD8BB; 
                    }
                }
                &.compress {
                    .botonera {
                        transform: translateX(-135px);                
                    }
                }
            }
            input {
                &::placeholder {
                    color: $gris_fuerte;
                }
            }
            textarea {
                font-family: $archivo_regular;
                font-size: 14px;
                line-height: 23px;
                margin-top: 8px;
                resize: none;
                &::placeholder {
                    color: $gris_fuerte;;
                    font-style: italic;
                }
            }
            .name {
                margin-top: 19px;
                input {
                    border: none;
                    font-family: $archivo_bold;
                    font-size: 26px;
                    width: 100%;
                }
            }
            .producto-main {
                display: flex;
                margin-top: 25px;
                width: 100%;
                .producto-left {
                    min-width: 220px;
                    .image-container {
                        background-size: cover;
                        background-position: center;
                        border-radius: 10px;
                        cursor: pointer;
                        height: 357px;
                        width: 100%;
                    }
                }
                .producto-right {
                    flex: 1 1 auto;
                    margin-left: 39px;
                    .producto-data {
                        display: flex;
                        .producto-texts {
                            flex: 1 1 auto;
                            .idiomas {
                                align-items: center;
                                display: flex;
                                height: auto;
                                .btn-idioma {
                                    align-items: center;
                                    cursor: pointer;
                                    display: flex;
                                    font-family: $archivo_bold;
                                    justify-content: center;
                                    margin-right: 2px;
                                    height: 28px;
                                    width: 46px;
                                }
                            }
                            .formato {
                                display: flex;
                                justify-content: space-between;
                                margin-top: 17px;
                                .formato-item {
                                    width: 45%;
                                    input {
                                        background-color: transparent;
                                        border: none;
                                        margin-top: 8px;
                                    }
                                }
                            }
                            .producto-text {
                                margin-top: 17px;
                                margin-bottom: 20px;
                                textarea {
                                    border: none;
                                    width: 100%;
                                }
                            }
                        }
                        .producto-prices {
                            margin-left: 39px;
                            width: 210px;
                            .prices-container {
                                border-radius: 10px;
                                display: flex;
                                height: 105px;
                                justify-content: center;
                                padding: 21px;
                                width: 100%;
                                .prices-title {
                                    font-family: $archivo_regular;
                                    font-size: 11px;
                                    margin-bottom: 9px;
                                    text-transform: uppercase;
                                }
                                .price-container {
                                    border-right: 1px solid;
                                    width: 50%;
                                    &.price {
                                        border-right: none;
                                        .prices-title {
                                            text-align: right;
                                        }
                                    }
                                    &.stock {
                                        padding-right: 25px;
                                        .prices-title {
                                            text-align: center;
                                        }
                                        input {
                                            text-align: center;
                                        }
                                    }
                                    .price-item {
                                        align-items: center;
                                        display: flex;
                                        font-family: $archivo_bold;
                                        font-size: 25px;
                                        justify-content: center;
                                        input {
                                            background: none;
                                            border: none;
                                            font-family: $archivo_bold;
                                            font-size: 25px;
                                            text-align: right;
                                        }
                                        span {
                                            display: inline-block;
                                            //margin-right: 23px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .producto-selectors {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 7px;
                        .producto-selector {
                            border-radius: 10px;
                            display: flex;
                            height: 71px;
                            padding: 8px;
                            position: relative;
                            width: 210px;
                            .left {
                                .icon-bg {
                                    align-items: center;
                                    background-color: $white;
                                    border-radius: 10px;
                                    display: flex;
                                    height: 54px;
                                    justify-content: center;
                                    width: 54px;
                                }
                            }
                            .right {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                flex: 1 1 auto;
                                margin-left: 15px;
                                .title {
                                    font-size: 11px;
                                    text-transform: uppercase;
                                }
                            }

                            &.prepago {
                                .right {
                                    flex-direction: row;
                                    p {
                                        font-size: 11px;
                                        line-height: 16px;
                                        margin-left: auto;
                                        margin-right: 16px;
                                        text-transform: uppercase;
                                    }
                                }
                            }
                            &.fuera-stock {
                                .right {
                                    flex-direction: row;
                                    p {
                                        font-size: 11px;
                                        line-height: 16px;
                                        margin-left: auto;
                                        margin-right: 16px;
                                        text-transform: uppercase;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .ylm-sucursal-select {
        margin-bottom: 6px;
        width: 100%;
        .select-main {
            align-items: center;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            padding-right: 10px;
            width: 100%;
            p {
                font-size: 20px;
            }
        }
    }
}