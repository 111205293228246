.ylm-calendar {
    flex-shrink: 0;
    width: 100%;
    padding-left: 38px;
    padding-right: 46px;
    position: relative;
    .calendar-preheader {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-top: 28px;
        .title {
            display: flex;
            align-items: center;
            span {
                display: inline-block;
                width: 25px;
                height: 25px;
                background-image: url('../../assets/img/sprite.png');
                background-position: -147px -380px;
                margin-right: 10px;
            }
            h1 {
                font-family: $archivo_bold;
                font-size: 28px;
                color: var(--colorPrincipal);
            }
        }
        .selector {
            display: flex;
            width: 245px;
            flex-shrink: 0;
            margin-left: 65px;
            justify-content: flex-start;
            align-items: center;
            color: $grey2;
            @include lg(){
                width: 350px;
            }
            p {
                cursor: pointer;
                color: $azul_flojo;
                margin: 0px 5px;
                padding: 10px 35px;

                .badge {
                    position: relative;
                    font-family: $archivo_bold;
                    font-size: 11px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    width: 16px;
                    height: 16px;
                    margin-left: 8px;
                    background-color: var(--colorPrincipal);
                    color: $white;
                    top: -6px;
                }
                &.active {
                    background-color: $azul_extraflojo;
                    font-family: $archivo_semibold;
                    color: var(--colorPrincipal);
                    .badge {
                        background-color: var(--colorPrincipal);
                    }
                }
                &:last-of-type {
                    padding: 10px 10px 10px 20px;
                }
            }
        }
        .botonera {
            display: flex;
            justify-content: flex-end;
            flex: 1;
            div {
                margin-left: 8px;
            }
            .btn-add {
                width: 35px;
                height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $gris_boton_bg;
                font-family: $archivo_bold;
                font-size: 21px;
                cursor: pointer;
            }

            .btn-refresh {
                width: 35px;
                height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $gris_boton_bg;
                font-family: $archivo_bold;
                font-size: 21px;
                cursor: pointer;
                svg {
                    g {
                        fill: transparent;
                    }
                    line {
                        stroke-width: 2px;
                    }
                }
            }
        }
    }
    .calendar-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 46px 0px 15px;
        max-width: 902px;
        margin: 0 auto;
        .selector {
            display: flex;
            align-items: center;
            .btn {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 20px;
                cursor: pointer;
                &.btn-arrow {
                    width: 7px;
                    height: 14px;
                    background-image: url('../../assets/img/sprite.png');
                    background-position: -169px -419px;
                    &.arrow-right {
                        background-position: -184px -419px;
                    }
                }
            }
            .ylm-month-year {
                display: flex;
                font-family: $archivo_bold;
                font-size: 20px;
                margin: 0px 25px;
                .ylm-day {
                    margin-right: 5px;
                }
                .ylm-year {
                    margin-left: 5px;
                }
            }
        }
        .list-title {
            font-family: $archivo_bold;
            h3 {
                font-size: 20px;
            }
        }
        .right {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .btn {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $gris_boton_bg;
                color: var(--colorPrincipal);
                font-family: $archivo_medium;
                font-size: 14px;
                &.active {
                    color: var(--colorPrincipal);
                    background-color: $azul_extraflojo;
                }
            }
            .month-selector {
                padding: 0px 5px;
                height: 48px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                .btn {
                    width: 85px;
                    height: 40px;
                }
            }
            .view-selector {
                margin-left: 50px;
                padding: 0px 5px;
                height: 48px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                .btn {
                    width: 47px;
                    height: 40px;
                    span {
                        display: inline-block;
                        width: 22px;
                        height: 22px;
                        background-image: url('../../assets/img/sprite.png');
                    }
                    &.btn-calendar {
                        span { background-position: -119px -380px; }
                    }
                    &.btn-list {
                        span { height: 18px; background-position: -141px -419px; }
                    }
                }
            }
        }
        .calendar-type {
            width: 70px;
        }
    }
    .month-view {
        max-width: 902px;
        margin: 0 auto;
        .week-days {
            display: flex;
            justify-content: space-between;
            padding-bottom: 16px;
            border-bottom: 1px solid $calendar-border-gray;
            font-family: $archivo_medium;
            font-size: 16px;
            .week-day {
                display: flex;
                justify-content: center;
                width: calc( 100% / 7);
            }
        }
    }
    .ylm-dates {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .ylm-date {
            align-items: center;
            border-bottom: 1px solid $calendar-border-gray;
            color: $calendar;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            font-size: 20px;
            padding-bottom: 12px;
            padding-top: 3px;
            width: calc( 100% / 7);
            &.no-current {
                color: $calendar-pasado;
            }
            .ylm-day {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 30px;
                width: 30px;
                border-radius: 50%;
            }
            .indicators {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                width: 40px;
                margin-top: 8px;
                min-height: 24px;
                .indicator {
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    margin: 0px 1px 4px;
                }
            }
            &.today {
                .ylm-day {
                    font-weight: bold;
                }
            }
            &.selected {
                .ylm-day {
                    background-color: var(--colorPrincipal);
                    color: $white;
                }
            }
        }
    }

    .toggle-dnd {
        align-items: center;
        background-color: var(--color6);
        border-radius: 8px;
        bottom: 0px;
        color: var(--colorPrincipal);
        cursor: pointer;
        display: flex;
        left: 50%;
        padding: 8px 20px 8px 11px;
        position: absolute;
        transform: translateX(-50%);
        svg {
            margin-right: 10px;
        }
        &.active {
            background-color: var(--colorPrincipal);
            color: $white;
        }
        &:hover {
            opacity: 0.8;
        }
    }
}