@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    }
    to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
    }
}
@keyframes rotating {
    from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    }
    to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
    }
}
.rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}

.loading-layer {
    align-items: center;
    color: var(--white);
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 100vw;
    z-index: 1000;
    .loading-bg {
        background-color: var(--colorPrincipal);
        left: 0px;
        height: 100%;
        opacity: 0.8;
        position: absolute;
        top: 0px;
        width: 100%;
    }
    .loading-content {
        font-family: $archivo_bold;
        font-size: 50px;
        z-index: 1001;
        img {
            width: 50px;
        }
    }
}