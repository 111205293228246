.planes-list {
    display: flex;
    gap: 14px;
    margin-top: 11px;
    max-height: calc(100vh - 170px);
    overflow: auto;
    padding-top: 20px;
    position: relative;
    z-index: 15;
    .plan-item {
        color: var(--colorPrincipal);
        cursor: pointer;
        font-family: $archivo_bold;
        font-size: 20px;
        padding-bottom: 20px;
        position: relative;
        width: 33%;
        max-width: 288px;
        .plan-header {
            background-color: var(--color6);
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            height: 155px;
            justify-content: center;
            padding: 0px 15px 0px 25px;
            .recomendado {
                background-color: var(--color5);
                border-radius: 100px;
                color: var(--white);
                font-size: 16px;
                left: 50%;
                padding: 5px 20px;
                position: absolute;
                top: -14px;
                transform: translateX(-50%);
            }
            h5 {
                color: var(--colorPrincipal);
                font-family: $archivo_bold;
                font-size: 18px;
            }
            .price {
                margin-top: 10px;
                p {
                    font-size: 50px;
                    span {
                        font-size: 20px;
                        margin-left: 12px;
                    }
                }
            }
        }
        .plan-body {
            background-color: var(--color6);
            border-radius: 20px;
            font-size: 16px;
            margin-top: 14px;
            padding: 40px 15px 40px 25px;
            .plan-feature {
                display: flex;
                margin-bottom: 8px;
                position: relative;
                .plan-check {
                    background-color: var(--colorSecundario);
                    border-radius: 50%;
                    margin-right: 10px;
                    height: 20px;
                    position: relative;
                    transform: translateY(-2px);
                    width: 20px;
                    .plan-check-item {
                        border-bottom: 2px solid var(--colorPrincipal); 
                        border-left: 2px solid var(--colorPrincipal); 
                        height: 6px;
                        left: 5px;
                        position: absolute;
                        top: 6px;
                        transform: rotate(-45deg);
                        width: 10px;
                    }
                }
            }
        }
        .plan-footer {
            align-items: center;
            background-color: var(--color3);
            border-radius: 20px;
            display: flex;
            justify-content: center;
            margin-top: 14px;
            padding: 23px 15px 23px 25px;
        }
    }
}

.plan {
    width: 100%;
    .plan-wrapper {
        width: 963px;
        .header {
            display: flex;
            justify-content: space-between;
            min-height: 36px;
            width: 100%;
            .nav-back {
                align-items: center;
                color: $azul3;
                cursor: pointer;
                display: flex;
                font-family: $archivo_bold;
                font-size: 20px;
                p {
                    margin-left: 9px;
                }
            }

            .botonera {
                align-items: center;
                display: flex;
                .btn-mini {
                    background: none;
                    border: none;
                    height: 36px;
                    margin-left: 10px;
                    width: 36px;
                    &.btn-editar {
                        svg {
                            height: 16px;
                            width: 17px;
                        }
                    }
                    &.btn-eliminar {
                        svg {
                            height: 17px;
                            width: 17px;
                        }
                    }
                }
                .btn {
                    background: none;
                    background-color: $gestion_bg_menu;
                    border: none;
                    color: var(--colorPrincipal);
                    font-family: $archivo_bold;
                    font-size: 16px;
                    height: 36px;
                    margin-left: 10px;
                    width: 120px;
                    &.btn-crear {
                        background-color: var(--colorPrincipal);
                        color: $gestion_bg_menu;
                    }
                    &:hover {
                        opacity: 0.3;
                    }
                }
                .btn:active {
                    background-color: #EDD8BB; 
                }
            }
        }

        .single-plan {
            padding: 44px 30px 10px;
            .plan-body {
                height: calc(100vh - 170px);
                overflow: auto;
                width: 100%;
                .plan-data {
                    display: flex;
                    gap: 10px;
                    position: relative;
                    .plan-item {
                        color: var(--colorPrincipal);
                        display: flex;
                        flex: 1;
                        flex-direction: column;
                        font-family: $archivo_bold;
                        height: 114px;
                        justify-content: center;
                        margin-top: 10px;
                        padding: 0px 10px 0px 10px;
                        position: relative;
                        h5 {
                            color: var(--black);
                            font-family: $archivo_semibold;
                            font-size: 14px;
                            margin-bottom: 10px;
                        }
                        &.mensual,
                        &.anual {
                            background-color: var(--color6);
                            border-radius: 20px;
                            flex: 0 0 250px;
                            height: 100px;
                            padding: 0px 10px 0px 25px;
                            top: 20px;
                        }
                        &.error {
                            border: 1px solid red;
                        }
                        .form-item {
                            input {
                                border: none;
                                color: var(--colorPrincipal);
                                font-family: $archivo_bold;
                                font-size: 45px;
                                width: 100%;
                                &::placeholder {
                                    color: var(--colorPrincipal);
                                }
                            }
                            .eur {
                                font-family: $archivo_bold;
                                font-size: 45px;
                            }
                        }
                    }
                }
                .plan-description {
                    margin-top: 23px;
                    h3 {
                        color: var(--colorSecundario);
                        font-family: $archivo_regular;
                        font-weight: normal;
                        margin-bottom: 18px;
                    }
                    textarea {
                        border: none;
                        font-family: $archivo_regular;
                        height: 126px;
                        line-height: 23px;
                        resize: none;
                        width: 473px;
                    }
                }
                .plan-permisos {
                    margin-top: 15px;
                    h3 {
                        color: var(--colorSecundario);
                        font-family: $archivo_regular;
                        font-weight: normal;
                        margin-bottom: 18px;
                    }
                    .permisos-row {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 16px;
                        .permiso {
                            align-items: center;
                            display: flex;
                            font-family: $archivo_bold;
                            gap: 6px;
                            height: 43px;
                            max-width: 271px;
                            width: 30%;
                            .permiso-qt {
                                align-items: center;
                                background-color: var(--gris1);
                                border-radius: 10px;
                                display: flex;
                                flex: 0 0 46px;
                                height: 100%;
                                justify-content: center;
                                p {
                                    color: var(--white);
                                    text-align: center;
                                    padding: 0;
                                    span {
                                        text-transform: lowercase;
                                    }
                                }
                            }
                            .permiso-data {
                                align-items: center;
                                background-color: var(--gris3);
                                border-radius: 10px;
                                display: flex;
                                flex: 1;
                                height: 100%;
                            }
                            p {
                                color: var(--gris1);
                                flex: 1;
                                padding: 0px 10px 0px 15px;
                                text-transform: uppercase;
                            }
                            .permiso-toggle {
                                background-color: var(--gris4);
                                border-radius: 100px;
                                display: none;
                                height: 10px;
                                margin-right: 15px;
                                position: relative;
                                width: 33px;
                                span {
                                    background-color: var(--gris1);
                                    border: 2px solid var(--gris3);
                                    border-radius: 50%;
                                    display: block;
                                    height: 16px;
                                    position: absolute;
                                    top: -3px;
                                    transition: transform 0.3s;
                                    width: 16px;
                                }
                            }
                            .permiso-btn {
                                align-items: center;
                                background: none;
                                background-color: var(--colorPrincipal);
                                border: none;
                                border-radius: 50%;
                                display: flex;
                                flex: 0 0 26px;
                                height: 26px;
                                justify-content: center;
                                margin-right: 13px;
                                position: relative;
                                svg {
                                    height: 8px;
                                    position: relative;
                                    stroke: var(--white);
                                    stroke-width: 3px;
                                    top: 0px;
                                    width: 8px;
                                }
                            }
                            &.active {
                                p {
                                    color: var(--colorPrincipal);
                                }
                                .permiso-qt {
                                    background-color: var(--colorPrincipal);
                                    p {
                                        color: var(--white);
                                    }
                                }
                                .permiso-toggle {
                                    background-color: var(--colorSecundario);
                                    span {
                                        background-color: var(--colorPrincipal);
                                        transform: translateX(17px);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}